import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AppDispatch } from '../../../app/store';
import { RootState } from '../../../app/rootReducer';
import {
  fetchSurveyDetail,
  goToNextCategory,
  goToPreviousCategory,
  saveOutcomeAnswerLevel,
  updateAnswerLevel,
  setCurrentOutcomeCategoryID,
} from '../slices/surveyOutcomeDetailSlice';
import { SurveyIncentive } from '../../../api/getSurveyDetail/types';
import { updateCurrentStep, updateTotalSteps } from '../slices/assessmentHeaderSlice';
import { confirmCoreValue } from '../slices/surveyDetailSlice';
// import { fetchMyWholeHealth } from '../slices/wholeHealthSlice';

const SCROLL_OFFSET = 80;

const useOutcomeAssessment = () => {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const questionRefs = useRef<(HTMLDivElement | null)[]>([]);

  const [yCoordinates, setYCoordinates] = useState<number[]>([]);
  const scrolledToNextQuestion = useRef(false);

  const [isIntroModalVisible, setIntroModalVisible] = useState(false);
  const [introModalShown, setIntroModalShown] = useState(false);
  const [isOutroModalVisible, setOutroModalVisible] = useState(false);

  // -------------------------------
  // Incentive State
  // -------------------------------
  const [isIncentiveModalVisible, setIncentiveModalVisible] = useState(false);
  const [currentIncentive, setCurrentIncentive] = useState<SurveyIncentive | null>(null);

  // -------------------------------
  // Core Values State
  // -------------------------------
  const [isCoreValuesModalVisible, setCoreValuesModalVisible] = useState(false);
  const [coreValuesModalDismissed, setCoreValuesModalDismissed] = useState(false);

  const {
    surveyDetail,
    isLoading,
    error,
    currentOutcomeCategoryID,
    isLastPage,
    currentStep,
    totalSteps,
    uniqueCategories,
  } = useSelector((state: RootState) => state.surveyOutcomeDetail);

  // For convenience
  const currentSectionHeading = surveyDetail?.surveySectionHeadings?.find(
    (s) => s.sectionID === currentOutcomeCategoryID
  )?.sectionHeading;
  const continueButtonText = surveyDetail?.continueButtonText;
  const backButtonText = surveyDetail?.backButtonText;

  // Filter questions for this category
  const currentQuestions =
    surveyDetail?.surveyQuestions.filter(
      (q) => q.outcomeCategoryID === currentOutcomeCategoryID
    ) || [];

  // -------------------------------
  // Fetch data (if not already loaded)
  // -------------------------------
  useEffect(() => {
    // dispatch(fetchMyWholeHealth());
    dispatch(fetchSurveyDetail(0));
  }, [dispatch]);

  // -------------------------------
  // Update header steps
  // -------------------------------
  useEffect(() => {
    if (surveyDetail) {
      dispatch(updateCurrentStep(currentStep));
      dispatch(updateTotalSteps(totalSteps));
    }
  }, [dispatch, surveyDetail, currentStep, totalSteps]);

  // -------------------------------
  // Show the core values modal if needed
  // -------------------------------
  useEffect(() => {
    if (surveyDetail) {
      const { coreValuesStatusID } = surveyDetail;
      if ([3, 4, 5].includes(coreValuesStatusID)) {
        setCoreValuesModalVisible(true);
      } else {
        setCoreValuesModalDismissed(true);
      }
    }
  }, [surveyDetail]);

  // -------------------------------
  // Show the intro modal if needed
  // -------------------------------
  useEffect(() => {
    if (
      coreValuesModalDismissed &&
      surveyDetail &&
      !introModalShown &&
      currentStep === 1
    ) {
      const { sectionStatusID } = surveyDetail;
      // sectionStatusID === 2 means “Intro not shown yet” (for example)
      if (sectionStatusID === 2) {
        setIntroModalVisible(true);
        setIntroModalShown(true);
      }
    }
  }, [coreValuesModalDismissed, surveyDetail, introModalShown, currentStep]);

  // -------------------------------
  // Core Values confirmation
  // -------------------------------
  const handleCoreValuesConfirm = (extendedDefer: boolean) => {
    dispatch(
      confirmCoreValue(extendedDefer, () => {
        setCoreValuesModalVisible(false);
        setCoreValuesModalDismissed(true);
      })
    );
  };

  const handleCoreValuesRetake = () => {
    setCoreValuesModalVisible(false);
    setCoreValuesModalDismissed(true);
    history.push('/assessment/corevalues?fromWholeHealth=0', { isRetake: true });
  };

  // -------------------------------
  // Intro Modal
  // -------------------------------
  const handleIntroModalClose = () => {
    setIntroModalVisible(false);
  };
  const handleIntroNext = () => {
    setIntroModalVisible(false);
    window.scrollTo({ top: 0, left: 0 });
  };

  // -------------------------------
  // Incentive modal close
  // -------------------------------
  const handleIncentiveModalClose = () => {
    // Close the incentive modal, then proceed to the next category
    setIncentiveModalVisible(false);
    dispatch(goToNextCategory());
  };

  // -------------------------------
  // Scroll helper
  // -------------------------------
  const scrollToY = (y: number) => {
    const offsetPosition = y - SCROLL_OFFSET;
    window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
  };

  // -------------------------------
  // Navigation (Continue / Back)
  // -------------------------------
  const handleNext = () => {
    if (isLastPage) {
      // If it's the last page, navigate to the outro
      history.push('/mywholeHealth/outcomeAssessmentOutro');
    } else if (surveyDetail) {
      // Check if we need to show an incentive
      const surveyIncentives = surveyDetail.surveyIncentives || [];
      const incentive = surveyIncentives.find(
        (inv) => inv.screenID === currentStep
      );
      if (incentive) {
        // Show inline instead of routing away
        setCurrentIncentive(incentive);
        setIncentiveModalVisible(true);
      } else {
        dispatch(goToNextCategory());
      }
    }
  };

  const handleBack = () => {
    const currentIndex = uniqueCategories.indexOf(currentOutcomeCategoryID || 0);
    if (currentIndex > 0) {
      dispatch(goToPreviousCategory());
    } else {
      // If we're at the first category, go back
      history.push('/MyWholeHealth');
    }
  };

  // -------------------------------
  // Save question answers
  // -------------------------------
  const onQuestionChange = (id: number, answerLevel: number, index: number) => {
    dispatch(updateAnswerLevel({ selectionID: id, answerLevel }));
    dispatch(saveOutcomeAnswerLevel(id, answerLevel));

    // Optionally scroll to next question
    if (yCoordinates.length > index + 1) {
      scrollToY(yCoordinates[index + 1]);
    }
  };

  // -------------------------------
  // Build an array of question "top" positions
  // -------------------------------
  useEffect(() => {
    if (currentQuestions.length > 0) {
      const newCoordinates = currentQuestions.map((_, index) => {
        const el = questionRefs.current[index];
        return el ? el.getBoundingClientRect().top + window.scrollY : 0;
      });
      setYCoordinates(newCoordinates);
    } else {
      setYCoordinates([]);
    }
  }, [currentQuestions.length]);

  // -------------------------------
  // If the API sets a "nextSelectionID", scroll there or set next category
  // -------------------------------
  useEffect(() => {
    if (
      surveyDetail &&
      surveyDetail.nextSelectionID !== null &&
      currentQuestions.length === yCoordinates.length &&
      !scrolledToNextQuestion.current
    ) {
      let nextQuestionIndex: number;

      if (surveyDetail.nextSelectionID === 0) {
        nextQuestionIndex = 0;
      } else {
        nextQuestionIndex = currentQuestions.findIndex(
          (q) => q.selectionID === surveyDetail.nextSelectionID
        );
      }

      if (nextQuestionIndex !== -1 && yCoordinates[nextQuestionIndex] !== undefined) {
        scrollToY(yCoordinates[nextQuestionIndex]);
        scrolledToNextQuestion.current = true;
      } else {
        const nextQuestion = surveyDetail.surveyQuestions.find(
          (q) => q.selectionID === surveyDetail.nextSelectionID
        );
        if (nextQuestion && nextQuestion.outcomeCategoryID !== currentOutcomeCategoryID) {
          dispatch(setCurrentOutcomeCategoryID(nextQuestion.outcomeCategoryID));
        }
      }
    }
  }, [
    surveyDetail,
    currentQuestions,
    yCoordinates,
    currentOutcomeCategoryID,
    dispatch,
  ]);

  // -------------------------------
  // Outro modal
  // -------------------------------
  const handleOutroModalClose = () => {
    setOutroModalVisible(false);
  };
  const handleOutroContinue = () => {
    setOutroModalVisible(false);
  };

  return {
    surveyDetail,
    isLoading,
    error,
    currentQuestions,
    currentStep,
    totalSteps,
    handleNext,
    handleBack,
    onQuestionChange,
    questionRefs,
    scrollContainerRef,

    // Intro
    isIntroModalVisible,
    handleIntroModalClose,
    handleIntroNext,

    // Outro
    isOutroModalVisible,
    handleOutroModalClose,
    handleOutroContinue,

    // Incentive
    currentIncentive,
    isIncentiveModalVisible,
    handleIncentiveModalClose,

    // Core values
    isCoreValuesModalVisible,
    handleCoreValuesConfirm,
    handleCoreValuesRetake,

    // Misc
    currentSectionHeading,
    continueButtonText,
    backButtonText,
  };
};

export default useOutcomeAssessment;
