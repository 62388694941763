import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {ServiceResponseSurveyInformation, SurveyInformationResponse} from "../../../api/getSurveyInformation/types";
import {AppThunk} from "../../../app/store";
import {setRedirectToLogin} from "../../auth/authSlice";
import {SeityAuthenticationError} from "../../../api/authTypes";
import {getSurveyInformation} from "../../../api/getSurveyInformation/getSurveyInformationApi";
import {getRefreshedToken} from "../../auth/UseSessionStorageToken";

interface SurveyInformationState {
  error: string | null;
  isLoading: boolean;
  surveyInformation: SurveyInformationResponse | null;
}

const initialState: SurveyInformationState = {
  error: null,
  isLoading: false,
  surveyInformation: null,
};

const surveyInformationSlice = createSlice({
  name: 'survey_information',
  initialState,
  reducers: {
    fetchSurveyInformationSuccess(state, action: PayloadAction<SurveyInformationResponse>) {
      state.surveyInformation = action.payload;
      state.isLoading = false;
    },
    setApiError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    clearApiError(state) {
      state.error = null;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
  },
});

export const {
  fetchSurveyInformationSuccess,
  setApiError,
  clearApiError,
  setIsLoading,
} = surveyInformationSlice.actions;

export default surveyInformationSlice.reducer;

export const fetchSurveyInformation = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    dispatch(clearApiError());

    const { token } = await getRefreshedToken();
    if (token === null) {
      dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
      throw new SeityAuthenticationError();
    }

    const response: ServiceResponseSurveyInformation = await getSurveyInformation(token);

    if (response.success) {
      dispatch(fetchSurveyInformationSuccess(response.data));
    } else {
      dispatch(setApiError(response.userMessage || 'Failed to fetch survey information data'));
    }
  } catch (err: any) {
    dispatch(setApiError(err.toString()));
  } finally {
    dispatch(setIsLoading(false));
  }
};
