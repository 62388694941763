import { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { RootState } from "../../app/rootReducer";
import SeityLoader from "../../_core/components/SeityLoader";
import "./styles.scss";
import { CheckInBanner } from "./CheckInBanner";
import { ParticipationWidget } from "./ParticipationWidget";
import { LatestCheckin } from "./LatestCheckin";
import { Incentive } from "./Incentive";
import { sendGetWellbeingOnlyRequest } from "./wellbeingSlice";
import { CheckInGraphs } from "../myStory/CheckInGraphs/CheckInGraphs";
import moment from "moment";
import { isPlotlineInjectionCompleted } from "../plotline/helpers";
import { PlotlineInjectionPointID } from "../../api/plotlineTypes";
import { CredentialVerification } from "../today/CredentialVerification";
import { CredentialsType } from "../account/maintenance/ModifyAuthentication";
import {fetchWellbeingDetail} from "../../slices/wellbeingDetailSlice";

const dateFormat = "MMM Do, YYYY hh:mm a";

export const WellbeingOnly: FunctionComponent = () => {
  const { isLoading, data } = useSelector((state: RootState) => {
    return state.wellbeing;
  });
  const { isCheckInRedirected } = useSelector((state: RootState) => {
    return state.account;
  });
  const { today } = useSelector((state: RootState) => {
    return state.today;
  });
  const { plotlineInjections } = useSelector((state: RootState) => {
    return state.plotline;
  });

  // Get wellbeingDetail from store
  const { wellbeingDetail } = useSelector((state: RootState) => state.wellbeingDetail);

  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isCredentialVerificationModalVisible, setIsCredentialVerificationModalVisible] = useState(false);
  const [verificationCredentialType, setVerificationCredentialType] = useState<CredentialsType | null>(null);
  const [lastUpdated, setLastUpdated] = useState<string | null>(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { header, checkInDue, incentive, dailyCheckInParticipation, overallStandings, wellbeing, checkInDescription } = data;
  const { cellPhone, phoneCountryCodeID } = useSelector((state: RootState) => {
    return state.account.accountInfo;
  });
  const { userAppSettings, phoneVerificationShown } = useSelector((state: RootState) => {
    return state.userAppSettings;
  });

  const checkVerifications = () => {
    if (userAppSettings.smsVerificationNeeded && cellPhone !== null && cellPhone.trim() !== "") {
      if (!isCredentialVerificationModalVisible && !phoneVerificationShown) {
        setIsCredentialVerificationModalVisible(true);
        setVerificationCredentialType(CredentialsType.PhoneNumber);
      }
    }
  };

  useEffect(() => {
    checkVerifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [today.checkInDue]);

  useEffect(() => {
    if (!isPlotlineInjectionCompleted(plotlineInjections, PlotlineInjectionPointID.Wellbeing)) {
      history.push("/plotline/" + PlotlineInjectionPointID.Wellbeing);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      sendGetWellbeingOnlyRequest(() => {
        setLastUpdated(moment(new Date()).format(dateFormat));
      })
    );

    // Fetch the wellbeing detail data
    dispatch(fetchWellbeingDetail());
  }, [dispatch]);

  const refreshData = () => {
    setIsRefreshing(true);
    dispatch(
      sendGetWellbeingOnlyRequest(() => {
        setIsRefreshing(false);
        setLastUpdated(moment(new Date()).format(dateFormat));
      })
    );
    dispatch(fetchWellbeingDetail());
  };

  if (today.checkInDue && !isCheckInRedirected) {
    return <Redirect to="/checkin/wellbeingCheckIn" />;
  }

  return (
    <>
      {isLoading && !isRefreshing ? (
        <SeityLoader />
      ) : (
        <div className="wellbeing-only-container seity-main-fullwidth">
          <img className="header-background" src={require("../../assets/graphics/wellbeing-rankings.png").default} alt="wellbeing" />
          <div className="header">
            <h3>{header?.title}</h3>
            <h5>
              {header?.subtitle}{" "}
              <img
                className="more-info"
                src={require("../../assets/graphics/more-info.png").default}
                alt="more info"
                onClick={() =>
                  history.push({
                    pathname: "/plotline/" + PlotlineInjectionPointID.Wellbeing,
                    state: {
                      onDemand: true
                    }
                  })
                }
              />
            </h5>
          </div>
          {checkInDue && <CheckInBanner incentive={incentive} description={checkInDescription} />}
          {(dailyCheckInParticipation || overallStandings) && (
            <ParticipationWidget
              participationData={dailyCheckInParticipation}
              overallStandings={overallStandings}
              lastUpdated={lastUpdated}
              refreshFunction={refreshData}
              isLoading={isLoading}
            />
          )}
          {wellbeing?.lastCheckInDate && (
            <div className="footer">
              <div className="checkin-incentive">
                <LatestCheckin wellbeing={wellbeing} checkInDue={checkInDue} />
                {incentive?.active && <Incentive incentive={incentive} />}
              </div>

              <div className="graphs">
                {/* Pass wellbeingDetail to CheckInGraphs instead of characterization */}
                {wellbeingDetail && (
                  <CheckInGraphs wellbeingDetail={wellbeingDetail} />
                )}
              </div>
            </div>
          )}
        </div>
      )}
      <CredentialVerification
        isVisible={isCredentialVerificationModalVisible}
        setVisible={(visible) => {
          setIsCredentialVerificationModalVisible(visible);
        }}
        credentialType={verificationCredentialType}
        phoneNumber={cellPhone}
        countryCodeId={phoneCountryCodeID}
      />
    </>
  );
};
