import { BASE_URL } from "../../apiConstants";
import axios from "axios";
import { AppContentResponse, AppContentType } from "../types";

export async function getAppContentByType(token: string, appContentType: AppContentType): Promise<AppContentResponse> {
  const url = BASE_URL + `/Content/AppContent?AppContentTypeID=${appContentType}`;
    const coreValueCateogryResponse = await axios.get<AppContentResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return coreValueCateogryResponse.data;
}
