import { CoreValuesCompleteDetailsResponse } from "./types";
import { BASE_URL } from "../../apiConstants";
import axios from "axios";

export async function getCoreValuesCompleteDetails(token: string): Promise<CoreValuesCompleteDetailsResponse> {
  const url = BASE_URL + `/WholeHealth/CoreValuesCompleteDetail`;
  try {
    const coreValuesCompleteDetailsResponse = await axios.get<CoreValuesCompleteDetailsResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return coreValuesCompleteDetailsResponse.data;
  } catch (err) {
    throw err;
  }
}
