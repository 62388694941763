import React from 'react';
import './styles.scss';

export interface SeityRadioButtonGroupProps {
  testID?: string;
  onValueChange: (value: string) => void;
  value: string;
  name: string;
  children: React.ReactNode;
}

export type SeityRadioButtonContextType = {
  value: string;
  onValueChange: (item: string) => void;
  name: string;
};

export const RadioButtonContext = React.createContext<SeityRadioButtonContextType | null>(null);

const SeityRadioButtonGroup: React.FC<SeityRadioButtonGroupProps> = ({
                                                                       testID,
                                                                       onValueChange,
                                                                       value,
                                                                       name,
                                                                       children,
                                                                     }) => {
  return (
    <RadioButtonContext.Provider value={{ value, onValueChange, name }}>
      <div data-testid={testID} role="radiogroup" className="radioButtonGroup">
        {children}
      </div>
    </RadioButtonContext.Provider>
  );
};

export default SeityRadioButtonGroup;
