import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './styles.scss';
import PracticeQuestion from '../PracticeQuestion/PracticeQuestion';
import { AppDispatch } from '../../../../app/store';
import { RootState } from '../../../../app/rootReducer';
import {
  fetchSurveyDetail,
  updateAnswerLevel,
  saveAnswerLevel,
  clearSurveyDetailsState,
  confirmCoreValue
} from '../../slices/surveyDetailSlice';
import PracticeAssessmentNavigation from '../PracticeAssessmentNavigation/PracticeAssessmentNavigation';
import { selectSubcategoryById } from '../../selectors/wholeHealthSelectors';
import { selectAnsweredQuestionCount } from '../../selectors/surveyDetailSelectors';
import { fetchMyWholeHealth } from '../../slices/wholeHealthSlice';
import PracticeAssessmentIntroModal from '../PracticeAssessmentIntroModal/PracticeAssessmentIntroModal';
/* import PracticeAssessmentOutroModal from '../PracticeAssessmentOutroModal/PracticeAssessmentOutroModal'; */
import CoreValuesConfirmationModal from '../CoreValuesConfirmationModal/CoreValuesConfirmationModal';

interface PracticeAssessmentRouteParams {
  subcategoryID: string;
  statusId?: string;
}

const PracticeAssessment: React.FC = () => {
  const { subcategoryID } = useParams<PracticeAssessmentRouteParams>();
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const scrollRef = useRef<HTMLDivElement>(null);
  const questionRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [scrollToIndex, setScrollToIndex] = useState<number | null>(null);
  const [initialScrollDone, setInitialScrollDone] = useState(false);

  const surveyDetail = useSelector((state: RootState) => state.surveyDetail.surveyDetail);
  const isLoading = useSelector((state: RootState) => state.surveyDetail.isLoading);
  const error = useSelector((state: RootState) => state.surveyDetail.error);

  const subcategory = useSelector(selectSubcategoryById(Number(subcategoryID)));
  const { answeredCount, totalCount } = useSelector(selectAnsweredQuestionCount);

  // Intro modal logic
  const [isIntroModalVisible, setIsIntroModalVisible] = useState(false);
  const [introModalShown, setIntroModalShown] = useState(false);

  // Outro modal logic
  const [isOutroModalVisible, setIsOutroModalVisible] = useState(false);

  // Core Values modal logic
  const [isCoreValuesModalVisible, setCoreValuesModalVisible] = useState(false);
  const [coreValuesModalDismissed, setCoreValuesModalDismissed] = useState(false);

  const SCROLL_OFFSET = 80;

  useEffect(() => {
    dispatch(fetchSurveyDetail(Number(subcategoryID)));
    dispatch(fetchMyWholeHealth());

    return () => {
      setIsIntroModalVisible(false);
      setIntroModalShown(false);
      setIsOutroModalVisible(false);
      setCoreValuesModalVisible(false);
      setCoreValuesModalDismissed(false);
      dispatch(clearSurveyDetailsState());
    };
  }, [dispatch, subcategoryID]);

  // Show Core Values Modal if needed
  useEffect(() => {
    if (surveyDetail) {
      const { coreValuesStatusID } = surveyDetail;
      // If the status ID is 3, 4, or 5, we show the Core Values Confirmation Modal
      if ([3, 4, 5].includes(coreValuesStatusID)) {
        setCoreValuesModalVisible(true);
      } else {
        // If not needed, mark as dismissed
        setCoreValuesModalDismissed(true);
      }
    }
  }, [surveyDetail]);

  // Show intro modal if sectionStatusID is 2 or 4 and core values modal is dismissed
  useEffect(() => {
    if (
      coreValuesModalDismissed &&
      surveyDetail &&
      !introModalShown
    ) {
      const { sectionStatusID } = surveyDetail;
      if ([2,4].includes(sectionStatusID)) {
        console.log("showing intro modal");
        setIsIntroModalVisible(true);
        setIntroModalShown(true);
      }
    }
  }, [surveyDetail, introModalShown, coreValuesModalDismissed]);

  const handleCoreValuesConfirm = (extendedDefer: boolean) => {
    dispatch(
      confirmCoreValue(extendedDefer, () => {
        setCoreValuesModalVisible(false);
        setCoreValuesModalDismissed(true);
      })
    );
  };

  const handleCoreValuesRetake = () => {
    setCoreValuesModalVisible(false);
    setCoreValuesModalDismissed(true);
    history.push(`/assessment/corevalues?fromWholeHealth=${subcategoryID}`, { isRetake: true });
  };

  const onQuestionChange = (id: number, answerLevel: number, index: number) => {
    dispatch(updateAnswerLevel({ selectionID: id, answerLevel }));
    dispatch(saveAnswerLevel(id, answerLevel));

    if (questionRefs.current.length > index + 1) {
      setScrollToIndex(index + 1);
    }
  };

  const scrollToElementWithOffset = (element: HTMLDivElement) => {
    const elementPosition = element.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition - SCROLL_OFFSET;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (scrollToIndex !== null && initialScrollDone) {
      const nextQuestionRef = questionRefs.current[scrollToIndex];
      if (nextQuestionRef) {
        scrollToElementWithOffset(nextQuestionRef);
      }
      setScrollToIndex(null);
    }
  }, [scrollToIndex, initialScrollDone]);

  const allQuestionsAnswered = surveyDetail?.surveyQuestions.every(
    (question) => question.answerLevel > 0
  );

  const nextPressed = () => {
    if (allQuestionsAnswered) {
      window.location.href = `/mywholeHealth/practiceAssessmentOutro/${Number(subcategoryID)}`;
    }
  };

  const backPressed = () => {
    history.goBack();
  };

  useEffect(() => {
    if (
      !initialScrollDone &&
      surveyDetail &&
      questionRefs.current.length === surveyDetail.surveyQuestions.length
    ) {
      const index = surveyDetail.surveyQuestions.findIndex(
        (question) => question.selectionID === surveyDetail.nextSelectionID
      );
      if (index !== -1 && questionRefs.current[index]) {
        const element = questionRefs.current[index];
        if (element) {
          scrollToElementWithOffset(element);
          setInitialScrollDone(true);
        }
      }
    }
  }, [surveyDetail, initialScrollDone]);

  useEffect(() => {
    return () => {
      setInitialScrollDone(false);
    };
  }, []);

  // Intro modal handlers
  const handleIntroModalClose = () => {
    history.goBack();
  };

  const handleIntroNext = () => {
    setIsIntroModalVisible(false);
    window.scrollTo({ top: 0, left: 0 });
  };

  // Outro modal handlers
  const handleOutroModalClose = () => {
    setIsOutroModalVisible(false);
  };

  const handleOutroContinue = () => {
    history.push('/MyWholeHealth');
  };

  const shouldShowMainContent =
    !isLoading &&
    !isIntroModalVisible &&
    !isCoreValuesModalVisible &&
    !isOutroModalVisible;

  return (
    <div className="practice-assessment-mainContainer">
      {isLoading ? (
        <div className="loadingContainer">Loading...</div>
      ) : (
        <>
          {/* Core Values Confirmation Modal */}
          <CoreValuesConfirmationModal
            isVisible={isCoreValuesModalVisible}
            onClose={backPressed} // Or any desired logic for closing
            onConfirm={handleCoreValuesConfirm}
            onRetake={handleCoreValuesRetake}
            surveyCoreValuePage={surveyDetail?.surveyCoreValuePage}
          />

          {/* Intro Modal */}
          {isIntroModalVisible && (
            <PracticeAssessmentIntroModal
              isVisible={isIntroModalVisible}
              onClose={handleIntroModalClose}
              onNext={handleIntroNext}
            />
          )}

          {/* Outro Modal */}
         {/*  {isOutroModalVisible && (
            <PracticeAssessmentOutroModal
              isVisible={isOutroModalVisible}
              onClose={handleOutroModalClose}
              onContinue={handleOutroContinue}
              outcomeSubcategoryID={Number(subcategoryID)}
            />
          )} */}

          {shouldShowMainContent && surveyDetail && (
            <>
              <div className="headingContainer">
                <p className="categoryHeading">{surveyDetail.categoryHeading}</p>
              </div>

              {subcategory && (
                <div className="subcategoryHeader">
                  <p className="subcategoryText">{subcategory.subcategory}</p>
                  <p className="questionCountText">
                    {answeredCount}/{totalCount}
                  </p>
                </div>
              )}

              <div ref={scrollRef}>
                {error && <p className="errorText">{error}</p>}
                {!error && (
                  <div>
                    {surveyDetail.surveyQuestions.map((question, index) => (
                      <PracticeQuestion
                        key={question.selectionID}
                        id={question.selectionID}
                        questionTypeId={question.questionTypeID}
                        questionText={question.questionText}
                        answerLevel={question.answerLevel}
                        answers={question.surveyAnswers}
                        onQuestionChange={(id, answerLevel) =>
                          onQuestionChange(id, answerLevel, index)
                        }
                        index={index}
                        needsToBeAnswered={question.answerLevel === 0}
                        ref={(el) => (questionRefs.current[index] = el)}
                      />
                    ))}
                  </div>
                )}

                <PracticeAssessmentNavigation
                  continueButtonText={surveyDetail?.continueButtonText}
                  primaryAction={nextPressed}
                  cancelAction={backPressed}
                  backButtonText={surveyDetail?.backButtonText}
                  errorMessage={error}
                  isPrimaryDisabled={!allQuestionsAnswered}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PracticeAssessment;
