import { SeityResponse } from "../baseTypes";

export type IntentionListResponse = SeityResponse & {
  data: Array<Intention>;
};

export type IntentionSelListResponse = SeityResponse & {
  data: Array<IntentionSelected>;
};

export type IntentionPostResponse = SeityResponse & {
  data: IntentionSelected;
};

export type CongratulationsGetResponse = SeityResponse & {
  data: CongratData;
};

export enum DICongratulationsType {
  Commit = 1,
  Complete = 2
}

export type Intention = {
  dailyIntentionID: number;
  text: string;
  imageFileName: string;
  outcomeCategoryID: number;
  outcomeCategory: string;
  outcomeCategoryImage: string;
  outcomeCategoryImageName: string;
  outcomeSubcategoryID: number;
  outcomeSubcategory: string;
  outcomeSubcategoryImage: string;
  outcomeSubcategoryImageName: string;
  dragonType: string;
  dragonID: number;
  dragonImageFileName: string;
};

export type IntentionSelected = {
  dailyIntentionAccountID: number;
  dailyIntentionID: number;
  text: string;
  imageFileName: string;
  outcomeCategoryID: number;
  outcomeCategory: string;
  outcomeCategoryImage: string;
  outcomeSubcategoryID: number;
  outcomeSubcategory: string;
  outcomeSubcategoryImage: string;
  subCategoryScore: number;
  selectedDate: string;
  completedDate: string;
  isCompleted: boolean;
  coreValueID1: number;
  coreValueSelected1: boolean;
  coreValueName1: string;
  coreValueID2: number;
  coreValueSelected2: boolean;
  coreValueName2: string;
  coreValueID3: number;
  coreValueSelected3: boolean;
  coreValueName3: string;
  coreValueID4: number;
  coreValueSelected4: boolean;
  coreValueName4: string;
};

export type PostIntentionType = {
  dailyIntentionID: number;
  coreValueID1: number;
  coreValueSelected1: boolean;
  coreValueID2: number;
  coreValueSelected2: boolean;
  coreValueID3: number;
  coreValueSelected3: boolean;
  coreValueID4: number;
  coreValueSelected4: boolean;
};

export type PutIntentionType = {
  dailyIntentionAccountID: number;
  coreValueID1: number;
  coreValueSelected1: boolean;
  coreValueID2: number;
  coreValueSelected2: boolean;
  coreValueID3: number;
  coreValueSelected3: boolean;
  coreValueID4: number;
  coreValueSelected4: boolean;
  isCompleted: boolean;
};

export type CongratData = {
  imageUrl: string;
  header: string;
  contentText: string;
  startDateTime: string;
  endDateTime: string;
};
