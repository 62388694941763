import { BASE_URL } from "../../apiConstants";
import axios from "axios";
import { CoreValueSelectionResponse } from "./type";


export async function getCoreValueSelection(token: string, level: number): Promise<CoreValueSelectionResponse> {
  const url = BASE_URL + `/WholeHealth/CoreValueSelection?Level=${level.toString()}`;
  try {
    const coreValueCateogryResponse = await axios.get<CoreValueSelectionResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return coreValueCateogryResponse.data;
  } catch (err) {
    throw err;
  }
}