import { SeityResponse } from "../baseTypes";

export enum AppContentType {
  CoreValueCongratulationsModal = 31
}

export class AppContent {
  appContentTypeID: number;
  description: string;
  appContentTextID: number;
  appContentTextKey: string;
  sectionNumber: number;
  languageID: number;
  contentText: string;

  constructor(appContentTypeID: number,
    description: string,
    appContentTextID: number,
    appContentTextKey: string,
    sectionNumber: number,
    languageID: number,
    contentText: string
  ) {
    this.appContentTypeID = appContentTypeID;
    this.description = description;
    this.appContentTextID = appContentTextID;
    this.appContentTextKey = appContentTextKey;
    this.sectionNumber = sectionNumber;
    this.languageID = languageID;
    this.contentText = contentText;
  }
}

export class AppContentResponse {
  success: boolean;
  message: string | null;
  data: { appContentLines: AppContent[] };
  constructor(success: boolean,
    message: string | null,
    data: { appContentLines: AppContent[] }
  ) {
    this.success = success;
    this.message = message;
    this.data = data;
  }
}