import axios from 'axios';
import { ServiceResponseCoreValueConfirmation } from './types';
import { BASE_URL } from "../apiConstants";

export async function confirmCoreValues(
  token: string,
  extendedDefer: boolean
): Promise<ServiceResponseCoreValueConfirmation> {
  const url = `${BASE_URL}/WholeHealth/CoreValueConfirmation`;
  try {
    const response = await axios.post<ServiceResponseCoreValueConfirmation>(
      url,
      { extendedDefer },
      {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (err: any) {
    if (err.response?.data?.userMessage) {
      err.message = err.response.data.userMessage;
    }
    throw err;
  }
}
