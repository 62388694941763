import React from 'react';
import './styles.scss';
import {GRAPHICS_URL} from '../../../../api/apiConstants';
import SeityButton from '../../../../_core/components/SeityButton';
import {SurveyCoreValuePage} from '../../../../api/getSurveyDetail/types';
import SeityCheckbox from "../../../../_core/components/SeityCheckbox";

interface CoreValuesConfirmationModalProps {
  isVisible: boolean;
  onClose: () => void;
  onConfirm: (extendedDefer: boolean) => void;
  onRetake: () => void;
  surveyCoreValuePage?: SurveyCoreValuePage;
}

const CoreValuesConfirmationModal: React.FC<CoreValuesConfirmationModalProps> =
  ({
     isVisible,
     onClose,
     onConfirm,
     onRetake,
     surveyCoreValuePage
   }) => {
    // If it's not visible or there's no data, return null
    if (!isVisible || !surveyCoreValuePage) {
      return null;
    }

    const {
      heading,
      subheading,
      deferText,
      confirmText,
      lastTaken,
      yesButtonText,
      retakeButtonText,
      surveyCoreValues
    } = surveyCoreValuePage;

    const [deferChecked, setDeferChecked] = React.useState<boolean>(false);

    return (
      <div className="core-values-confirmation-view">
        <div className="core-values-container">
          <div className="core-values-header">
            {/* If you need a back button, you can add it here */}
          </div>

          <div className="core-values-content">
            <h2 className="core-values-title">{heading}</h2>
            {subheading && subheading.trim() !== '' && (
              <p className="core-values-subheading">{subheading}</p>
            )}

            {surveyCoreValues && surveyCoreValues.length === 4 && (
              <div className="four-core-values-container">
                <div className="core-value-row">
                  <div className="core-value-card">
                    <img
                      className="core-value-image"
                      src={`${GRAPHICS_URL}${surveyCoreValues[0].image}.png`}
                      alt={surveyCoreValues[0].name}
                    />
                    <p className="core-value-text">{surveyCoreValues[0].name}</p>
                  </div>

                  <div className="core-value-card">
                    <img
                      className="core-value-image"
                      src={`${GRAPHICS_URL}${surveyCoreValues[1].image}.png`}
                      alt={surveyCoreValues[1].name}
                    />
                    <p className="core-value-text">{surveyCoreValues[1].name}</p>
                  </div>
                </div>

                <div className="core-value-row">
                  <div className="core-value-card">
                    <img
                      className="core-value-image"
                      src={`${GRAPHICS_URL}${surveyCoreValues[2].image}.png`}
                      alt={surveyCoreValues[2].name}
                    />
                    <p className="core-value-text">{surveyCoreValues[2].name}</p>
                  </div>

                  <div className="core-value-card">
                    <img
                      className="core-value-image"
                      src={`${GRAPHICS_URL}${surveyCoreValues[3].image}.png`}
                      alt={surveyCoreValues[3].name}
                    />
                    <p className="core-value-text">{surveyCoreValues[3].name}</p>
                  </div>
                </div>
              </div>
            )}

            {lastTaken && <p className="core-values-last-taken">{lastTaken}</p>}
            {confirmText && <p className="core-values-confirm-text">{confirmText}</p>}

            {deferText && (
              <div className="defer-row">
                <SeityCheckbox
                  checked={deferChecked}
                  onChange={(checked) => setDeferChecked(checked)}
                  label={deferText}
                />
              </div>
            )}

            <div className="buttons-container">
              <SeityButton
                onClick={() => onConfirm(deferChecked)}
                label={yesButtonText || 'Yes'}
              />
              <SeityButton
                onClick={onRetake}
                label={retakeButtonText || 'Retake Core Values'}
                type="outline"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

export default CoreValuesConfirmationModal;
