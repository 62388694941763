import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/rootReducer';
import AccordionList from '../AccordionList/AccordionList';
import SubcategoryCard from '../SubcategoryCard/SubcategoryCard';
import './styles.scss'; // Import the global styles

interface MySurveysTabProps {
  subcategorySvgData: { [key: string]: string };
  scrollViewRef: React.RefObject<HTMLDivElement>;
}

interface AccordionDataItem {
  id: string;
  title: string;
  subText: string;
  content: React.ReactNode;
}

const MySurveysTab: React.FC<MySurveysTabProps> = ({ subcategorySvgData, scrollViewRef }) => {
  const myWholeHealth = useSelector((state: RootState) => state.wholeHealth.myWholeHealth);
  const error = useSelector((state: RootState) => state.wholeHealth.error);

  console.log("my whole health", myWholeHealth);

  const data: AccordionDataItem[] = [];

  if (myWholeHealth) {
    if (myWholeHealth.practicesEnabled) {
      const categoryData: AccordionDataItem[] = myWholeHealth.myWholeHealthOutcomeCategories
        .map((category) => {
          const subcategories = myWholeHealth.myWholeHealthOutcomeSubcategories
            .filter(
              (sub) =>
                sub.outcomeCategoryID === category.outcomeCategoryID &&
                sub.practiceSectionStatusID !== 5
            )
            .sort((a, b) => a.outcomeSubcategorySortOrder - b.outcomeSubcategorySortOrder);

          if (subcategories.length === 0) {
            return null;
          }

          return {
            id: category.outcomeCategoryID.toString(),
            title: category.category,
            subText: `${category.surveysAvailable} surveys available`,
            content: (
              <div className="category-details">
                {subcategories.map((subcategory) => (
                  <SubcategoryCard
                    key={subcategory.outcomeSubcategoryID}
                    subcategoryId={subcategory.outcomeSubcategoryID}
                    svgXml={subcategorySvgData[subcategory.outcomeSubcategoryID.toString()]}
                    subcategory={subcategory.subcategory}
                    timeEstimateDescription={subcategory.timeEstimateDescription}
                    statusId={subcategory.practiceSectionStatusID}
                    statusDescription={subcategory.statusDescription}
                  />
                ))}
              </div>
            )
          };
        })
        .filter(Boolean) as AccordionDataItem[];

      data.push(...categoryData);
    }

    if (
      myWholeHealth.myWholeHealthLifeOutcome &&
      myWholeHealth.myWholeHealthLifeOutcome.outcomeStatusID !== 5 &&
      myWholeHealth.outcomesEnabled
    ) {
      data.push({
        id: 'lifeOutcome',
        title: myWholeHealth.myWholeHealthLifeOutcome.categoryName,
        subText: `${myWholeHealth.myWholeHealthLifeOutcome.surveysAvailable} surveys available`,
        content: (
          <div className="category-details">
            <SubcategoryCard
              subcategoryId={0}
              key="lifeOutcomeSubcategory"
              svgXml={subcategorySvgData.lifeOutcome}
              subcategory={myWholeHealth.myWholeHealthLifeOutcome.categoryName}
              timeEstimateDescription={
                myWholeHealth.myWholeHealthLifeOutcome.timeEstimateDescription
              }
              isLifeOutcome={true}
              statusId={myWholeHealth.myWholeHealthLifeOutcome.outcomeStatusID}
              statusDescription={myWholeHealth.myWholeHealthLifeOutcome.statusDescription}
            />
          </div>
        )
      });
    }
  }

  if (data.length === 0) {
    return (
      <div className="no-results-container">
        <p className="no-results-text">{myWholeHealth?.noSurveysText}</p>
      </div>
    );
  }

  return <AccordionList scrollViewRef={scrollViewRef} data={data} error={error} />;
};

export default MySurveysTab;
