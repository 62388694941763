import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { RootState } from '../../../../app/rootReducer';
import { useHistory, useParams } from 'react-router-dom';
import './styles.scss';
import SeityButton from "../../../../_core/components/SeityButton";
import {fetchSurveyDetail} from "../../slices/surveyOutcomeDetailSlice";
import {AppDispatch} from "../../../../app/store";

interface OutcomeAssessmentIncentiveRouteParams {
  screenID: string;
}

const OutcomeAssessmentIncentivePage: React.FC = () => {
  const { screenID } = useParams<OutcomeAssessmentIncentiveRouteParams>();
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();

  const surveyDetail = useSelector((state: RootState) => state.surveyOutcomeDetail.surveyDetail);

  const incentive = useMemo(() => {
    if (!surveyDetail || !surveyDetail.surveyIncentives) return null;
    return surveyDetail.surveyIncentives.find((inv) => inv.screenID === Number(screenID));
  }, [surveyDetail, screenID]);

  useEffect(() => {
    dispatch(fetchSurveyDetail(0));
  }, [dispatch]);

  const handleNext = () => {
    history.push('/mywholeHealth/outcomeAssessment');
  };

  if (!incentive) {
    return <div></div>;
  }

  return (
    <div className="outcome-assessment-incentive-container">
      <div className="background-image" />
      <div className="main-container">
        <div className="content-container">
          <h1 className="text01">{incentive.text01?.replace(/\\n/g, '\n')}</h1>
        </div>
        <div className="footer-container">
          <p className="text02">{incentive.text02}</p>
          <div className="button-wrapper"> {/* Add a wrapper if needed, otherwise just style button */}
            <SeityButton
              onClick={handleNext}
              label={incentive.buttonText || 'Continue'}
              type="next"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutcomeAssessmentIncentivePage;
