import { FunctionComponent, ReactElement } from "react";

import strings from "../../../../_core/strings/strings";
import { CoreValuesIncentive } from "../../../../api/coreValues/types";
import CVIncentiveImage from "../../../../assets/graphics/conffetigift.png";
import Image from "react-bootstrap/Image";

import "./styles.scss";

export type CVIncentiveModalProps = {
  isVisible: boolean;
  setVisible: Function;
  onCloseParent: Function;
  data: CoreValuesIncentive | null;
};

export const CVIncentiveModal: FunctionComponent<CVIncentiveModalProps> = ({
  isVisible,
  setVisible,
  onCloseParent,
  data
}): ReactElement => {
  const onClose = () => {
    setVisible(false);
    setTimeout(() => {
      onCloseParent();
    }, 0);
  };

  if (!data || data === null || !isVisible) return <></>;

  return (
    <div className="cv-incentive w-100 h-100">
      <div className="cv-incentive-back w-100 h-100"></div>
      <div className="cv-incentive-modal">
        <Image className="cv-incentive-img" src={CVIncentiveImage} />
        <p className="cv-incentive-header">{data.header}</p>
        <div
          className="cv-incentive-body"
          dangerouslySetInnerHTML={{
            __html: data.body
          }}
        />
        <div style={{ flex: 1 }}></div>
        <p className="cv-incentive-footer">{data.footer}</p>
        <button
          className="cv-incentive-done"
          onClick={(e) => {
            e.preventDefault();
            onClose();
          }}
        >
          {strings.gotIt}
        </button>
      </div>
    </div>
  );
};

export default CVIncentiveModal;
