import { SeityCoreValue, SeityResponse } from "../../baseTypes";

export class SeityDashboardResponse extends SeityResponse {
  data: SeityDashboardPayloadResponse | null;

  constructor(success: boolean, message: string | null, data: SeityDashboardPayloadResponse | null) {
    super(success, message);
    this.data = data;
  }
}

export class SeityDashboardPayloadResponse {
  firstName: string;
  lastName: string;
  messageCount: number;
  coreValueOne: SeityCoreValue;
  coreValueTwo: SeityCoreValue;
  coreValueThree: SeityCoreValue;
  coreValueFour: SeityCoreValue;
  checkInDate: string;
  energyScore: number;
  directionScore: number;
  belongingScore: number;
  joyScore: number;
  wellbeingScore: number;

  constructor(
    firstName: string,
    lastName: string,
    messageCount: number,
    coreValueOne: SeityCoreValue,
    coreValueTwo: SeityCoreValue,
    coreValueThree: SeityCoreValue,
    coreValueFour: SeityCoreValue,
    checkInDate: string,
    energyScore: number,
    directionScore: number,
    belongingScore: number,
    joyScore: number,
    wellbeingScore: number
  ) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.messageCount = messageCount;
    this.coreValueOne = coreValueOne;
    this.coreValueTwo = coreValueTwo;
    this.coreValueThree = coreValueThree;
    this.coreValueFour = coreValueFour;
    this.checkInDate = checkInDate;
    this.energyScore = energyScore;
    this.directionScore = directionScore;
    this.belongingScore = belongingScore;
    this.joyScore = joyScore;
    this.wellbeingScore = wellbeingScore;
  }
}

export class OutcomeScores {
  energy: number;
  energyCategory: string;
  energyLogoURL: string;
  energyText: string;
  direction: number;
  directionCategory: string;
  directionLogoURL: string;
  directionText: string;
  belonging: number;
  belongingCategory: string;
  belongingLogoURL: string;
  belongingText: string;
  joy: number;
  joyCategory: string;
  joyLogoURL: string;
  joyText: string;
  wholeHealth: number;
  wholeHealthCategory: string;
  wholeHealthLogoURL: string;
  wholeHealthText: string;

  constructor(
    energy: number,
    energyCategory: string,
    energyLogoURL: string,
    energyText: string,
    direction: number,
    directionCategory: string,
    directionLogoURL: string,
    directionText: string,
    belonging: number,
    belongingCategory: string,
    belongingLogoURL: string,
    belongingText: string,
    joy: number,
    joyCategory: string,
    joyLogoURL: string,
    joyText: string,
    wholeHealth: number,
    wholeHealthCategory: string,
    wholeHealthLogoURL: string,
    wholeHealthText: string
  ) {
    this.energy = energy;
    this.energyCategory = energyCategory;
    this.energyLogoURL = energyLogoURL;
    this.energyText = energyText;
    this.direction = direction;
    this.directionCategory = directionCategory;
    this.directionLogoURL = directionLogoURL;
    this.directionText = directionText;
    this.belonging = belonging;
    this.belongingCategory = belongingCategory;
    this.belongingLogoURL = belongingLogoURL;
    this.belongingText = belongingText;
    this.joy = joy;
    this.joyCategory = joyCategory;
    this.joyLogoURL = joyLogoURL;
    this.joyText = joyText;
    this.wholeHealth = wholeHealth;
    this.wholeHealthCategory = wholeHealthCategory;
    this.wholeHealthLogoURL = wholeHealthLogoURL;
    this.wholeHealthText = wholeHealthText;
  }
}

export class SeityDepartmentListResponse extends SeityResponse {
  data: SeityDepartmentPayloadResponse | null;

  constructor(success: boolean, message: string | null, data: SeityDepartmentPayloadResponse | null) {
    super(success, message);
    this.data = data;
  }
}

export class SeityDepartmentPayloadResponse {
  constructor(public departmentEnabled: boolean, public departmentName: null | string, public departments: Array<SeityDepartment>) {}
}
export class SeityDepartment {
  constructor(public departmentID: number, public departmentName: string) {}
}

export class SeityJobPositionPayloadResponse {
  constructor(public jobPositionEnabled: boolean, public jobPositionName: null | string, public jobPositions: Array<SeityJobPosition>) {}
}
export class SeityJobPosition {
  constructor(public jobPositionID: number, public jobPositionName: string) {}
}

export class OutcomeScoresResponse {
  success: boolean;
  message: string | null;
  data: OutcomeScores;

  constructor(success: boolean, message: string | null, data: OutcomeScores) {
    this.success = success;
    this.message = message;
    this.data = data;
  }
}

export class PracticeScores {
  firstName: string;
  lastAssessmentDate: string;
  worldEcosystem: number;
  worldEcosystemCategory: string;
  worldEcosystemLogoURL: string;
  worldEcosystemPracticeDescription: string;
  worldEcosystemDragonText: string;
  worldEcosystemDragonLogo: string;
  worldEcosystemDragonLabel: string;
  worldEcosystemDragonDescription: string;
  worldSafety: number;
  worldSafetyCategory: string;
  worldSafetyLogoURL: string;
  worldSafetyPracticeDescription: string;
  worldSafetyDragonText: string;
  worldSafetyDragonLogo: string;
  worldSafetyDragonLabel: string;
  worldSafetyDragonDescription: string;
  worldWork: number;
  worldWorkCategory: string;
  worldWorkLogoURL: string;
  worldWorkPracticeDescription: string;
  worldWorkDragonText: string;
  worldWorkDragonLogo: string;
  worldWorkDragonLabel: string;
  worldWorkDragonDescription: string;
  worldSocialTies: number;
  worldSocialTiesCategory: string;
  worldSocialTiesLogoURL: string;
  worldSocialTiesPracticeDescription: string;
  worldSocialTiesDragonText: string;
  worldSocialTiesDragonLogo: string;
  worldSocialTiesDragonLabel: string;
  worldSocialTiesDragonDescription: string;
  worldRelationships: number;
  worldRelationshipsCategory: string;
  worldRelationshipsLogoURL: string;
  worldRelationshipsPracticeDescription: string;
  worldRelationshipsDragonText: string;
  worldRelationshipsDragonLogo: string;
  worldRelationshipsDragonLabel: string;
  worldRelationshipsDragonDescription: string;
  worldFinances: number;
  worldFinancesCategory: string;
  worldFinancesLogoURL: string;
  worldFinancesPracticeDescription: string;
  worldFinancesDragonText: string;
  worldFinancesDragonLogo: string;
  worldFinancesDragonLabel: string;
  worldFinancesDragonDescription: string;
  world: number;
  worldCategory: string;
  worldLogoURL: string;
  bodyHealthcare: number;
  bodyHealthcareCategory: string;
  bodyHealthcareLogoURL: string;
  bodyHealthcarePracticeDescription: string;
  bodyHealthcareDragonText: string;
  bodyHealthcareDragonLogo: string;
  bodyHealthcareDragonLabel: string;
  bodyHealthcareDragonDescription: string;
  bodyAddictionPrevention: number;
  bodyAddictionPreventionCategory: string;
  bodyAddictionPreventionLogoURL: string;
  bodyAddictionPreventionPracticeDescription: string;
  bodyAddictionPreventionDragonText: string;
  bodyAddictionPreventionDragonLogo: string;
  bodyAddictionPreventionDragonLabel: string;
  bodyAddictionPreventionDragonDescription: string;
  bodyExercise: number;
  bodyExerciseCategory: string;
  bodyExerciseLogoURL: string;
  bodyExercisePracticeDescription: string;
  bodyExerciseDragonText: string;
  bodyExerciseDragonLogo: string;
  bodyExerciseDragonLabel: string;
  bodyExerciseDragonDescription: string;
  bodyNutrition: number;
  bodyNutritionCategory: string;
  bodyNutritionLogoURL: string;
  bodyNutritionPracticeDescription: string;
  bodyNutritionDragonText: string;
  bodyNutritionDragonLogo: string;
  bodyNutritionDragonLabel: string;
  bodyNutritionDragonDescription: string;
  bodySleep: number;
  bodySleepCategory: string;
  bodySleepLogoURL: string;
  bodySleepPracticeDescription: string;
  bodySleepDragonText: string;
  bodySleepDragonLogo: string;
  bodySleepDragonLabel: string;
  bodySleepDragonDescription: string;
  body: number;
  bodyCategory: string;
  bodyLogoURL: string;
  mindThoughts: number;
  mindThoughtsCategory: string;
  mindThoughtsLogoURL: string;
  mindThoughtsPracticeDescription: string;
  mindThoughtsDragonText: string;
  mindThoughtsDragonLogo: string;
  mindThoughtsDragonLabel: string;
  mindThoughtsDragonDescription: string;
  mindFeelings: number;
  mindFeelingsCategory: string;
  mindFeelingsLogoURL: string;
  mindFeelingsPracticeDescription: string;
  mindFeelingsDragonText: string;
  mindFeelingsDragonLogo: string;
  mindFeelingsDragonLabel: string;
  mindFeelingsDragonDescription: string;
  mindMemories: number;
  mindMemoriesCategory: string;
  mindMemoriesLogoURL: string;
  mindMemoriesPracticeDescription: string;
  mindMemoriesDragonText: string;
  mindMemoriesDragonLogo: string;
  mindMemoriesDragonLabel: string;
  mindMemoriesDragonDescription: string;
  mindKnowledge: number;
  mindKnowledgeCategory: string;
  mindKnowledgeLogoURL: string;
  mindKnowledgePracticeDescription: string;
  mindKnowledgeDragonText: string;
  mindKnowledgeDragonLogo: string;
  mindKnowledgeDragonLabel: string;
  mindKnowledgeDragonDescription: string;
  mind: number;
  mindCategory: string;
  mindLogoURL: string;
  spiritCoreValueConnections: number;
  spiritCoreValueConnectionsCategory: string;
  spiritCoreValueConnectionsLogoURL: string;
  spiritCoreValueConnectionsPracticeDescription: string;
  spiritCoreValueConnectionsDragonText: string;
  spiritCoreValueConnectionsDragonLogo: string;
  spiritCoreValueConnectionsDragonLabel: string;
  spiritCoreValueConnectionsDragonDescription: string;
  spiritSpiritualConnections: number;
  spiritSpiritualConnectionsCategory: string;
  spiritSpiritualConnectionsLogoURL: string;
  spiritSpiritualConnectionsPracticeDescription: string;
  spiritSpiritualConnectionsDragonText: string;
  spiritSpiritualConnectionsDragonLogo: string;
  spiritSpiritualConnectionsDragonLabel: string;
  spiritSpiritualConnectionsDragonDescription: string;
  spiritSpiritualTransactions: number;
  spiritSpiritualTransactionsCategory: string;
  spiritSpiritualTransactionsLogoURL: string;
  spiritSpiritualTransactionsPracticeDescription: string;
  spiritSpiritualTransactionsDragonText: string;
  spiritSpiritualTransactionsDragonLogo: string;
  spiritSpiritualTransactionsDragonLabel: string;
  spiritSpiritualTransactionsDragonDescription: string;
  spirit: number;
  spiritCategory: string;
  spiritLogoURL: string;

  constructor(
    firstName: string,
    lastAssessmentDate: string,
    worldEcosystem: number,
    worldEcosystemCategory: string,
    worldEcosystemLogoURL: string,
    worldEcosystemPracticeDescription: string,
    worldEcosystemDragonText: string,
    worldEcosystemDragonLogo: string,
    worldEcosystemDragonLabel: string,
    worldEcosystemDragonDescription: string,
    worldSafety: number,
    worldSafetyCategory: string,
    worldSafetyLogoURL: string,
    worldSafetyPracticeDescription: string,
    worldSafetyDragonText: string,
    worldSafetyDragonLogo: string,
    worldSafetyDragonLabel: string,
    worldSafetyDragonDescription: string,
    worldWork: number,
    worldWorkCategory: string,
    worldWorkLogoURL: string,
    worldWorkPracticeDescription: string,
    worldWorkDragonText: string,
    worldWorkDragonLogo: string,
    worldWorkDragonLabel: string,
    worldWorkDragonDescription: string,
    worldSocialTies: number,
    worldSocialTiesCategory: string,
    worldSocialTiesLogoURL: string,
    worldSocialTiesPracticeDescription: string,
    worldSocialTiesDragonText: string,
    worldSocialTiesDragonLogo: string,
    worldSocialTiesDragonLabel: string,
    worldSocialTiesDragonDescription: string,
    worldRelationships: number,
    worldRelationshipsCategory: string,
    worldRelationshipsLogoURL: string,
    worldRelationshipsPracticeDescription: string,
    worldRelationshipsDragonText: string,
    worldRelationshipsDragonLogo: string,
    worldRelationshipsDragonLabel: string,
    worldRelationshipsDragonDescription: string,
    worldFinances: number,
    worldFinancesCategory: string,
    worldFinancesLogoURL: string,
    worldFinancesPracticeDescription: string,
    worldFinancesDragonText: string,
    worldFinancesDragonLogo: string,
    worldFinancesDragonLabel: string,
    worldFinancesDragonDescription: string,
    world: number,
    worldCategory: string,
    worldLogoURL: string,
    bodyHealthcare: number,
    bodyHealthcareCategory: string,
    bodyHealthcareLogoURL: string,
    bodyHealthcarePracticeDescription: string,
    bodyHealthcareDragonText: string,
    bodyHealthcareDragonLogo: string,
    bodyHealthcareDragonLabel: string,
    bodyHealthcareDragonDescription: string,
    bodyAddictionPrevention: number,
    bodyAddictionPreventionCategory: string,
    bodyAddictionPreventionLogoURL: string,
    bodyAddictionPreventionPracticeDescription: string,
    bodyAddictionPreventionDragonText: string,
    bodyAddictionPreventionDragonLogo: string,
    bodyAddictionPreventionDragonLabel: string,
    bodyAddictionPreventionDragonDescription: string,
    bodyExercise: number,
    bodyExerciseCategory: string,
    bodyExerciseLogoURL: string,
    bodyExercisePracticeDescription: string,
    bodyExerciseDragonText: string,
    bodyExerciseDragonLogo: string,
    bodyExerciseDragonLabel: string,
    bodyExerciseDragonDescription: string,
    bodyNutrition: number,
    bodyNutritionCategory: string,
    bodyNutritionLogoURL: string,
    bodyNutritionPracticeDescription: string,
    bodyNutritionDragonText: string,
    bodyNutritionDragonLogo: string,
    bodyNutritionDragonLabel: string,
    bodyNutritionDragonDescription: string,
    bodySleep: number,
    bodySleepCategory: string,
    bodySleepLogoURL: string,
    bodySleepPracticeDescription: string,
    bodySleepDragonText: string,
    bodySleepDragonLogo: string,
    bodySleepDragonLabel: string,
    bodySleepDragonDescription: string,
    body: number,
    bodyCategory: string,
    bodyLogoURL: string,
    mindThoughts: number,
    mindThoughtsCategory: string,
    mindThoughtsLogoURL: string,
    mindThoughtsPracticeDescription: string,
    mindThoughtsDragonText: string,
    mindThoughtsDragonLogo: string,
    mindThoughtsDragonLabel: string,
    mindThoughtsDragonDescription: string,
    mindFeelings: number,
    mindFeelingsCategory: string,
    mindFeelingsLogoURL: string,
    mindFeelingsPracticeDescription: string,
    mindFeelingsDragonText: string,
    mindFeelingsDragonLogo: string,
    mindFeelingsDragonLabel: string,
    mindFeelingsDragonDescription: string,
    mindMemories: number,
    mindMemoriesCategory: string,
    mindMemoriesLogoURL: string,
    mindMemoriesPracticeDescription: string,
    mindMemoriesDragonText: string,
    mindMemoriesDragonLogo: string,
    mindMemoriesDragonLabel: string,
    mindMemoriesDragonDescription: string,
    mindKnowledge: number,
    mindKnowledgeCategory: string,
    mindKnowledgeLogoURL: string,
    mindKnowledgePracticeDescription: string,
    mindKnowledgeDragonText: string,
    mindKnowledgeDragonLogo: string,
    mindKnowledgeDragonLabel: string,
    mindKnowledgeDragonDescription: string,
    mind: number,
    mindCategory: string,
    mindLogoURL: string,
    spiritCoreValueConnections: number,
    spiritCoreValueConnectionsCategory: string,
    spiritCoreValueConnectionsLogoURL: string,
    spiritCoreValueConnectionsPracticeDescription: string,
    spiritCoreValueConnectionsDragonText: string,
    spiritCoreValueConnectionsDragonLogo: string,
    spiritCoreValueConnectionsDragonLabel: string,
    spiritCoreValueConnectionsDragonDescription: string,
    spiritSpiritualConnections: number,
    spiritSpiritualConnectionsCategory: string,
    spiritSpiritualConnectionsLogoURL: string,
    spiritSpiritualConnectionsPracticeDescription: string,
    spiritSpiritualConnectionsDragonText: string,
    spiritSpiritualConnectionsDragonLogo: string,
    spiritSpiritualConnectionsDragonLabel: string,
    spiritSpiritualConnectionsDragonDescription: string,
    spiritSpiritualTransactions: number,
    spiritSpiritualTransactionsCategory: string,
    spiritSpiritualTransactionsLogoURL: string,
    spiritSpiritualTransactionsPracticeDescription: string,
    spiritSpiritualTransactionsDragonText: string,
    spiritSpiritualTransactionsDragonLogo: string,
    spiritSpiritualTransactionsDragonLabel: string,
    spiritSpiritualTransactionsDragonDescription: string,
    spirit: number,
    spiritCategory: string,
    spiritLogoURL: string
  ) {
    this.firstName = firstName;
    this.lastAssessmentDate = lastAssessmentDate;
    this.worldEcosystem = worldEcosystem;
    this.worldEcosystemCategory = worldEcosystemCategory;
    this.worldEcosystemLogoURL = worldEcosystemLogoURL;
    this.worldEcosystemPracticeDescription = worldEcosystemPracticeDescription;
    this.worldEcosystemDragonText = worldEcosystemDragonText;
    this.worldEcosystemDragonLogo = worldEcosystemDragonLogo;
    this.worldEcosystemDragonLabel = worldEcosystemDragonLabel;
    this.worldEcosystemDragonDescription = worldEcosystemDragonDescription;
    this.worldSafety = worldSafety;
    this.worldSafetyCategory = worldSafetyCategory;
    this.worldSafetyLogoURL = worldSafetyLogoURL;
    this.worldSafetyPracticeDescription = worldSafetyPracticeDescription;
    this.worldSafetyDragonText = worldSafetyDragonText;
    this.worldSafetyDragonLogo = worldSafetyDragonLogo;
    this.worldSafetyDragonLabel = worldSafetyDragonLabel;
    this.worldSafetyDragonDescription = worldSafetyDragonDescription;
    this.worldWork = worldWork;
    this.worldWorkCategory = worldWorkCategory;
    this.worldWorkLogoURL = worldWorkLogoURL;
    this.worldWorkPracticeDescription = worldWorkPracticeDescription;
    this.worldWorkDragonText = worldWorkDragonText;
    this.worldWorkDragonLogo = worldWorkDragonLogo;
    this.worldWorkDragonLabel = worldWorkDragonLabel;
    this.worldWorkDragonDescription = worldWorkDragonDescription;
    this.worldSocialTies = worldSocialTies;
    this.worldSocialTiesCategory = worldSocialTiesCategory;
    this.worldSocialTiesLogoURL = worldSocialTiesLogoURL;
    this.worldSocialTiesPracticeDescription = worldSocialTiesPracticeDescription;
    this.worldSocialTiesDragonText = worldSocialTiesDragonText;
    this.worldSocialTiesDragonLogo = worldSocialTiesDragonLogo;
    this.worldSocialTiesDragonLabel = worldSocialTiesDragonLabel;
    this.worldSocialTiesDragonDescription = worldSocialTiesDragonDescription;
    this.worldRelationships = worldRelationships;
    this.worldRelationshipsCategory = worldRelationshipsCategory;
    this.worldRelationshipsLogoURL = worldRelationshipsLogoURL;
    this.worldRelationshipsPracticeDescription = worldRelationshipsPracticeDescription;
    this.worldRelationshipsDragonText = worldRelationshipsDragonText;
    this.worldRelationshipsDragonLogo = worldRelationshipsDragonLogo;
    this.worldRelationshipsDragonLabel = worldRelationshipsDragonLabel;
    this.worldRelationshipsDragonDescription = worldRelationshipsDragonDescription;
    this.worldFinances = worldFinances;
    this.worldFinancesCategory = worldFinancesCategory;
    this.worldFinancesLogoURL = worldFinancesLogoURL;
    this.worldFinancesPracticeDescription = worldFinancesPracticeDescription;
    this.worldFinancesDragonText = worldFinancesDragonText;
    this.worldFinancesDragonLogo = worldFinancesDragonLogo;
    this.worldFinancesDragonLabel = worldFinancesDragonLabel;
    this.worldFinancesDragonDescription = worldFinancesDragonDescription;
    this.world = world;
    this.worldCategory = worldCategory;
    this.worldLogoURL = worldLogoURL;
    this.bodyHealthcare = bodyHealthcare;
    this.bodyHealthcareCategory = bodyHealthcareCategory;
    this.bodyHealthcareLogoURL = bodyHealthcareLogoURL;
    this.bodyHealthcarePracticeDescription = bodyHealthcarePracticeDescription;
    this.bodyHealthcareDragonText = bodyHealthcareDragonText;
    this.bodyHealthcareDragonLogo = bodyHealthcareDragonLogo;
    this.bodyHealthcareDragonLabel = bodyHealthcareDragonLabel;
    this.bodyHealthcareDragonDescription = bodyHealthcareDragonDescription;
    this.bodyAddictionPrevention = bodyAddictionPrevention;
    this.bodyAddictionPreventionCategory = bodyAddictionPreventionCategory;
    this.bodyAddictionPreventionLogoURL = bodyAddictionPreventionLogoURL;
    this.bodyAddictionPreventionPracticeDescription = bodyAddictionPreventionPracticeDescription;
    this.bodyAddictionPreventionDragonText = bodyAddictionPreventionDragonText;
    this.bodyAddictionPreventionDragonLogo = bodyAddictionPreventionDragonLogo;
    this.bodyAddictionPreventionDragonLabel = bodyAddictionPreventionDragonLabel;
    this.bodyAddictionPreventionDragonDescription = bodyAddictionPreventionDragonDescription;
    this.bodyExercise = bodyExercise;
    this.bodyExerciseCategory = bodyExerciseCategory;
    this.bodyExerciseLogoURL = bodyExerciseLogoURL;
    this.bodyExercisePracticeDescription = bodyExercisePracticeDescription;
    this.bodyExerciseDragonText = bodyExerciseDragonText;
    this.bodyExerciseDragonLogo = bodyExerciseDragonLogo;
    this.bodyExerciseDragonLabel = bodyExerciseDragonLabel;
    this.bodyExerciseDragonDescription = bodyExerciseDragonDescription;
    this.bodyNutrition = bodyNutrition;
    this.bodyNutritionCategory = bodyNutritionCategory;
    this.bodyNutritionLogoURL = bodyNutritionLogoURL;
    this.bodyNutritionPracticeDescription = bodyNutritionPracticeDescription;
    this.bodyNutritionDragonText = bodyNutritionDragonText;
    this.bodyNutritionDragonLogo = bodyNutritionDragonLogo;
    this.bodyNutritionDragonLabel = bodyNutritionDragonLabel;
    this.bodyNutritionDragonDescription = bodyNutritionDragonDescription;
    this.bodySleep = bodySleep;
    this.bodySleepCategory = bodySleepCategory;
    this.bodySleepLogoURL = bodySleepLogoURL;
    this.bodySleepPracticeDescription = bodySleepPracticeDescription;
    this.bodySleepDragonText = bodySleepDragonText;
    this.bodySleepDragonLogo = bodySleepDragonLogo;
    this.bodySleepDragonLabel = bodySleepDragonLabel;
    this.bodySleepDragonDescription = bodySleepDragonDescription;
    this.body = body;
    this.bodyCategory = bodyCategory;
    this.bodyLogoURL = bodyLogoURL;
    this.mindThoughts = mindThoughts;
    this.mindThoughtsCategory = mindThoughtsCategory;
    this.mindThoughtsLogoURL = mindThoughtsLogoURL;
    this.mindThoughtsPracticeDescription = mindThoughtsPracticeDescription;
    this.mindThoughtsDragonText = mindThoughtsDragonText;
    this.mindThoughtsDragonLogo = mindThoughtsDragonLogo;
    this.mindThoughtsDragonLabel = mindThoughtsDragonLabel;
    this.mindThoughtsDragonDescription = mindThoughtsDragonDescription;
    this.mindFeelings = mindFeelings;
    this.mindFeelingsCategory = mindFeelingsCategory;
    this.mindFeelingsLogoURL = mindFeelingsLogoURL;
    this.mindFeelingsPracticeDescription = mindFeelingsPracticeDescription;
    this.mindFeelingsDragonText = mindFeelingsDragonText;
    this.mindFeelingsDragonLogo = mindFeelingsDragonLogo;
    this.mindFeelingsDragonLabel = mindFeelingsDragonLabel;
    this.mindFeelingsDragonDescription = mindFeelingsDragonDescription;
    this.mindMemories = mindMemories;
    this.mindMemoriesCategory = mindMemoriesCategory;
    this.mindMemoriesLogoURL = mindMemoriesLogoURL;
    this.mindMemoriesPracticeDescription = mindMemoriesPracticeDescription;
    this.mindMemoriesDragonText = mindMemoriesDragonText;
    this.mindMemoriesDragonLogo = mindMemoriesDragonLogo;
    this.mindMemoriesDragonLabel = mindMemoriesDragonLabel;
    this.mindMemoriesDragonDescription = mindMemoriesDragonDescription;
    this.mindKnowledge = mindKnowledge;
    this.mindKnowledgeCategory = mindKnowledgeCategory;
    this.mindKnowledgeLogoURL = mindKnowledgeLogoURL;
    this.mindKnowledgePracticeDescription = mindKnowledgePracticeDescription;
    this.mindKnowledgeDragonText = mindKnowledgeDragonText;
    this.mindKnowledgeDragonLogo = mindKnowledgeDragonLogo;
    this.mindKnowledgeDragonLabel = mindKnowledgeDragonLabel;
    this.mindKnowledgeDragonDescription = mindKnowledgeDragonDescription;
    this.mind = mind;
    this.mindCategory = mindCategory;
    this.mindLogoURL = mindLogoURL;
    this.spiritCoreValueConnections = spiritCoreValueConnections;
    this.spiritCoreValueConnectionsCategory = spiritCoreValueConnectionsCategory;
    this.spiritCoreValueConnectionsLogoURL = spiritCoreValueConnectionsLogoURL;
    this.spiritCoreValueConnectionsPracticeDescription = spiritCoreValueConnectionsPracticeDescription;
    this.spiritCoreValueConnectionsDragonText = spiritCoreValueConnectionsDragonText;
    this.spiritCoreValueConnectionsDragonLogo = spiritCoreValueConnectionsDragonLogo;
    this.spiritCoreValueConnectionsDragonLabel = spiritCoreValueConnectionsDragonLabel;
    this.spiritCoreValueConnectionsDragonDescription = spiritCoreValueConnectionsDragonDescription;
    this.spiritSpiritualConnections = spiritSpiritualConnections;
    this.spiritSpiritualConnectionsCategory = spiritSpiritualConnectionsCategory;
    this.spiritSpiritualConnectionsLogoURL = spiritSpiritualConnectionsLogoURL;
    this.spiritSpiritualConnectionsPracticeDescription = spiritSpiritualConnectionsPracticeDescription;
    this.spiritSpiritualConnectionsDragonText = spiritSpiritualConnectionsDragonText;
    this.spiritSpiritualConnectionsDragonLogo = spiritSpiritualConnectionsDragonLogo;
    this.spiritSpiritualConnectionsDragonLabel = spiritSpiritualConnectionsDragonLabel;
    this.spiritSpiritualConnectionsDragonDescription = spiritSpiritualConnectionsDragonDescription;
    this.spiritSpiritualTransactions = spiritSpiritualTransactions;
    this.spiritSpiritualTransactionsCategory = spiritSpiritualTransactionsCategory;
    this.spiritSpiritualTransactionsLogoURL = spiritSpiritualTransactionsLogoURL;
    this.spiritSpiritualTransactionsPracticeDescription = spiritSpiritualTransactionsPracticeDescription;
    this.spiritSpiritualTransactionsDragonText = spiritSpiritualTransactionsDragonText;
    this.spiritSpiritualTransactionsDragonLogo = spiritSpiritualTransactionsDragonLogo;
    this.spiritSpiritualTransactionsDragonLabel = spiritSpiritualTransactionsDragonLabel;
    this.spiritSpiritualTransactionsDragonDescription = spiritSpiritualTransactionsDragonDescription;
    this.spirit = spirit;
    this.spiritCategory = spiritCategory;
    this.spiritLogoURL = spiritLogoURL;
  }
}

export class PracticeScoresResponse {
  success: boolean;
  message: string | null;
  data: PracticeScores;

  constructor(success: boolean, message: string | null, data: PracticeScores) {
    this.success = success;
    this.message = message;
    this.data = data;
  }
}

export class SeityReportDownloadPayloadResponse {
  fileName: string;
  contents: string;

  constructor(fileName: string, contents: string) {
    this.fileName = fileName;
    this.contents = contents;
  }
}

export class SeityReportDownloadResponse extends SeityResponse {
  data: SeityReportDownloadPayloadResponse | null;

  constructor(success: boolean, message: string | null, data: SeityReportDownloadPayloadResponse | null) {
    super(success, message);
    this.data = data;
  }
}

export class VerificationCodeResponse {
  success: boolean;
  message: string | null;
  endPointMessageID?: number;

  constructor(success: boolean, message: string | null, endPointMessageID: number) {
    this.success = success;
    this.message = message;
    this.endPointMessageID = endPointMessageID;
  }
}

export class ValidateVerificationCodeResponse {
  success: boolean;
  message: string | null;
  endPointMessageID?: number;

  constructor(success: boolean, message: string | null, endPointMessageID: number) {
    this.success = success;
    this.message = message;
    this.endPointMessageID = endPointMessageID;
  }
}

export class UpdateEmailResponse {
  success: boolean;
  message: string | null;
  constructor(success: boolean, message: string | null) {
    this.success = success;
    this.message = message;
  }
}

export class LanguageItem {
  languageID: number;
  description: string;
  code: string;

  constructor(languageID: number, description: string, code: string) {
    this.languageID = languageID;
    this.description = description;
    this.code = code;
  }
}

export class LanguageResponse {
  success: boolean;
  message: string | null;
  data: [LanguageItem];

  constructor(success: boolean, message: string | null, data: [LanguageItem]) {
    this.success = success;
    this.message = message;
    this.data = data;
  }
}

export class CoreValuesMode {
  title: string;
  subTitle: string;
  description: string;
  buttonText: string;
  coreValuesEnabled: boolean;
  coreValuesStatusID: number;

  constructor(title: string, subtitle: string,
     description: string, buttonText: string, 
     coreValuesEnabled: boolean, coreValuesStatusID: number, ) {
    this.title = title;
    this.subTitle = subtitle;
    this.description = description;
    this.buttonText = buttonText;
    this.coreValuesEnabled = coreValuesEnabled;
    this.coreValuesStatusID = coreValuesStatusID;
  }
}

export enum CoreValuesStatus {
  CompleteNoActionRequired = 1,
  RequiredNeverCompleted = 2,
  RequiredOpenRequested = 3,
  RequiredCompletedDaysExpired = 4,
  RequiredConfirmationDaysExpired = 5,
  ErorrRetrievingStatus = 6
}
