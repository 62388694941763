import { FunctionComponent, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import classNames from "classnames";
import moment from "moment";

import { RootState } from "../../app/rootReducer";
import { SeityAppContext } from "../../app/SeityAppContext";
import { getPracticeScoresListRequest } from "../practices/practicesSlice";
import { sendAccountMaintenanceUpdateRequest, sendGetAccountInfoRequest, sendMessageCenterListRequest } from "../account/accountSlice";
import { getCharacterizationRequest } from "./myStorySlice";
import { languages } from "../account/Language/data";

import { SeityContentWrap } from "../../_core/components/SeityContentWrap";
import { ArtOfYou } from "./ArtOfYou/ArtOfYou";
import { CheckInGraphs } from "./CheckInGraphs/CheckInGraphs";
import { AssessmentOutcomes } from "./AssessmentOutcomes/AssessmentOutcomes";
import { TameYourDragons } from "./TameYourDragons/TameYourDragons";
import { PrivacyAndInformation } from "./PrivacyAndInformation/PrivacyAndInformation";
import { ArtOfYouCarousel } from "./ArtOfYou/components/ArtOfYouCarousel";
import { YourCoreValues } from "./ArtOfYou/components/YourCoreValues";

import { switchLanguage } from "../../app/utils";

import "./styles.scss";
import { getSortedFourCoreValuesRequest } from "../coreValues/coreValuesSlice";
import { getUserAppSettingsRequest } from "../../slices/userAppSettingsSlice";

/**
 * @deprecated
 * @constructor
 */
export const MyStory: FunctionComponent = () => {
  const dispatch = useDispatch();
  const context = useContext(SeityAppContext);

  const {
    error: characterizationError,
    isLoading: isCharacterizationLoading,
    characterization
  } = useSelector((state: RootState) => {
    return state.characterization;
  });

  const { isCVOnly } = useSelector((state: RootState) => {
    return state.userAppSettings.userAppSettings;
  });

  const { sortedFourCoreValues } = useSelector((state: RootState) => {
    return state.fourCoreValues;
  });

  const { ragingDragons, awakeDragons, tameDragons } = useSelector((state: RootState) => {
    return state.practiceScores;
  });

  const { accountInfo, messageCenterList, isCheckInRedirected } = useSelector((state: RootState) => {
    return state.account;
  });

  useEffect(() => {
    dispatch(getUserAppSettingsRequest());
    dispatch(getCharacterizationRequest());
    dispatch(sendGetAccountInfoRequest());
    dispatch(sendMessageCenterListRequest());
    dispatch(getPracticeScoresListRequest());
    dispatch(getSortedFourCoreValuesRequest());

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (characterization.languageID) {
      switchLanguage(characterization.languageID);
    }
  }, [characterization.languageID]);

  const { shouldRedirectToLogin } = useSelector((state: RootState) => {
    return state.auth;
  });

  if (
    messageCenterList.some((m) => m.messageTypeID === 3) &&
    !characterization.emailVerificationNeeded &&
    !characterization.forceNewPassword &&
    !isCharacterizationLoading &&
    !isCheckInRedirected
  ) {
    return <Redirect to="/checkin/wellbeingCheckIn" />;
  }

  // only show loading for the first, i.e. informatin text is empty
  if (isCharacterizationLoading && characterization.information === "") {
    return (
      <SeityContentWrap
        isBackgroundLoading
        showBackgroundMask
        onDismiss={() => {
          console.log("onDismiss");
        }}
      />
    );
  }

  if (!characterizationError) {
    return (
      <SeityContentWrap>
        <div className="mystory-content">
          <div className="aoy-intro-wrapper">
            <ArtOfYouCarousel firstName={accountInfo.firstName} />
            {sortedFourCoreValues.length === 4 && <YourCoreValues fourCoreValues={sortedFourCoreValues} />}
          </div>
          <div className="mystory-content-wrapper">
            <div className="mystory-content-wrapper-info">
              {/* <CheckInGraphs characterization={characterization} /> */}
            </div>
            {!isCVOnly && (
              <>
                <div className="mystory-content-wrapper-info">
                  <AssessmentOutcomes characterization={characterization} />
                </div>
                <div className="mystory-content-wrapper-info">
                  <ArtOfYou characterization={characterization} accountInfo={accountInfo} />
                </div>
                <div className="mystory-content-wrapper-info">
                  <TameYourDragons
                    characterization={characterization}
                    ragingDragons={ragingDragons}
                    awakeDragons={awakeDragons}
                    tameDragons={tameDragons}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </SeityContentWrap>
    );
  }
  return <></>;
};
