import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './styles.scss';
import { RootState } from '../../../../app/rootReducer';
import { GRAPHICS_URL } from '../../../../api/apiConstants';
import { subcategoryImages } from '../../assets/subcategoryImages';
import SeityButton from '../../../../_core/components/SeityButton';
import { fetchSurveyOutro } from '../../slices/surveyOutroSlice';
import { fetchSurveyDetail } from "../../slices/surveyDetailSlice";

interface OutcomeAssessmentOutroRouteParams {
  subcategoryID: string;
}

const OutcomeAssessmentOutroPage: React.FC = () => {
  const { subcategoryID } = useParams<OutcomeAssessmentOutroRouteParams>();
  const dispatch = useDispatch();
  const history = useHistory();

  const surveyOutro = useSelector((state: RootState) => state.surveyOutro.surveyOutro);
  const categoryHeading = useSelector((state: RootState) => state.surveyDetail.surveyDetail?.categoryHeading);
  const isLoading = useSelector((state: RootState) => state.surveyOutro.isLoading);
  const error = useSelector((state: RootState) => state.surveyOutro.error);

  const [useFallbackHeaderImage, setUseFallbackHeaderImage] = useState(false);
  const [headerImageLoaded, setHeaderImageLoaded] = useState(false);

  useEffect(() => {
    // Fetching detail and outro with ID 0 as requested.
    dispatch(fetchSurveyDetail(Number(subcategoryID)));
    dispatch(fetchSurveyOutro(0));
  }, [dispatch, subcategoryID]);

  const handleContinue = () => {
    history.push('/MyWholeHealth');
  };

  if (isLoading) {
    return <div className="loadingContainer">Loading...</div>;
  }

  if (error) {
    return <div className="errorText">{error}</div>;
  }

  if (!surveyOutro) {
    return <div className="errorText">No outro data available.</div>;
  }

  const {
    sectionName,
    heading,
    score,
    sectionImage01,
    resultsText,
    continueButtonText
  } = surveyOutro;

  const fallbackImage = subcategoryImages[Number(subcategoryID) ?? 1] || subcategoryImages[1];

  const headerImageSource = useFallbackHeaderImage
    ? fallbackImage
    : GRAPHICS_URL + sectionImage01;

  return (
    <div className="outcome-assessment-outro-container">
      <div className="outro-content-container">
        <div className="outro-heading-container">
          {categoryHeading && <p className="outro-category-heading">{categoryHeading}</p>}
          <h2 className="outro-section-name">{sectionName}</h2>
        </div>

        {!headerImageLoaded && (
          <div className="outro-header-image-placeholder"/>
        )}
        <img
          className={`outro-header-image ${!headerImageLoaded ? 'outro-image-invisible' : ''}`}
          src={headerImageSource}
          alt="Section"
          onLoad={() => setHeaderImageLoaded(true)}
          onError={() => setUseFallbackHeaderImage(true)}
        />

        {/* Results Container (similar to React Native snippet) */}
        <div className="results-container">
          {heading && <p className="score-text">{heading}</p>}
          {score && <p className="score-text">{score}</p>}
          {resultsText && (
            <p className="results-text">
              {resultsText.replace(/\\n/g, '\n')}
            </p>
          )}
        </div>
      </div>

      <div className="outro-footer-container">
        <SeityButton
          onClick={handleContinue}
          label={continueButtonText || 'Continue'}
          type="next"
        />
      </div>
    </div>
  );
};

export default OutcomeAssessmentOutroPage;
