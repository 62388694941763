import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../app/rootReducer';
import { SurveyPracticeQuestion } from '../../../api/getSurveyDetail/types';

export const selectSurveyDetailState = (state: RootState) => state.surveyDetail;

export const selectAnsweredQuestionCount = createSelector(
  [selectSurveyDetailState],
  (surveyDetailState) => {
    const questions = surveyDetailState.surveyDetail?.surveyQuestions || [];

    const answeredCount = questions.filter((question: SurveyPracticeQuestion) => question.answerLevel > 0).length;
    const totalCount = questions.length;

    return { answeredCount, totalCount };
  }
);
