import React, {useState, useRef, useEffect} from 'react';
import './styles.scss';
import {useHistory} from 'react-router-dom';
import Thermometer from '../Thermometer/Thermometer';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight, faChevronLeft} from '@fortawesome/free-solid-svg-icons';

interface SubcategoryResultCardProps {
  svgXml?: string;
  subcategory: string;
  subcategoryId: number;
  dragonText: string;
  scoreHistories: ScoreHistory[];
  dragonImage?: string;
  dragonTitle?: string;
  dragonScore: number;
  historyTitle: string;
  historyText: string;
  retakeSurveyText: string;
}

interface ScoreHistory {
  score: number;
  scoreDate: string;
}

const SubcategoryResultCard: React.FC<SubcategoryResultCardProps> =
  ({
     svgXml,
     subcategory,
     subcategoryId,
     dragonText,
     scoreHistories,
     dragonImage,
     dragonTitle,
     dragonScore,
     historyTitle,
     historyText,
     retakeSurveyText
   }) => {
    const history = useHistory();
    const [isExpanded, setIsExpanded] = useState(false);
    const [dragonSvgContent, setDragonSvgContent] = useState<string | null>(null);
    const [imageError, setImageError] = useState(false);

    const scrollViewRef = useRef<HTMLDivElement>(null);

    const toggleExpand = () => {
      setIsExpanded(!isExpanded);
    };

    const GRAPHICS_URL = process.env.REACT_APP_GRAPHICS_URL || '';
    const dragonImageUrl = dragonImage
      ? `${GRAPHICS_URL}${dragonImage}`
      : null;

    useEffect(() => {
      let isMounted = true;
      const fetchSvg = async () => {
        try {
          if (dragonImageUrl) {
            const response = await fetch(dragonImageUrl);
            const svgText = await response.text();
            if (isMounted) {
              setDragonSvgContent(svgText);
            }
          }
        } catch (error) {
          console.error('Error fetching SVG:', error);
          setImageError(true);
        }
      };

      fetchSvg();

      return () => {
        isMounted = false;
      };
    }, [dragonImageUrl]);

    useEffect(() => {
      if (scrollViewRef.current) {
        scrollViewRef.current.scrollLeft =
          scrollViewRef.current.scrollWidth - scrollViewRef.current.clientWidth;
      }
    }, [scoreHistories, isExpanded]);

    const handleRetakeSurvey = () => {
      history.push(`/myWholeHealth/PracticeAssessment/${subcategoryId}`);
    };

    return (
      <div className="cardContainer">
        <button
          className="touchableCard"
          onClick={toggleExpand}
          aria-expanded={isExpanded}
          aria-label={`View results for ${subcategory}`}
        >
          <div
            className={`subcategoryCard ${isExpanded ? 'expanded' : ''}`}
          >
            {svgXml ? (
              <div
                className="subcategoryImage"
                dangerouslySetInnerHTML={{__html: svgXml}}
              />
            ) : (
              <div className="svgPlaceholder">
                <p className="svgPlaceholderText">SVG</p>
              </div>
            )}

            <div className="subcategoryTextContainer">
              <p className="subcategoryText">{subcategory}</p>
            </div>

            {dragonSvgContent && !imageError ? (
              <div
                className="dragonImage"
                dangerouslySetInnerHTML={{__html: dragonSvgContent}}
                aria-label={`Dragon image for ${subcategory}`}
              />
            ) : null}

            <FontAwesomeIcon
              icon={faChevronRight}
              className={`expandIcon ${isExpanded ? 'rotated' : ''}`}
            />
          </div>
        </button>

        {isExpanded && (
          <div className="expandedContent">
            <p className="dragonScoreText">{dragonScore}</p>

            {dragonTitle && <p className="dragonTitleText">{dragonTitle}</p>}

            <p className="dragonText">{dragonText}</p>

            <button className="retakeSurveyButton" onClick={handleRetakeSurvey}>{retakeSurveyText}</button>

            <p className="historyTitle">{historyTitle}</p>

            {scoreHistories.length > 0 && (
              <>
                {scoreHistories.length <= 3 ? (
                  <div className="historyContentContainer">
                    <div className="scoreHistoriesContainer">
                      {[...scoreHistories]
                        .sort((a, b) => new Date(a.scoreDate).getTime() - new Date(b.scoreDate).getTime())
                        .map((history, index) => (
                          <div key={index} className="scoreHistoryItem">
                            <Thermometer score={history.score}/>
                            <p className="scoreHistoryDate">{history.scoreDate}</p>
                          </div>
                        ))}
                    </div>
                    <p className="historyText">{historyText}</p>
                  </div>
                ) : (
                  <div className="scrollContainer">
                    <button className="chevronLeft" onClick={() => scrollViewRef.current?.scrollBy({
                      left: -200,
                      behavior: 'smooth'
                    })}>
                      <FontAwesomeIcon icon={faChevronLeft} size="2x"/>
                    </button>
                    <div className="scoreHistoriesScroll" ref={scrollViewRef}>
                      <div className="scoreHistoriesContainer">
                        {[...scoreHistories]
                          .sort((a, b) => new Date(a.scoreDate).getTime() - new Date(b.scoreDate).getTime())
                          .map((history, index) => (
                            <div key={index} className="scoreHistoryItem">
                              <Thermometer score={history.score}/>
                              <p className="scoreHistoryDate">{history.scoreDate}</p>
                            </div>
                          ))}
                      </div>
                    </div>
                    <button className="chevronRight" onClick={() => scrollViewRef.current?.scrollBy({
                      left: 200,
                      behavior: 'smooth'
                    })}>
                      <FontAwesomeIcon icon={faChevronRight} size="2x"/>
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    );
  };

export default SubcategoryResultCard;
