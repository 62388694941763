import axios from 'axios';
import { ServiceResponsePracticeSelectionUpdate, PracticeSelectionUpdateRequest } from './types';
import { BASE_URL } from "../apiConstants";

export async function practiceSelectionUpdate(
  token: string,
  practiceSelectionID: number,
  answerLevel: number
): Promise<ServiceResponsePracticeSelectionUpdate> {
  const url = `${BASE_URL}/WholeHealth/PracticeSelectionUpdate`;

  const requestBody: PracticeSelectionUpdateRequest = {
    PracticeSelectionID: practiceSelectionID,
    AnswerLevel: answerLevel,
  };

  try {
    const response = await axios.post<ServiceResponsePracticeSelectionUpdate>(
      url,
      requestBody,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (err: any) {
    if (err.response?.data?.userMessage) {
      err.message = err.response.data.userMessage;
    }
    throw err;
  }
}
