import { FunctionComponent, useEffect, useState } from "react";
import "./styles.scss";
import SeityAvatar from "../../../_core/components/SeityAvatar";
import SeityCVImage from "../../../_core/components/SeityCVImage";
import { CoreValue } from "../../../api/coreValues/types";

type ContactListItemProps = {
  coreValues: CoreValue[];
  firstName: string;
  lastName: string;
  profilePicture: string | null;
  searchValue: string;
};

export const ContactListItem: FunctionComponent<ContactListItemProps> = ({
  coreValues,
  firstName,
  lastName,
  profilePicture,
  searchValue
}) => {
  const [isFirstNameHighlighted, setIsFirstNameHighlighted] = useState(false);
  const [isLastNameHighlighted, setIsLastNameHighlighted] = useState(false);

  const fourCVstring = () => {
    return coreValues.map((cv, index) => cv.coreValue + (index < 3 ? " | " : ""));
  };

  const user = {
    firstName,
    lastName,
    profilePicture
  };

  useEffect(() => {
    if (searchValue) {
      const searchValueLower = searchValue.toLowerCase();
      if (firstName?.toLowerCase().startsWith(searchValueLower)) {
        setIsFirstNameHighlighted(true);
      }
      if (lastName?.toLowerCase().startsWith(searchValueLower)) {
        setIsLastNameHighlighted(true);
      }
    } else {
      setIsLastNameHighlighted(true);
    }
  }, [searchValue]);

  return (
    <div className="contact-list-item-container">
      <SeityAvatar size={64} content={user} />
      <div className="user-details">
        <p className="user-name">
          <span className={isFirstNameHighlighted ? "highlighted" : ""}>{firstName}</span>{" "}
          <span className={isLastNameHighlighted ? "highlighted" : ""}>{lastName}</span>
        </p>
        <div className="user-cv-images">
          {coreValues.map((cv) => (
            <SeityCVImage cvId={cv.coreValueID} size={16} />
          ))}
        </div>
        <p className="user-cv-names">{fourCVstring()}</p>
      </div>
    </div>
  );
};
