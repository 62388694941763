import React, { useEffect, useState } from 'react';
import { LMDragonInfo } from '../../../../api/getWhatAreDragons/types';
import { GRAPHICS_URL } from '../../../../api/apiConstants';
import './styles.scss';

interface DragonItemProps {
  dragon: LMDragonInfo;
}

const DragonItem: React.FC<DragonItemProps> = ({ dragon }) => {
  const [useFallbackDragonImage, setUseFallbackDragonImage] = useState(false);
  const [fallbackDragonImage, setFallbackDragonImage] = useState<string>('');

  useEffect(() => {
    // Determine the fallback image based on the dragon's imageFileName
    if (dragon.imageFileName) {
      const lowerCaseName = dragon.imageFileName.toLowerCase();
      if (lowerCaseName.includes('tame')) {
        setFallbackDragonImage(require('../../assets/tameDragon.png'));
      } else if (lowerCaseName.includes('awake')) {
        setFallbackDragonImage(require('../../assets/awakeDragon.png'));
      } else if (lowerCaseName.includes('raging')) {
        setFallbackDragonImage(require('../../assets/ragingDragon.png'));
      } else {
        // If none of the keywords match, we can default to a generic fallback
        setFallbackDragonImage(require('../../assets/lockIcon.png'));
      }
    } else {
      // If no imageFileName, fallback to generic image
      setFallbackDragonImage(require('../../assets/lockIcon.png'));
    }
  }, [dragon.imageFileName]);

  const dragonImageSource = useFallbackDragonImage
    ? fallbackDragonImage
    : GRAPHICS_URL + dragon.imageFileName;

  return (
    <div className="dragon-item-container">
      <img
        className="dragon-item-image"
        src={dragonImageSource}
        alt={dragon.dragonHeader}
        onError={() => setUseFallbackDragonImage(true)}
      />
      <div className="dragon-item-text-container">
        <h3 className="dragon-item-header">{dragon.dragonHeader}</h3>
        <p className="dragon-item-text">{dragon.dragonText}</p>
      </div>
    </div>
  );
};

export default DragonItem;
