import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/rootReducer';
import AccordionList from '../AccordionList/AccordionList';
import SubcategoryResultCard from '../SubcategoryResultCard/SubcategoryResultCard';
// import OutcomeResultCard from '../OutcomeResultCard/OutcomeResultCard'; // Skipping for now
import './styles.scss';
import OutcomeResultCard from "../OutcomeResultCard/OutcomeResultCard";

interface MyResultsTabProps {
  scrollViewRef: React.RefObject<HTMLDivElement>;
}

interface AccordionDataItem {
  id: string;
  title: string;
  subText?: string | null;
  content: React.ReactNode;
}

const MyResultsTab: React.FC<MyResultsTabProps> = ({ scrollViewRef }) => {
  const myWholeHealth = useSelector((state: RootState) => state.wholeHealth.myWholeHealth);
  const error = useSelector((state: RootState) => state.wholeHealth.error);
  const subcategorySvgData = useSelector((state: RootState) => state.wholeHealth.subcategorySvgData);

  const data: AccordionDataItem[] = [];

  if (myWholeHealth) {
    if (myWholeHealth.practicesEnabled) {
      const categoryData: AccordionDataItem[] = myWholeHealth.myWholeHealthOutcomeCategories
        .filter(category => category.resultsAvailable > 0)
        .map(category => {
          const subcategories = myWholeHealth.myWholeHealthOutcomeSubcategories
            .filter(sub =>
              sub.outcomeCategoryID === category.outcomeCategoryID &&
              sub.practiceScoreHistories.length > 0
            )
            .sort((a, b) => a.outcomeSubcategorySortOrder - b.outcomeSubcategorySortOrder);

          if (subcategories.length === 0) {
            return null;
          }

          return {
            id: category.outcomeCategoryID.toString(),
            title: category.category,
            subText: null,
            content: (
              <div className="subcategoriesContainer">
                {subcategories.map(subcategory => (
                  <SubcategoryResultCard
                    key={subcategory.outcomeSubcategoryID}
                    svgXml={subcategorySvgData[subcategory.outcomeSubcategoryID.toString()]}
                    subcategory={subcategory.subcategory}
                    subcategoryId={subcategory.outcomeSubcategoryID}
                    dragonText={subcategory.dragonText}
                    scoreHistories={subcategory.practiceScoreHistories}
                    dragonImage={subcategory.dragonImage}
                    dragonTitle={subcategory.dragonTitle}
                    dragonScore={subcategory.dragonScore}
                    historyTitle={myWholeHealth.historyTitle}
                    historyText={myWholeHealth.historyText}
                    retakeSurveyText={myWholeHealth.retakeSurvey}
                  />
                ))}
              </div>
            )
          };
        })
        .filter(Boolean) as AccordionDataItem[];

      data.push(...categoryData);
    }

    if (
      myWholeHealth.myWholeHealthLifeOutcome &&
      myWholeHealth.myWholeHealthLifeOutcome.outcomeScoreHistories.length > 0 &&
      myWholeHealth.outcomesEnabled
    ) {
      data.push({
        id: 'lifeOutcome',
        title: myWholeHealth.myWholeHealthLifeOutcome.categoryName,
        subText: null,
        content: (
          <div>
            <OutcomeResultCard
              lifeOutcome={myWholeHealth.myWholeHealthLifeOutcome}
              svgXml={subcategorySvgData.lifeOutcome}
              historyTitle={myWholeHealth.historyTitle}
              historyText={myWholeHealth.historyText}
              retakeSurveyText={myWholeHealth.retakeSurvey}
            />
          </div>
        )
      });
    }
  }

  if (data.length === 0) {
    return (
      <div className="noResultsContainer">
        <p className="noResultsText">
          {myWholeHealth?.noResultsText}
        </p>
      </div>
    );
  }

  return <AccordionList scrollViewRef={scrollViewRef} data={data} error={error} />;
};

export default MyResultsTab;
