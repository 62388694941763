import { GRAPHICS_URL } from "../../../api/apiConstants";

type SvgImage = {
  id: string;
  path: string;
};

export const preloadSvgImages = async (imagePaths: SvgImage[]): Promise<{ [key: string]: string }> => {
  try {
    const svgPromises = imagePaths.map(async ({ id, path }) => {
      const url = GRAPHICS_URL + path;
      const response = await fetch(url);
      const svgText = await response.text();
      return { [id]: svgText };
    });

    const svgDataArray = await Promise.all(svgPromises);
    const svgData = Object.assign({}, ...svgDataArray);

    return svgData;
  } catch (error) {
    console.error('Error preloading SVGs:', error);
    return {};
  }
};

export const preloadSingleSvg = async (path: string): Promise<string | null> => {
  try {
    const url = GRAPHICS_URL + path;
    const response = await fetch(url);
    const svgText = await response.text();
    return svgText;
  } catch (error) {
    console.error('Error preloading SVG:', error);
    return null;
  }
};
