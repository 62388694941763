import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MyWholeHealthResponse, ServiceResponseMyWholeHealth } from "../../../api/getMyWholeHealth/types";
import { AppThunk } from "../../../app/store";
import { setRedirectToLogin } from "../../auth/authSlice";
import { SeityAuthenticationError } from "../../../api/authTypes";
import { getMyWholeHealth } from "../../../api/getMyWholeHealth/getMyWholeHealthApi";
import {preloadSvgImages} from "../../../_core/utils/svgPreloadService/svgPreloadService";
import {getRefreshedToken} from "../../auth/UseSessionStorageToken";

export interface ScoreHistory {
  score: number;
  scoreDate: string;
}

export interface WholeHealthState {
  error: string | null;
  isLoading: boolean;
  myWholeHealth: MyWholeHealthResponse | null;
  subcategorySvgData: { [key: string]: string };
}

const initialState: WholeHealthState = {
  error: null,
  isLoading: false,
  myWholeHealth: null,
  subcategorySvgData: {},
};

const wholeHealthSlice = createSlice({
  name: 'whole_health',
  initialState,
  reducers: {
    fetchMyWholeHealthSuccess(state, action: PayloadAction<MyWholeHealthResponse>) {
      state.myWholeHealth = action.payload;
    },
    setApiError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    clearApiError(state) {
      state.error = null;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setSubcategorySvgData(state, action: PayloadAction<{ [key: string]: string }>) {
      state.subcategorySvgData = action.payload;
    },
    clearWholeHealthState(state) {
      state.error = initialState.error;
      state.isLoading = initialState.isLoading;
      state.myWholeHealth = initialState.myWholeHealth;
      state.subcategorySvgData = initialState.subcategorySvgData;
    },
  },
});

export const {
  fetchMyWholeHealthSuccess,
  setApiError,
  clearApiError,
  setIsLoading,
  setSubcategorySvgData,
  clearWholeHealthState
} = wholeHealthSlice.actions;

export default wholeHealthSlice.reducer;

export const fetchMyWholeHealth = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    dispatch(clearApiError());

    const { token } = await getRefreshedToken();
    if (token === null) {
      dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
      throw new SeityAuthenticationError();
    }

    const response: ServiceResponseMyWholeHealth = await getMyWholeHealth(token);

    console.log("j", response);

    if (response.success) {
      dispatch(fetchMyWholeHealthSuccess(response.data));

      if (response.data.myWholeHealthOutcomeSubcategories.length > 0) {
        const subcategoryImages = response.data.myWholeHealthOutcomeSubcategories.map(subcategory => ({
          id: subcategory.outcomeSubcategoryID.toString(),
          path: subcategory.image,
        }));

        if (response.data.myWholeHealthLifeOutcome) {
          subcategoryImages.push({
            id: 'lifeOutcome',
            path: response.data.myWholeHealthLifeOutcome.categoryImage,
          });
        }

        const svgData = await preloadSvgImages(subcategoryImages);

        dispatch(setSubcategorySvgData(svgData));
      }
    } else {
      dispatch(setApiError(response.userMessage || 'Failed to fetch My Whole Health data'));
    }
  } catch (err: any) {
    dispatch(setApiError(err.toString()));
  } finally {
    dispatch(setIsLoading(false));
  }
};
