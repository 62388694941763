import { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { RootState } from "../../../app/rootReducer";

import { sendGetGoodLifeSeriesInfo, sendGetSeityAssets, sendGetTeachSeityAssets } from "./guideSlice";
import SeityLoader from "../../../_core/components/SeityLoader";
import { PostItem } from "./Slider/PostItem";
import { useReferenceGuideList } from "./data";
import { Slider } from "./Slider";
import SeityNetworkAlert from "../../../_core/components/SeityNetworkAlert";

import strings from "../../../_core/strings/strings";
import "./styles.scss";
import { TSAssetItem } from "./Slider/TSAssetItem";
import { PostItemProps } from "./Slider/PostItem/types";
import { SeityScienceAsset, TeachSeityAsset } from "../../../api/referenceGuide/referenceGuideTypes";
import { getUserAppSettingsRequest } from "../../../slices/userAppSettingsSlice";

export const SEITY_SCIENCE_ASSET_TYPE = 2;

export const Learn: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const seityReferenceGuideList = useReferenceGuideList();

  const { userAppSettings } = useSelector((state: RootState) => {
    return state.userAppSettings;
  });

  const { isLoading, goodLifeSeries, teachSeityAssets, seityScienceAssets, guideError } = useSelector((state: RootState) => {
    return state.guide;
  });

  useEffect(() => {
    dispatch(sendGetGoodLifeSeriesInfo());
    dispatch(sendGetTeachSeityAssets());
    dispatch(getUserAppSettingsRequest());
    dispatch(sendGetSeityAssets(SEITY_SCIENCE_ASSET_TYPE));
  }, [dispatch]);

  if (isLoading) return <SeityLoader />;
  if (guideError) return <SeityNetworkAlert pageTitle={strings.learnSeity} />;

  return (
    <div className="learn-wrapper seity-main-fullwidth">
      <h1>{strings.learnSeity}</h1>
      <div className="learn-wrapper-description mb-xxl">
        <img src={require("../../../assets/graphics/self-care.png").default} alt="self care" />
        <p>{strings.learnDescription}</p>
      </div>
      <Slider
        info={strings.seityReferenceGuideInfo}
        title={strings.seityReferenceGuide}
        items={seityReferenceGuideList}
        goToGallery={() => history.push(`/learn/guides`)}
        renderItem={(item, index) => {
          const post = item as PostItemProps;
          return (
            <PostItem
              key={index}
              title={post.title}
              imageUrl={post.imageUrl}
              previewImageName={post.previewImageName}
              onClick={() => history.push(`/learn/guide/${post.categoryID}`)}
            />
          );
        }}
      />
      {userAppSettings.teachSeityEnabled && (
        <>
          <div className="devider mb-lg" />
          {userAppSettings.isTeachSeityNew && <span className="mr-auto mb-sm">{strings.new}</span>}
          <Slider
            info={strings.teachSeityTooltip}
            title={strings.teachSeity}
            items={teachSeityAssets}
            goToGallery={() => {}}
            renderItem={(item, index) => (
              <TSAssetItem
                key={index}
                item={item as TeachSeityAsset}
                onClick={() => {
                  history.push({
                    pathname: `/learn/teach/${item.assetID}`,
                    state: { item: item }
                  });
                }}
              />
            )}
          />
        </>
      )}
      <div className="devider mb-xxl" />
      <Slider
        info={strings.theGoodLifeSeriesInfo}
        title={strings.theGoodLifeSeries}
        items={goodLifeSeries}
        goToGallery={() => history.push(`/learn/series`)}
        renderItem={(item, index) => {
          const post = item;
          return (
            <PostItem
              key={index}
              title={post.title}
              subtitle={`${strings.episode} ${index + 1}`}
              className="good-life-item"
              imageUrl={post.imageUrl}
              onClick={() => history.push(`/learn/post/${post.referenceGuideVideoID}`)}
            />
          );
        }}
      />
      {userAppSettings.seityScienceEnabled && (
        <>
          <div className="devider mb-lg" />
          {userAppSettings.isSeityScienceNew && <span className="mr-auto mb-sm">{strings.new}</span>}
          <Slider
            info={strings.seityScienceTooltip}
            title={strings.seityScience}
            items={seityScienceAssets}
            goToGallery={() => {
              history.push("learn/sciences");
            }}
            renderItem={(item, index) => (
              <TSAssetItem
                key={index}
                item={item as SeityScienceAsset}
                onClick={() => {
                  history.push({
                    pathname: `/learn/science/${item.assetID}`,
                    state: { item: item }
                  });
                }}
              />
            )}
          />
        </>
      )}
    </div>
  );
};
