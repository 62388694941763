/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { useState, useEffect, FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { RootState } from "../../../../app/rootReducer";
import { sendFourCoreValuesRequest, sendCoreValueSortOrderUpdateRequest } from "../../coreValuesSlice";
import CoreStrings from "../../../../_core/strings/strings";
import { CoreValuesDragValuesProps } from "./types";

import SeityPrimaryCancelButtons from "../../../../_core/components/SeityNavigationButtons";
import { ResultItem } from "./ResultItem";

import "./styles.scss";
import { CoreValue } from "../../../../api/coreValues/types";

export const CoreValuesDragValues: FunctionComponent<CoreValuesDragValuesProps> = (props) => {
  const sortedFourCoreValuesDroppableId = "sortedFourCoreValues";
  const fourCoreValuesDroppableId = "fourCoreValues";

  const [width, setWidth] = useState<number>(window.innerWidth);
  const [sortedCoreValues, setSortedCoreValues] = useState<CoreValue[]>();
  const [coreValues, setCoreValues] = useState<CoreValue[]>();
  const [allCoreValuesSorted, setAllCoreValuesSorted] = useState(false);

  const dispatch = useDispatch();

  const { fourCoreValues, error } = useSelector((state: RootState) => {
    return state.assessment.coreValues;
  });

  useEffect(() => {
    dispatch(sendFourCoreValuesRequest());
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    const areAllCoreValuesSorted =
      sortedCoreValues?.filter((c) => {
        return c.coreValueID !== 0;
      }).length === 4;
    setAllCoreValuesSorted(areAllCoreValuesSorted);
  }, [sortedCoreValues]);

  useEffect(() => {
    setSortedCoreValues([]);
    setCoreValues(fourCoreValues.slice());
  }, [fourCoreValues]);

  const updateSortedOrder = () => {
    if (
      sortedCoreValues![0].coreValue !== "" &&
      sortedCoreValues![1].coreValue !== "" &&
      sortedCoreValues![2].coreValue !== "" &&
      sortedCoreValues![3].coreValue !== ""
    ) {
      dispatch(
        sendCoreValueSortOrderUpdateRequest(sortedCoreValues!, props?.isRetake?.toString(), () => {
          props.onNextClicked(props.stepId);
        })
      );
    } else {
      console.log("All core values not sorted");
    }
  };

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  function handleOnDragEnd(result: any) {
    if (!result.destination) return;
    const { source, destination } = result;

    if (
      source.droppableId === fourCoreValuesDroppableId &&
      destination.droppableId !== fourCoreValuesDroppableId &&
      destination.index < 4
    ) {
      const copySortedCoreValues = sortedCoreValues;
      const copyCoreValues = coreValues;
      const [added] = copyCoreValues!.splice(source.index - 4, 1);

      if (copySortedCoreValues![destination.index]) {
        copySortedCoreValues?.splice(destination.index, 0, added);
      } else {
        copySortedCoreValues![destination.index] = added;
      }
      setSortedCoreValues(copySortedCoreValues);
      setCoreValues(copyCoreValues);
    }

    // re-order inside the filled array
    if (source.droppableId === fourCoreValuesDroppableId && destination.droppableId === fourCoreValuesDroppableId) {
      const copyCoreValues = coreValues;
      const [removed] = copyCoreValues!.splice(source.index - 4, 1);
      copyCoreValues!.splice(destination.index - 4, 0, removed);
      setCoreValues(copyCoreValues);
    }
    if (source.droppableId !== fourCoreValuesDroppableId && destination.droppableId === fourCoreValuesDroppableId) {
      const copySortedCoreValues = sortedCoreValues;
      const copyCoreValues = coreValues;
      const [removed] = copySortedCoreValues!.splice(source.index, 1);
      copyCoreValues!.splice(destination.index - 4, 0, removed);
      setCoreValues(copyCoreValues);
      setSortedCoreValues(copySortedCoreValues);
    }
    if (source.droppableId !== fourCoreValuesDroppableId && destination.droppableId !== fourCoreValuesDroppableId) {
      const copySortedCoreValues = sortedCoreValues;
      const [removed] = copySortedCoreValues!.splice(source.index, 1);
      copySortedCoreValues!.splice(destination.index, 0, removed);
      setSortedCoreValues(copySortedCoreValues);
    }

    const areAllCoreValuesSorted =
      sortedCoreValues?.filter((c) => {
        return c.coreValueID !== 0;
      }).length === 4;
    setAllCoreValuesSorted(areAllCoreValuesSorted);
  }

  if (fourCoreValues.length === 4) {
    return (
      <div className="dv-container">
        <div>
          <h1>{CoreStrings.rankCoreValues}</h1>
          <h4>{CoreStrings.rankCoreValuesSubtitle}</h4>
        </div>
        <div className="dv-wrapper">
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <div className="dv-wrapper-position">
              <div className={width <= 1152 ? "dv-wrapper-placeholders" : "dv-wrapper-placeholders-horizontal"}>
                <div className="dv-wrapper-placeholders-choice">
                  <h1>1</h1>
                </div>
                <div className="dv-wrapper-placeholders-choice">
                  <h1>2</h1>
                </div>
                <div className="dv-wrapper-placeholders-choice">
                  <h1>3</h1>
                </div>
                <div className="dv-wrapper-placeholders-choice">
                  <h1>4</h1>
                </div>
              </div>
              <div className="dv-wrapper-rank">
                <Droppable direction={width <= 1152 ? "vertical" : "horizontal"} droppableId={sortedFourCoreValuesDroppableId}>
                  {(provided) => {
                    return (
                      <div {...provided.droppableProps} ref={provided.innerRef} className="dv-wrapper-values-choice">
                        {sortedCoreValues?.map(({ coreValue = "", coreValueID = 0 }, index) => {
                          if (index < 4) {
                            return (
                              <Draggable isDragDisabled={coreValue === ""} key={index} draggableId={index.toString()} index={index}>
                                {(provided) => {
                                  return (
                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                      {ResultItem({
                                        cvId: coreValueID,
                                        text: coreValue,
                                        isSelected: true
                                      })}
                                    </div>
                                  );
                                }}
                              </Draggable>
                            );
                          }
                          return <div />;
                        })}
                      </div>
                    );
                  }}
                </Droppable>
              </div>
            </div>
            <div className="dv-wrapper-values">
              <Droppable direction={width <= 1152 ? "vertical" : "horizontal"} droppableId={fourCoreValuesDroppableId}>
                {(provided) => {
                  return (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className="dv-wrapper-values-choice dv-wrapper-values-choice-left"
                    >
                      {coreValues?.map(({ coreValue, coreValueID }, index) => {
                        if (index < 4) {
                          return (
                            <Draggable key={coreValueID} draggableId={coreValueID.toString()} index={index + 4}>
                              {/* eslint-disable-next-line @typescript-eslint/no-shadow */}
                              {(provided) => {
                                return (
                                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                    {ResultItem({
                                      cvId: coreValueID,
                                      text: coreValue,
                                      isSelected: false
                                    })}
                                  </div>
                                );
                              }}
                            </Draggable>
                          );
                        }
                        return <div />;
                      })}
                    </div>
                  );
                }}
              </Droppable>
            </div>
          </DragDropContext>
        </div>
        <SeityPrimaryCancelButtons
          primaryTitle={props.isCVOnly ? CoreStrings.finishButtonText : CoreStrings.continue}
          primaryAction={() => {
            return updateSortedOrder();
          }}
          primaryClassName="dv-wrapper-primary-button"
          cancelTitle={CoreStrings.back}
          cancelButtonType="black"
          cancelAction={() => {
            return props.onBackClicked(props.stepId);
          }}
          cancelClassName="dv-wrapper-cancel-button"
          primaryDisabled={!allCoreValuesSorted}
          errorMessage={error}
          showCancel={props.stepId !== 1}
        />
      </div>
    );
  }
  return (
    <div>
      {console.log("fourcore values", fourCoreValues)}
      error getting 4 Core Values
    </div>
  );
};
