import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CoreValue } from "../../api/coreValues/types"
import { FourCoreValuesSortOrderUpdate } from "../../api/coreValues/updateFourCoreValuesSortOrder/types";
import { getFourCoreValues } from "../../api/coreValues/getFourCoreValues/getFourCoreValuesApi";
import { fourCoreValuesSortOrderUpdateRequest } from "../../api/coreValues/updateFourCoreValuesSortOrder/updateFourCoreValuesSortOrderApi";
import { getSortedFourCoreValues } from "../../api/coreValues/getSortedFourCoreValues/getSortedFourCoreValuesApi";
import { AppThunk } from "../../app/store";
import { SeityAuthenticationError } from "../../api/authTypes";
import { getRefreshedToken } from "../auth/UseSessionStorageToken";
import { downloadCoreValuesBackground, downloadCoreValuesPDF, downloadCoreValueBackgroundInstructions } from "../../api/account/seityHealthAPI-account";
import { base64ToArrayBuffer, savePDFFile, savePNGFile } from "../../_core/utils/fileUtils/byteArrayProcessing";

export interface CoreValueState {
  readonly error: string | null;
  readonly isLoading: boolean;
  readonly fourCoreValues: CoreValue[];
  readonly sortedFourCoreValues: CoreValue[];
  readonly selected: number;
}

const setInitialState = {
  error: null,
  isLoading: false,
  selected: 0,
  fourCoreValues: [],
  sortedFourCoreValues: []
} as CoreValueState;

const coreValueSlice = createSlice({
  name: "corevalues-coreValues",
  initialState: setInitialState,
  reducers: {
    setFourCoreValues(state, action: PayloadAction<CoreValue[]>) {
      state.isLoading = false;
      state.fourCoreValues = action.payload;
    },
    setSortedFourCoreValues(state, action: PayloadAction<CoreValue[]>) {
      state.isLoading = false;
      state.sortedFourCoreValues = action.payload;
    },
    setCoreValueError(state, action) {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
    },
    clearApiError(state, action) {
      state.error = null;
    },
    setIsLoading(state, action) {
      state.isLoading = true;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    resetCoreValueSlice: () => setInitialState
  }
});

export const {
  setCoreValueError,
  clearApiError,
  setIsLoading,
  setFourCoreValues,
  setSortedFourCoreValues,
  setSelected,
  resetCoreValueSlice
} = coreValueSlice.actions;
export default coreValueSlice.reducer;

export const getFourCoreValuesRequest = (): AppThunk => async (dispatch) => {
  try {
    dispatch(clearApiError({}));
    const { token } = await getRefreshedToken();
    if (token === null) {
      throw new SeityAuthenticationError();
    }
    const fourCoreValuesResponse = await getFourCoreValues(token);
    if (fourCoreValuesResponse.success) {
      const data: any = fourCoreValuesResponse.data;
      const { lmAccountCoreValues } = data;
      dispatch(setFourCoreValues(lmAccountCoreValues));
    }
  } catch (err: any) {
    console.error(err);
    dispatch(setCoreValueError({ error: err.toString() }));
  }
};

export const getSortedFourCoreValuesRequest = (): AppThunk => async (dispatch) => {
  try {
    dispatch(clearApiError({}));
    const { token } = await getRefreshedToken();
    if (token === null) {
      throw new SeityAuthenticationError();
    }
    const fourCoreValuesResponse = await getSortedFourCoreValues(token);
    if (fourCoreValuesResponse.success) {
      const data: any = fourCoreValuesResponse.data;
      const { lmAccountCoreValues } = data;
      dispatch(setSortedFourCoreValues(lmAccountCoreValues));
    }
  } catch (err: any) {
    console.error(err);
    dispatch(setCoreValueError({ error: err.toString() }));
  }
};

export const sendCoreValueSortOrderUpdateRequest =
  (sortedFourCoreValues: CoreValue[], questionKey: string, callback?: (success: boolean) => void): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(clearApiError({}));

      const { token } = await getRefreshedToken();
      if (token === null) {
        throw new SeityAuthenticationError();
      }

      const fourCoreValuesSortOrderUpdate = new FourCoreValuesSortOrderUpdate(
        sortedFourCoreValues[0].coreValueID,
        sortedFourCoreValues[1].coreValueID,
        sortedFourCoreValues[2].coreValueID,
        sortedFourCoreValues[3].coreValueID,
        questionKey
      );

      const coreValueSortOrderUpdateResponse = await fourCoreValuesSortOrderUpdateRequest(token, fourCoreValuesSortOrderUpdate);
      if (callback) {
        callback(coreValueSortOrderUpdateResponse.success);
      }
    } catch (err: any) {
      console.error(err);
      dispatch(setCoreValueError({ error: err.toString() }));
    }
  };

export const sendDownloadCVBackgroundRequest = (): AppThunk => async (dispatch) => {
  try {
    dispatch(clearApiError({}));

    const { token } = await getRefreshedToken();
    if (token === null) {
      throw new SeityAuthenticationError();
    }

    const reportDownloadResponse = await downloadCoreValuesBackground(token);

    if (reportDownloadResponse.success) {
      const data = reportDownloadResponse.data?.contents;
      var fileArrayBuffer = base64ToArrayBuffer(data);

      savePNGFile(reportDownloadResponse.data?.fileName ?? 'Virtualbackground.png', fileArrayBuffer);
    }
  } catch (err: any) {
    console.error(err);
    dispatch(setCoreValueError({ error: err.toString() }));
  }
};

export const sendFetchCVBackgroundImageRequest = (): AppThunk => async (dispatch) => {
  try {
    dispatch(clearApiError({}));

    const { token } = await getRefreshedToken();
    if (token === null) {
      throw new SeityAuthenticationError();
    }

    const reportDownloadResponse = await downloadCoreValuesBackground(token);

    if (reportDownloadResponse.success) {
      const data = reportDownloadResponse.data?.contents;
      const fileArrayBuffer = base64ToArrayBuffer(data);

      const blob = new Blob([new Uint8Array(fileArrayBuffer)], { type: "image/png" });
      const blobUrl = window.URL.createObjectURL(blob);
      return Promise.resolve(blobUrl);
    } else {
      throw new Error("Failed to download background image.");
    }
  } catch (err: any) {
    dispatch(setCoreValueError({ error: err.toString() }));
    return Promise.reject(err);
  }
};

export const sendDownloadPDFInstructionsRequest = (): AppThunk => async(dispatch) => {
  try {
    dispatch(clearApiError({}));

    const { token } = await getRefreshedToken();
    if (token === null) {
      throw new SeityAuthenticationError();
    }

    const coreValueDownloadPDFResponse = await downloadCoreValueBackgroundInstructions(token);

    if (coreValueDownloadPDFResponse.success) {
      const data = coreValueDownloadPDFResponse.data?.contents;

      var fileArrayBuffer = base64ToArrayBuffer(data);

      savePDFFile(coreValueDownloadPDFResponse.data?.fileName, fileArrayBuffer);
    }
  } catch (err: any) {
    console.error(err);
    dispatch(setCoreValueError({ error: err.toString() }));
  }
};

export const sendDownloadPDFRequest = (): AppThunk => async (dispatch) => {
  try {
    dispatch(clearApiError({}));

    const { token } = await getRefreshedToken();
    if (token === null) {
      throw new SeityAuthenticationError();
    }

    const coreValueDownloadPDFResponse = await downloadCoreValuesPDF(token);

    if (coreValueDownloadPDFResponse.success) {
      const data = coreValueDownloadPDFResponse.data?.contents;

      var fileArrayBuffer = base64ToArrayBuffer(data);

      savePDFFile(coreValueDownloadPDFResponse.data?.fileName, fileArrayBuffer);
    }
  } catch (err: any) {
    console.error(err);
    dispatch(setCoreValueError({ error: err.toString() }));
  }
};