import { BASE_URL } from "../../apiConstants";
import axios from "axios";
import { CoreValueCategorySelectionResponse } from "./types";

export async function getCoreValueCategorySelection(token: string, level: number, isRetake: boolean): Promise<CoreValueCategorySelectionResponse> {
  const url = BASE_URL + `/WholeHealth/CoreValueCategorySelection?Level=${level.toString()}&isRetake=${isRetake}`;
  try {
    const coreValueCateogryResponse = await axios.get<CoreValueCategorySelectionResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return coreValueCateogryResponse.data;
  } catch (err) {
    throw err;
  }
}