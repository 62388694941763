import { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import { RootState } from "../../app/rootReducer";
import { getPracticeScoresListRequest } from "../practices/practicesSlice";
import { getCoreValuesModeRequest, sendGetAccountInfoRequest } from "../account/accountSlice";
import { getCharacterizationRequest } from "../myStory/myStorySlice";

import { SeityContentWrap } from "../../_core/components/SeityContentWrap";
import { AssessmentOutcomes } from "../myStory/AssessmentOutcomes/AssessmentOutcomes";
import { TameYourDragons } from "../myStory/TameYourDragons/TameYourDragons";

import { switchLanguage } from "../../app/utils";

import "./styles.scss";
import strings from "../../_core/strings/strings";
import SeityLoader from "../../_core/components/SeityLoader";

/**
 * @deprecated
 * @constructor
 */
export const WholeHealth: FunctionComponent = () => {
  const [isApiLoading, setApiLoading] = useState(true);
  const dispatch = useDispatch();

  const {
    error: characterizationError,
    isLoading: isCharacterizationLoading,
    characterization
  } = useSelector((state: RootState) => {
    return state.characterization;
  });

  const { ragingDragons, awakeDragons, tameDragons } = useSelector((state: RootState) => {
    return state.practiceScores;
  });

  const { isCheckInRedirected, coreValuesMode } = useSelector((state: RootState) => {
    return state.account;
  });

  const { today, isLoading } = useSelector((state: RootState) => {
    return state.today;
  });

  useEffect(() => {
    setApiLoading(true);
    dispatch(getCharacterizationRequest());
    dispatch(sendGetAccountInfoRequest());
    dispatch(getPracticeScoresListRequest());
    dispatch(getCoreValuesModeRequest());
    setApiLoading(false);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (characterization.languageID) {
      switchLanguage(characterization.languageID);
    }
  }, [characterization.languageID]);

  if (isLoading || isApiLoading) {
    return <SeityLoader />;
  }

  if (!isCheckInRedirected && today.checkInDue) {
    return <Redirect to="/checkin/wellbeingCheckIn" />;
  }

  // only show loading for the first, i.e. informatin text is empty
  if (isCharacterizationLoading && characterization.information === "") {
    return (
      <SeityContentWrap
        isBackgroundLoading
        showBackgroundMask
        onDismiss={() => {
          console.log("onDismiss");
        }}
      />
    );
  }

  if (!characterizationError) {
    return (
      <div className="whole-health-content">
        <p className="heading">{strings.wholeHealth}</p>
        <div className="whole-health-content-wrapper">
          <AssessmentOutcomes characterization={characterization}  />
          <TameYourDragons
            characterization={characterization}
            ragingDragons={ragingDragons}
            awakeDragons={awakeDragons}
            tameDragons={tameDragons}
          />
        </div>
      </div>
    );
  }
  return <></>;
};
