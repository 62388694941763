import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../app/store';
import { setRedirectToLogin } from '../features/auth/authSlice';
import { SeityAuthenticationError } from '../api/authTypes';
import { getWellbeingDetail01 } from '../api/getWellbeingDetail01/getWellbeingDetail01Api';
import { WellbeingDetail01Response, ServiceResponseWellbeingDetail } from '../api/getWellbeingDetail01/types';
import {getRefreshedToken} from "../features/auth/UseSessionStorageToken";

export interface WellbeingDetailState {
  error: string | null;
  isLoading: boolean;
  wellbeingDetail: WellbeingDetail01Response | null;
}

const initialState: WellbeingDetailState = {
  error: null,
  isLoading: false,
  wellbeingDetail: null,
};

const wellbeingDetailSlice = createSlice({
  name: 'WELLBEING_DETAIL',
  initialState,
  reducers: {
    fetchWellbeingDetailSuccess(state, action: PayloadAction<WellbeingDetail01Response>) {
      state.wellbeingDetail = action.payload;
      state.isLoading = false;
    },
    setApiError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    clearApiError(state) {
      state.error = null;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
  },
});

export const {
  fetchWellbeingDetailSuccess,
  setApiError,
  clearApiError,
  setIsLoading,
} = wellbeingDetailSlice.actions;

export default wellbeingDetailSlice.reducer;

export const fetchWellbeingDetail = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    dispatch(clearApiError());

    const { token } = await getRefreshedToken();
    if (token === null) {
      dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
      throw new SeityAuthenticationError();
    }

    const response: ServiceResponseWellbeingDetail = await getWellbeingDetail01(token);

    if (response.success) {
      dispatch(fetchWellbeingDetailSuccess(response.data));
    } else {
      dispatch(setApiError(response.userMessage || 'Failed to fetch wellbeing detail'));
    }
  } catch (err: any) {
    dispatch(setApiError(err.toString()));
  } finally {
    dispatch(setIsLoading(false));
  }
};
