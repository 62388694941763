import { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { GoodLifeEpisode } from "../../../../../api/referenceGuide/referenceGuideTypes";
import { RootState } from "../../../../../app/rootReducer";
import SeityLoader from "../../../../../_core/components/SeityLoader";
import { SeityPost } from "../../../../../_core/components/SeityPost";

type paramTypes = {
  cmsID: string;
};

export const LearnPost: FunctionComponent = () => {
  const [episode, setEpisode] = useState<GoodLifeEpisode>();

  const { isLoading, goodLifeSeries } = useSelector((state: RootState) => state.guide);
  const { cmsID } = useParams<paramTypes>();

  useEffect(() => {
    setEpisode(goodLifeSeries.find((e) => e.referenceGuideVideoID === parseInt(cmsID)));
  }, [cmsID, goodLifeSeries]);

  if (isLoading || !episode) return <SeityLoader />;

  return <SeityPost post={episode} />;
};
