import React from 'react';
import './styles.scss';
import SeityButton from "../../../../_core/components/SeityButton";

interface PracticeAssessmentNavigationProps {
  continueButtonText?: string;
  primaryAction: () => void;
  cancelAction: () => void;
  backButtonText?: string;
  errorMessage?: string | null;
  isPrimaryDisabled?: boolean;
}

const PracticeAssessmentNavigation: React.FC<PracticeAssessmentNavigationProps> =
  ({
     continueButtonText = 'Next',
     primaryAction,
     cancelAction,
     backButtonText = 'Back',
     errorMessage,
     isPrimaryDisabled = false,
   }) => {
    return (
      <div className="practice-assessment-navigationContainer">
        {errorMessage && <p className="errorText">{errorMessage}</p>}
        <SeityButton
          type="black"
          onClick={cancelAction}
          label={backButtonText}
        />
        <SeityButton
          type="next"
          onClick={primaryAction}
          label={continueButtonText}
          disabled={isPrimaryDisabled}
        />
      </div>
    );
  };

export default PracticeAssessmentNavigation;
