/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable react/button-has-type */
import { FunctionComponent, ReactElement, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import autosize from "autosize";

import { Journal, JournalEmotion } from "../../../api/individualJournal/journalTypes";
import { RootState } from "../../../app/rootReducer";
import SeityButton from "../../../_core/components/SeityButton";
import SeityLoader from "../../../_core/components/SeityLoader";
import { getBase64FromFile } from "../../../_core/utils/fileUtils/byteArrayProcessing";
import { sendIncentiveInfoRequest } from "../../wellbeingCheckIn/checkInSlice";
import CheckIn from "../../wellbeingCheckIn/CheckIn/CheckinMain";
import { getJournalEmotionsRequest, getWeatherRequest, postIndividualJournalRequest } from "../journalsSlice";
import EmotionButton from "../JournalEntry/EmotionButton";
import { makeEmotionsObj, makeJournalEntryObj } from "../JournalEntry/helpers";

import { AttachImageType } from "../JournalEntry/types";

import SeityIdkModal from "../../../_core/components/SeityIdkModal";

import "./styles.scss";
import LimitTextArea from "../JournalEntry/LimitTextArea";
import SeityCVSelection, { CoreValueType } from "../../../_core/components/SeityCVSelection";
import Incentives from "../../wellbeingCheckIn/Incentives";
import SeityEmotionsPopup from "../../../_core/components/SeityEmotionsPopup";

import strings from "../../../_core/strings/strings";
import { toastSuccess } from "../../../app/utils";

const MAX_BODY_LENGTH = 8000;

export type JournalEntryModalProps = {
  type: "story" | "sync";
  title: string;
  objectiveID: number;
  journal?: Journal;
  onClose: (success: boolean) => void;
};

const JournalEntryModal: FunctionComponent<JournalEntryModalProps> = ({ type, title, objectiveID, journal, onClose }): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const titleInputRef = useRef<any>();

  const { isLoading, weatherData, emotionsData } = useSelector((state: RootState) => {
    return state.journals;
  });
  const { incentiveResponse, dueResponse } = useSelector((state: RootState) => {
    return state.checkIn;
  });
  const { sortedFourCoreValues } = useSelector((state: RootState) => {
    return state.fourCoreValues;
  });

  const [dateObj, setDateObj] = useState<{ date: string; time: string }>();
  const [isLiked, setLiked] = useState(false);
  const [showEmotionPopup, setShowEmotionPopup] = useState(false);
  const [showCheckIn, setShowCheckIn] = useState(false);
  const [showIdkModal, setShowIdkModal] = useState(false);
  const [showIncentive, setShowIncentive] = useState(false);

  const [attachImg, setAttachImg] = useState<AttachImageType | null>(null);
  const [coreVals, setCoreVals] = useState<Array<CoreValueType>>([]);
  const [content, setContent] = useState("");
  const [emotionData, setEmotionData] = useState<{
    emotions: Array<JournalEmotion>;
    added: Array<JournalEmotion>;
  }>({
    emotions: [],
    added: []
  });

  useEffect(() => {
    autosize(titleInputRef.current);

    const today = new Date();
    setDateObj({
      date: moment(today).format("MMMM DD, Y"),
      time: moment(today).format("h:mm A")
    });

    if (emotionsData.length === 0) {
      dispatch(getJournalEmotionsRequest());
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        dispatch(getWeatherRequest(position.coords.latitude, position.coords.longitude));
      },
      () => {}
    );
  }, []);

  useEffect(() => {
    if (sortedFourCoreValues.length === 4) {
      const vals = sortedFourCoreValues.map((c) => {
        return {
          id: c.coreValueID,
          name: c.coreValue,
          selected: false
        };
      });
      setCoreVals(vals);
    }

    if (!dueResponse?.due && !incentiveResponse) {
      dispatch(sendIncentiveInfoRequest());
    }
  }, [dueResponse, sortedFourCoreValues]);

  useEffect(() => {
    if (emotionsData.length > 0) {
      setEmotionData({ emotions: emotionsData, added: [] });
    }
  }, [emotionsData]);

  const handleSave = () => {
    const emotions = makeEmotionsObj(emotionData.emotions, emotionData.added);
    const param = makeJournalEntryObj(title, isLiked, content, coreVals, emotions, attachImg ? [attachImg] : [], weatherData);
    param.objectiveID = objectiveID;

    dispatch(
      postIndividualJournalRequest(param, () => {
        onClose(true);
      })
    );
  };

  const renderCoreVals = useMemo(() => {
    return (
      <SeityCVSelection
        coreVals={coreVals}
        isReadOnly={false}
        onChange={(vals) => {
          setCoreVals(vals);
        }}
      />
    );
  }, [coreVals]);

  return (
    <div className="journal-entry-modal flex-col w-100 mt-xxl">
      <div className="journal-entry-modal__individual flex-row flex-ai-c pl-xl pr-xl">
        <img
          src={require(`../../../assets/graphics/journals/${type === "story" ? "icon-group-gray" : "icon-sync-gray"}.png`).default}
          alt="Individual"
        />
        <span className="ml-sm">{type === "story" ? strings.story : strings.sync}</span>
        <button
          className="ml-auto"
          onClick={() => {
            onClose(false);
          }}
        >
          <img src={require("../../../assets/icons/close-black.png").default} alt="close button" width={25} />
        </button>
      </div>
      <div className="flex-row mtb-lg pl-xxl pr-xxl">
        <h3>{title}</h3>
      </div>
      <div className={`journal-entry-modal__toolbar flex-ai-c mb-lg pb-xl  pl-xl pr-xl`}>
        {dateObj && (
          <p className="mr-auto">
            {dateObj.date} <span>{dateObj.time}</span>
          </p>
        )}
        <input
          type="file"
          id="file"
          accept="image/png, image/gif, image/jpeg"
          onChange={(event) => {
            if (event.target.files && event.target.files[0]) {
              const img = event.target.files[0];
              getBase64FromFile(img)
                .then((result) => {
                  setAttachImg({
                    individualJournalContentID: 0, // Put 0 is considered as a new image in the backend
                    imageName: img.name,
                    base64String: result as string
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          }}
        />
        <label htmlFor="file" />
        <button
          className="journal-entry-modal__favBtn ml-md"
          onClick={() => {
            setLiked(!isLiked);
          }}
        >
          {/* eslint-disable-next-line max-len */}
          <img
            src={
              isLiked
                ? require("../../../assets/graphics/journals/favorite-sel.png").default
                : require("../../../assets/graphics/journals/favorite.png").default
            }
            alt="favourite button"
            width={56}
          />
        </button>
      </div>
      <div className="journal-entry-modal__center mb-lg flex-row flex-ai-c">
        {dueResponse?.due ? (
          <button
            className="journal-entry-modal__outcomesBtn flex-center plr-xl"
            onClick={() => {
              setShowCheckIn(true);
            }}
          >
            <img src={require("../../../assets/graphics/journals/outcomes.png").default} alt="Check in button" />
            <span className="ml-md">{strings.checkInNow}</span>
          </button>
        ) : (
          <span className="journal-entry-modal__outcomesBtn flex-center plr-xl">
            <img className="mr-md" src={require("../../../assets/graphics/journals/outcomes.png").default} alt="Check in button" />
            {incentiveResponse && `${incentiveResponse?.checkInScore}%`}
          </span>
        )}
        <div className="flex-center journal-entry-modal__feelBtn">
          <EmotionButton
            emotions={emotionData.added}
            visibleCount={2}
            handleButtonClick={() => {
              setShowEmotionPopup(!showEmotionPopup);
            }}
          />
        </div>
      </div>
      {attachImg && (
        <div className="flex-row pl-xl">
          <img className="journal-entry-modal__attach" src={`data:image/png;base64,${attachImg.base64String}`} alt="attach" />
          <button
            className="btn-delete h-100"
            onClick={() => {
              setAttachImg(null);
            }}
          >
            <img
              src={require("../../../assets/graphics/intentions/intentions-delete.png").default}
              alt="delete button"
              width={32}
              height={32}
              color="red"
            />
          </button>
        </div>
      )}
      <div className="body-text-wrap pl-xl pr-xl h-100">
        <LimitTextArea
          maxLength={MAX_BODY_LENGTH}
          disabled={false}
          className="body-text w-100 h-100"
          value={content}
          onChange={(val) => {
            setContent(val);
          }}
        />
        <SeityButton className="rounded-lg" label={strings.doneButtonTitle} onClick={handleSave} />
      </div>
      <div className="journal-entry-modal__footer flex-col-center">
        <p>{coreVals.length > 0 ? strings.cvSelectionModalTitle : strings.coreValueConnect}</p>
        {coreVals.length > 0 ? (
          renderCoreVals
        ) : (
          <img src={require("../../../assets/graphics/journals/no-coreval-img.png").default} alt="attach" />
        )}
        <button
          className="intention-ancho btn-idk"
          onClick={() => {
            setShowIdkModal(true);
          }}
        >
          {strings.idk}
        </button>
      </div>
      {showEmotionPopup && emotionData.emotions?.length > 0 && (
        <>
          <div
            className="emotionsPopup-wrap"
            onClick={() => {
              setShowEmotionPopup(false);
            }}
          />
          <SeityEmotionsPopup
            emotions={emotionData.emotions}
            fullScreen={true}
            handleEmotionSel={(idx, selected) => {
              const temp = [...emotionData.emotions];
              const sel = { ...temp[idx] };
              sel.selected = selected;
              temp[idx] = sel;
              const added = temp.filter((t) => {
                return t.selected === true;
              });
              setEmotionData({ emotions: temp, added });
            }}
          />
        </>
      )}
      {showCheckIn && (
        <div className="journal-checkin-container">
          <CheckIn
            onFallBack={() => {
              setShowCheckIn(false);
            }}
          />
        </div>
      )}
      {isLoading && <SeityLoader />}
      {showIdkModal && (
        <SeityIdkModal
          isOpen={showIdkModal}
          onClose={() => {
            setShowIdkModal(false);
          }}
        />
      )}
    </div>
  );
};

export default JournalEntryModal;
