import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CoreValuesMode,
  LanguageItem,
  SeityDashboardPayloadResponse,
  SeityDepartmentPayloadResponse,
  SeityJobPositionPayloadResponse
} from "../../api/account/types/accountTypes";
import { getLanguages } from "../../api/account/seityHealthAPI-account";
import {
  SeityAccountListsItemResponse,
  SeityAccountMaintenancePayloadRequest,
  SeityGetAccountInformationPayloadResponse
} from "../../api/account/types/maintenanceTypes";
import { SeityAccountMessageCenter } from "../../api/account/types/messageCenterListTypes";
import { setRedirectToLockedAccount, logOut } from "../auth/authSlice";
import strings from "../../_core/strings/strings";
import { login } from "../../api/seityHealthAPI-Auth";
import { LOCKED_ACCOUNT_ID } from "../../consts";
import apiClientWithAuth, { apiClient } from "../../api/apiClient";
import { toastError, toastErrorWithDetails, toastSuccess, toastSuccessWithDetails } from "../../app/utils";
import { CreateSupportTicketRequest } from "../../api/supportTicket/types/supportTicketTypes";
import { createSupportTicketRequest } from "../../api/supportTicket/seityHealthAPI-SupportTicket";
import { setSortedFourCoreValues } from "../coreValues/coreValuesSlice";

export interface AccountState {
  readonly isLoading: boolean;
  readonly accountError: string | null;
  readonly codeVerifyError: string | null;
  readonly dashboardResponse: SeityDashboardPayloadResponse | null;
  readonly departmentResponse: SeityDepartmentPayloadResponse | null;
  readonly jobPositionResponse: SeityJobPositionPayloadResponse | null;
  readonly isAccountUpdateSuccessful: boolean; // Used to indicate a non-registration account update
  readonly isPasswordChangeSuccessful: boolean;
  readonly isPasswordValidated: boolean;
  readonly accountLists: [SeityAccountListsItemResponse] | null;
  readonly accountInfo: SeityGetAccountInformationPayloadResponse;
  readonly isCheckInRedirected: boolean;
  readonly isCheckInDue: boolean;
  readonly messageCenterList: [SeityAccountMessageCenter];
  readonly languagesList: [LanguageItem] | null;
  readonly coreValuesMode: CoreValuesMode | null;
  readonly supportEmail: string | null;
}

const defaultAccountInformation = () => {
  return {
    dateOfBirth: "", // MM/dd/yyyy
    status: "",
    lastName: "",
    firstName: "",
    occupation: "",
    maritalStatusID: 0,
    accountStatusID: 0,
    accountStatus: "",
    statusID: 0,
    zipcode: "",
    maritalStatus: "",
    eMailAddress: "",
    genderID: 0,
    occupationID: 0,
    education: "",
    educationID: 0,
    departmentID: 0,
    jobPositionID: 0,
    gender: "",
    cellPhone: "",
    accountID: 0,
    username: "",
    phoneCountryCodeID: 0
  };
};

const setInitialState = {
  isLoading: false,
  accountError: null,
  codeVerifyError: null,
  dashboardResponse: null,
  departmentResponse: null,
  jobPositionResponse: null,
  isAccountUpdateSuccessful: false,
  isPasswordChangeSuccessful: false,
  isPasswordValidated: false,
  accountLists: null,
  accountInfo: defaultAccountInformation(),
  isCheckInRedirected: false,
  isCheckInDue: false,
  messageCenterList: [{ message: "", messageID: 0, messageKeyID: 0, messageTypeID: 5 }],
  languagesList: null,
  coreValuesMode: null,
  supportEmail: null
} as AccountState;

const accountSlice = createSlice({
  name: "account",
  initialState: setInitialState,
  reducers: {
    setDashboardResponse(state, action) {
      state.dashboardResponse = action.payload.dashboardResponse;
      state.isLoading = false;
      state.accountError = null;
    },
    setDepartmentListResponse(state, action) {
      state.departmentResponse = action.payload.departmentListResponse;
      state.isLoading = false;
      state.accountError = null;
    },
    setJobPositionListResponse(state, action) {
      state.jobPositionResponse = action.payload.jobPositionListResponse;
      state.isLoading = false;
      state.accountError = null;
    },
    // If a value is not passed, it's assumed
    // to be true
    setIsLoading(state, action) {
      if (action && action.payload && action.payload.isLoading !== undefined && action.payload.isLoading !== null) {
        state.isLoading = action.payload.isLoading;
      } else {
        state.isLoading = true;
      }
    },
    setAccountError(state, action) {
      state.accountError = action.payload.accountError;
      state.isLoading = false;
    },
    setCodeVerifyError(state, action) {
      state.codeVerifyError = action.payload.codeVerifyError;
      state.isLoading = false;
    },
    setLanguagesList(state, action) {
      state.languagesList = action.payload.languagesList;
    },
    setAccountLists(state, action) {
      state.accountLists = action.payload.accountLists;
    },
    setAccountInfo(state, action) {
      state.accountInfo = action.payload.accountInfo;
      state.isLoading = false;
    },
    setMessageCenterList(state, action) {
      state.messageCenterList = action.payload.messageCenterList;
    },
    setIsCheckInReirected(state, action: PayloadAction<boolean>) {
      state.isCheckInRedirected = action.payload;
    },
    setIsCheckInDue(state, action: PayloadAction<boolean>) {
      state.isCheckInDue = action.payload;
    },
    setAccountInfoValues(state, action) {
      let payload = action.payload as SeityGetAccountInformationPayloadResponse;
      if (payload.dateOfBirth !== undefined) {
        state.accountInfo.dateOfBirth = payload.dateOfBirth;
      }
      if (payload.status !== undefined) {
        state.accountInfo.status = payload.status;
      }
      if (payload.lastName !== undefined) {
        state.accountInfo.lastName = payload.lastName;
      }
      if (payload.firstName !== undefined) {
        state.accountInfo.firstName = payload.firstName;
      }
      if (payload.occupation !== undefined) {
        state.accountInfo.occupation = payload.occupation;
      }
      if (payload.maritalStatusID !== undefined) {
        state.accountInfo.maritalStatusID = payload.maritalStatusID;
      }
      if (payload.accountStatusID !== undefined) {
        state.accountInfo.accountStatusID = payload.accountStatusID;
      }
      if (payload.accountStatus !== undefined) {
        state.accountInfo.accountStatus = payload.accountStatus;
      }
      if (payload.statusID !== undefined) {
        state.accountInfo.statusID = payload.statusID;
      }
      if (payload.zipcode !== undefined) {
        state.accountInfo.zipcode = payload.zipcode;
      }
      if (payload.maritalStatus !== undefined) {
        state.accountInfo.maritalStatus = payload.maritalStatus;
      }
      if (payload.eMailAddress !== undefined) {
        state.accountInfo.eMailAddress = payload.eMailAddress;
      }
      if (payload.genderID !== undefined) {
        state.accountInfo.genderID = payload.genderID;
      }
      if (payload.occupationID !== undefined) {
        state.accountInfo.occupationID = payload.occupationID;
      }
      if (payload.education !== undefined) {
        state.accountInfo.education = payload.education;
      }
      if (payload.educationID !== undefined) {
        state.accountInfo.educationID = payload.educationID;
      }
      if (payload.gender !== undefined) {
        state.accountInfo.gender = payload.gender;
      }
      if (payload.cellPhone !== undefined) {
        state.accountInfo.cellPhone = payload.cellPhone;
      }
      if (payload.accountID !== undefined) {
        state.accountInfo.accountID = payload.accountID;
      }
      if (payload.username !== undefined) {
        state.accountInfo.username = payload.username;
      }
      if (payload.phoneCountryCodeID !== undefined) {
        state.accountInfo.phoneCountryCodeID = payload.phoneCountryCodeID;
      }
    },
    setAccountUpdateSuccessful(state, action) {
      state.isAccountUpdateSuccessful = action.payload.accountUpdateSuccessful;
    },
    setChangePasswordSuccessful(state, action) {
      state.isPasswordChangeSuccessful = action.payload.passwordChangeSuccessful;
    },
    setPasswordValidated(state, action) {
      state.isPasswordValidated = action.payload;
    },
    setCoreValuesMode(state, action) {
      state.coreValuesMode = action.payload.coreValuesMode;
    },
    setSupportEmail(state, action) {
      state.supportEmail = action.payload.supportEmail;
    },
    resetAccountSlice: () => setInitialState,
    clearAccountError(state) {
      state.accountError = null;
      state.codeVerifyError = null;
    }
  }
});

export const {
  setIsLoading,
  setAccountError,
  setCodeVerifyError,
  setDashboardResponse,
  setAccountUpdateSuccessful,
  setAccountLists,
  setAccountInfo,
  setChangePasswordSuccessful,
  setPasswordValidated,
  setAccountInfoValues,
  setMessageCenterList,
  resetAccountSlice,
  clearAccountError,
  setIsCheckInReirected,
  setIsCheckInDue,
  setLanguagesList,
  setDepartmentListResponse,
  setJobPositionListResponse,
  setCoreValuesMode,
  setSupportEmail
} = accountSlice.actions;
export default accountSlice.reducer;

export const sendDashboardRequest = () => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    console.log("Getting dashboard data...");
    const res = await apiClientWithAuth.get("/Account/Dashboard");
    if (res.data.success === false || res.data.message || !res.data.data) {
      dispatch(
        setAccountError({
          accountError: res.data.message ?? "There was an error getting the dashboard information."
        })
      );
      return;
    }
    console.log("Received dashboard data...");
    dispatch(setDashboardResponse({ dashboardResponse: res.data.data }));

    const msgRes = await apiClientWithAuth.get("/Account/MessageCenterList");
    if (
      msgRes.data.success === false ||
      msgRes.data.message ||
      !msgRes.data.data ||
      !msgRes.data.data.messageCenterList ||
      msgRes.data.data.messageCenterList.length < 1
    ) {
      dispatch(
        setAccountError({
          accountError: msgRes.data.message ?? "There was an error getting the messageCenterList information."
        })
      );
      return;
    }

    dispatch(
      setMessageCenterList({
        messageCenterList: msgRes.data.data.messageCenterList
      })
    );
  } catch (err) {
    console.error(err);
    handleError(err, dispatch);
  }
};

export const sendMessageCenterListRequest = () => async (dispatch: any) => {
  console.log("Retrieving message center data...");

  const res = await apiClientWithAuth.post("/Account/MessageCenterList");
  if (
    res.data.success === false ||
    res.data.message ||
    !res.data.data ||
    !res.data.data.messageCenterList ||
    res.data.data.messageCenterList.length < 1
  ) {
    dispatch(
      setAccountError({
        accountError: res.data.message ?? "There was an error getting the messageCenterList information."
      })
    );
    return;
  }

  const messageCenterList = res.data.data.messageCenterList;

  dispatch(setMessageCenterList({ messageCenterList: messageCenterList }));

  const checkInDue = messageCenterList?.length > 0 && messageCenterList.findIndex((m) => m.messageTypeID === 3) >= 0;

  dispatch(setIsCheckInDue(checkInDue));
};

export const sendDepartmentListRequest = () => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    const res = await apiClientWithAuth.get("/Account/DepartmentList");
    if (res.data.success === false || res.data.message || !res.data.data) {
      dispatch(
        setAccountError({
          accountError: res.data.message ?? "There was an error getting the dashboard information."
        })
      );
      return;
    }

    dispatch(
      setDepartmentListResponse({
        departmentListResponse: res.data.data
      })
    );
  } catch (err) {
    handleError(err, dispatch);
  }
};

export const sendJobPositionListRequest = () => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    const res = await apiClientWithAuth.get("/Account/JobPositionList");
    if (res.data.success === false || res.data.message || !res.data.data) {
      dispatch(
        setAccountError({
          accountError: res.data.message ?? "There was an error getting the dashboard information."
        })
      );
      return;
    }

    dispatch(
      setJobPositionListResponse({
        jobPositionListResponse: res.data.data
      })
    );
  } catch (err) {
    handleError(err, dispatch);
  }
};

export const sendGetAccountListsRequest =
  (accountStatus: boolean, status: boolean, education: boolean, occupation: boolean, gender: boolean, maritalStatus: boolean) =>
  async (dispatch: any) => {
    try {
      dispatch(setIsLoading({}));
      console.log("Getting account lists...");
      const res = await apiClientWithAuth.post("/Account/AccountLists", {
        accountStatus,
        status,
        education,
        occupation,
        gender,
        maritalStatus
      });
      dispatch(setIsLoading({ isLoading: false }));
      if (
        res.data.success === false ||
        res.data.message ||
        !res.data.data ||
        !res.data.data.accountLists ||
        res.data.data.accountLists.length < 1
      ) {
        dispatch(
          setAccountError({
            accountError: res.data.message || "There was an error getting the data for the screen."
          })
        );
        return;
      }
      console.log("Received account lists response.");
      dispatch(setAccountLists({ accountLists: res.data.data?.accountLists }));
    } catch (err) {
      handleError(err, dispatch);
    }
  };

export const sendGetAccountInfoRequest = () => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    console.log("Getting account information...");
    const res = await apiClientWithAuth.get("/Account/Information");
    if (res.data.success === false || res.data.message || !res.data.data) {
      dispatch(
        setAccountError({
          accountError: res.data.message || "There was an error getting the data for the screen."
        })
      );
      return;
    }
    console.log("Received account info.");
    dispatch(setAccountInfo({ accountInfo: res.data.data }));
  } catch (err) {
    handleError(err, dispatch);
  }
};

export const sendChangePasswordRequest = (oldPassword: string, newPassword: string) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    console.log("Changing password...");
    const res = await apiClientWithAuth.post("/Auth/Security", {
      oldPassword,
      newPassword
    });
    if (res.data.success === false || res.data.message) {
      dispatch(
        setAccountError({
          accountError: res.data.message || "There was an error getting the data for the screen."
        })
      );
      return;
    }
    console.log("Password Changed.");
    dispatch(setChangePasswordSuccessful({ passwordChangeSuccessful: true }));
    dispatch(setAccountError({ accountError: "" }));
  } catch (err) {
    dispatch(setChangePasswordSuccessful({ passwordChangeSuccessful: false }));
    handleError(err, dispatch);
  }
};

export const sendAccountMaintenanceUpdateRequest =
  (maintenanceData: SeityAccountMaintenancePayloadRequest, showToast?: boolean, callback?: () => void) => async (dispatch: any) => {
    try {
      dispatch(setIsLoading({}));
      dispatch(clearAccountError());
      console.log("Sending account update...");
      const res = await apiClientWithAuth.put("/Account/AccountMaintenance", {
        ...maintenanceData
      });
      if (res.data.success === false || res.data.message) {
        dispatch(
          setAccountError({
            accountError: res.data.message || "There was an error updating your account details."
          })
        );
        return;
      }
      console.log("Received full account update response.");
      if (showToast) {
        toastSuccess(strings.profileUpdated);
      }
      dispatch(setIsLoading({ isLoading: false }));
      dispatch(setAccountUpdateSuccessful({ accountUpdateSuccessful: true }));

      if (callback) {
        callback();
      }
    } catch (err) {
      console.error(err);
      handleError(err, dispatch);
    }
  };

export const sendLangUpdateRequest = (langID: number, showToast?: boolean, callback?: () => void) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    dispatch(clearAccountError());
    const res = await apiClientWithAuth.put("/Account/AccountMaintenance", {
      firstName: null,
      lastName: null,
      eMailAddress: null,
      accountStatusID: null,
      dateOfBirth: null,
      statusID: null,
      educationID: null,
      occupationID: null,
      genderID: null,
      maritalStatusID: null,
      zipcode: null,
      cellPhone: null,
      departmentID: null,
      jobPositionID: null,
      languageID: langID
    });
    if (res.data.success === false || res.data.message) {
      dispatch(
        setAccountError({
          accountError: res.data.message || "There was an error updating your account details."
        })
      );
      return;
    }
    if (showToast) {
      toastSuccess(strings.profileUpdated);
    }
    dispatch(setIsLoading({ isLoading: false }));
    dispatch(setAccountUpdateSuccessful({ accountUpdateSuccessful: true }));

    if (callback) {
      callback();
    }
  } catch (err) {
    console.error(err);
    handleError(err, dispatch);
  }
};

export const sendVerificationCodeRequest = () => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    const res = await apiClientWithAuth.post("/Account/VerificationCode");

    if (res.data.success === false && res.data.message) {
      dispatch(
        setCodeVerifyError({
          codeVerifyError: res.data.message
        })
      );
      return;
    }
    dispatch(setIsLoading({ isLoading: false }));
  } catch (err) {
    handleError(err, dispatch);
  }
};

export const sendValidateVerificationCodeRequest = (code: string, callback: (response: boolean) => void) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    const res = await apiClientWithAuth.post("/Account/ValidateVerificationCode", {
      code
    });
    if (res.data.success === false && res.data.message) {
      handleError(res.data.message, dispatch);
      return;
    }
    dispatch(setIsLoading({ isLoading: false }));
    callback(res.data.success);
  } catch (err: any) {
    if (err.response?.data?.endPointMessageID === LOCKED_ACCOUNT_ID) {
      dispatch(logOut());
      dispatch(setRedirectToLockedAccount({ shouldRedirectToLockedAccount: true }));
    }
    handleError(err, dispatch);
  }
};

export const sendSmsVerificationCodeRequest = (callback?: (response: boolean) => void) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    const res = await apiClientWithAuth.post("/Account/SMSVerificationCode");
    if (res.data.success === false && res.data.message) {
      dispatch(
        setCodeVerifyError({
          codeVerifyError: res.data.message === "" ? strings.apiToastError : res.data.message
        })
      );
      return;
    }
    if (callback) {
      callback(res.data.success);
    }
    dispatch(setIsLoading({ isLoading: false }));
  } catch (err) {
    handleError(err, dispatch);
  }
};

export const sendSmsValidateVerificationCodeRequest = (code: string, callback: () => void) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    const res = await apiClientWithAuth.post("/Account/ValidateSMSVerificationCode", {
      code
    });
    if (res.data.success === false && res.data.message) {
      handleError(res.data.message, dispatch);
      return;
    }
    dispatch(setIsLoading({ isLoading: false }));
    callback();
  } catch (err: any) {
    if (err.response?.data?.endPointMessageID === LOCKED_ACCOUNT_ID) {
      dispatch(logOut());
      dispatch(setRedirectToLockedAccount({ shouldRedirectToLockedAccount: true }));
    }
    handleError(err, dispatch);
  }
};

export const sendValidatePasswordRequest =
  (username: string, password: string, callback: (boolean: boolean) => void) => async (dispatch: any) => {
    try {
      dispatch(setIsLoading({}));
      dispatch(clearAccountError());

      const checkPasswordResponse = await login(username, password);

      if (checkPasswordResponse.success === false && checkPasswordResponse.message) {
        dispatch(
          setAccountError({
            accountError: checkPasswordResponse.message
          })
        );
        dispatch(setIsLoading({ isLoading: false }));
        return;
      }
      callback(checkPasswordResponse.success);
      dispatch(setPasswordValidated(true));
      dispatch(setIsLoading({ isLoading: false }));
    } catch (err) {
      toastError(strings.evPasswordIncorrect);
      handleError(strings.evPasswordIncorrect, dispatch);
    }
  };

export const sendChangeEmailRequest = (newEmail: string, callback: (bool: boolean) => void) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    dispatch(clearAccountError());
    const res = await apiClientWithAuth.post("/Account/EMailAddress", {
      eMailAddress: newEmail
    });

    if (res.data.success === false && res.data.message) {
      dispatch(
        setAccountError({
          accountError: res.data.message
        })
      );
      dispatch(setIsLoading({ isLoading: false }));
      return;
    }
    callback(res.data.success);
    dispatch(setAccountInfoValues({ eMailAddress: newEmail }));
    dispatch(setIsLoading({ isLoading: false }));
  } catch (err) {
    console.log(err);
    dispatch(
      setAccountError({
        accountError: strings.evFailedToChangeEmail
      })
    );
  }
};

export const getLanguagesRequest = () => async (dispatch: any) => {
  try {
    const response = await getLanguages();
    if (response.success) {
      dispatch(setLanguagesList({ languagesList: response.data }));
    }
  } catch (err) {
    handleError(err, dispatch);
  }
};

export const getCoreValuesModeRequest = () => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    const res = await apiClientWithAuth.get("/Account/CoreValuesMode");
    if (res.data.success === false || res.data.message || !res.data.data) {
      dispatch(
        setAccountError({
          accountError: res.data.message ?? "There was an error getting the dashboard information."
        })
      );
      return;
    }
    dispatch(setCoreValuesMode({ coreValuesMode: res.data.data }));
    dispatch(setSortedFourCoreValues(res.data.data.coreValues));

    dispatch(setIsLoading({ isLoading: false }));
  } catch (err) {
    handleError(err, dispatch);
  }
};

export const sendCreateSupportTicketRequest =
  (createSupportTicketRequestPayload: CreateSupportTicketRequest, onSuccess: () => void) => async (dispatch: any) => {
    try {
      dispatch(setIsLoading({}));

      const response = await createSupportTicketRequest(createSupportTicketRequestPayload);

      if (!response.success) {
        toastErrorWithDetails(strings.error, strings.supportTicketSubmissionError);
        return;
      }

      const messageWithDays = strings.supportTicketSubmissionSuccess.replace(
        "{{numberOfDays}}",
        `${response.data?.supportTicketResponseTimeDays || "2"}`
      );
      toastSuccessWithDetails(strings.requestReceived, messageWithDays);
      onSuccess();
    } catch (err) {
      toastErrorWithDetails(strings.error, strings.supportTicketSubmissionError);
    } finally {
      dispatch(setIsLoading({ isLoading: false }));
    }
  };

function handleError(err: any, dispatch: any) {
  const errString = err.toString();
  const editiedErrString = errString.replace("Error:", "");
  dispatch(setIsLoading({ isLoading: false }));
  dispatch(setAccountError({ accountError: editiedErrString }));
}
