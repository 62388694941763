import axios from 'axios';
import { ServiceResponseMyWholeHealth } from './types';
import { BASE_URL } from "../apiConstants";

export async function getMyWholeHealth(token: string): Promise<ServiceResponseMyWholeHealth> {
  const url = `${BASE_URL}/WholeHealth/MyWholeHealth`;
  try {
    const response = await axios.get<ServiceResponseMyWholeHealth>(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    if (err.response?.data?.userMessage) {
      err.message = err.response.data.userMessage;
    }
    throw err;
  }
}
