import axios from "axios";
import {
  OutcomeScoresResponse,
  OutcomeSelectionResponse,
  OutcomeSelectionUpdate,
  PracticeSelectionResponse,
  PracticeSelectionUpdate,
  PracticeScoresResponse
} from "./types/questionTypes";
import { SeityResponse } from "../baseTypes";
import { BASE_URL } from "../apiConstants";
import { CoreValuesIncentiveResponse } from "../coreValues/types";

export async function getPracticeSelection(
  token: string,
  outcomeCategoryID: number,
  sortOrder: number
): Promise<PracticeSelectionResponse> {
  const url = BASE_URL + "/Assessment/PracticeSelection?OutcomeCategoryID=" + outcomeCategoryID.toString() + "&SortOrder=" + sortOrder;
  try {
    const practiceSelectionResponse = await axios.get<PracticeSelectionResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return practiceSelectionResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function practiceSelectionUpdateRequest(
  token: string,
  practiceSelectionUpdate: PracticeSelectionUpdate
): Promise<SeityResponse> {
  const url = BASE_URL + "/Assessment/PracticeSelectionUpdate";
  try {
    const practiceSelectionUpdateResponse = await axios.post<SeityResponse>(url, practiceSelectionUpdate, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return practiceSelectionUpdateResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function getPracticeScores(token: string): Promise<PracticeScoresResponse> {
  const url = BASE_URL + "/Account/PracticeScores";
  try {
    const coreValueCateogryResponse = await axios.get<PracticeScoresResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return coreValueCateogryResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function getOutcomeSelection(token: string, outcomeCategoryID: number): Promise<OutcomeSelectionResponse> {
  const url = BASE_URL + "/Assessment/OutcomeSelection?OutcomeCategoryID=" + outcomeCategoryID.toString();
  try {
    const outcomeSelectionResponse = await axios.get<OutcomeSelectionResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return outcomeSelectionResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function getOutcomeScores(token: string): Promise<OutcomeScoresResponse> {
  const url = BASE_URL + "/Account/OutcomeScores";
  try {
    const outcomeSelectionResponse = await axios.get<OutcomeScoresResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return outcomeSelectionResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function outcomeSelectionUpdateRequest(token: string, outcomeSelectionUpdate: OutcomeSelectionUpdate): Promise<SeityResponse> {
  const url = BASE_URL + "/Assessment/OutcomeSelectionUpdate";
  try {
    const outcomeSelectionUpdateResponse = await axios.post<SeityResponse>(url, outcomeSelectionUpdate, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return outcomeSelectionUpdateResponse.data;
  } catch (err) {
    throw err;
  }
}

// add to use fetch because axios was always 401
export async function practiceMarkCompleteRequest(token: string): Promise<SeityResponse> {
  const url = BASE_URL + "/Assessment/PracticeMarkComplete";
  const requestOptions = {
    method: "POST",
    headers: { Authorization: "Bearer " + token }
  };
  return await fetch(url, requestOptions)
    .then((response) => {
      return response.json();
    })
    .catch((error) => console.warn(error));
}

export async function outcomeMarkCompleteRequest(token: string): Promise<SeityResponse> {
  const url = BASE_URL + "/Assessment/OutcomeMarkComplete";
  const requestOptions = {
    method: "POST",
    headers: { Authorization: "Bearer " + token }
  };
  return await fetch(url, requestOptions)
    .then((response) => {
      return response.json();
    })
    .catch((error) => console.warn(error));
}

export async function resetCoreValues(token: string): Promise<SeityResponse> {
  const url = BASE_URL + "/WholeHealth/CoreValuesStartOver";
  return (await axios.post(url, { questionKey: '' }, { headers: {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${token}`
  }})).data
}

export async function getCVIncentive(token): Promise<CoreValuesIncentiveResponse> {
  const url = BASE_URL + "/IncentiveSchedule/CoreValuesIncentive";
  try {
    const cvIncentiveResponse = await axios.get<CoreValuesIncentiveResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return cvIncentiveResponse.data;
  } catch (err) {
    throw err;
  }
}
