import React from 'react';
import Modal from 'react-modal';
import './styles.scss';
import SeityButton from '../../../../_core/components/SeityButton';

interface CoreValuesCompleteDetailsModalProps {
  visible: boolean;
  onClose: () => void;
  onNotNow: () => void;
  onConfirm: () => void;
  header1?: string | null;
  header2?: string | null;
  body1?: string | null;
  body2?: string | null;
  confirmButtonText?: string | null;
  declineButtonText?: string | null;
}

const CoreValuesCompleteDetailsModal: React.FC<CoreValuesCompleteDetailsModalProps> =
  ({
     visible,
     onClose,
     onNotNow,
     onConfirm,
     header1,
     header2,
     body1,
     body2,
     confirmButtonText,
     declineButtonText
   }) => {
    const defaultHeader1 = header1 || 'Congratulations.';
    const defaultHeader2 = header2 || 'You have discovered your Core Values!';
    const defaultBody1 = body1 || 'Would you like to dive deeper into your Whole Health? Take your Life Practice Assessments to reveal your inner dragons and boost your wellbeing.';
    const defaultBody2 = body2 || 'Want to start now?';
    const defaultConfirmText = confirmButtonText || 'Yes';
    const defaultDeclineText = declineButtonText || 'Not now';

    return (
      <Modal
        isOpen={visible}
        onRequestClose={onClose}
        className="core-values-complete-modal"
        overlayClassName="core-values-complete-modal-overlay"
      >
        <button className="closeButton" onClick={onClose}>×</button>

        <div className="core-values-complete-content">
        <h2 className="title">{defaultHeader1}</h2>
          <h2 className="title">{defaultHeader2}</h2>

          <div className="subTitleWrapper">
            <p className="subTitle">{defaultBody1}</p>
            <p className="subTitle">{defaultBody2}</p>
          </div>

          <div className="buttonWrapper">
            <SeityButton
              onClick={onConfirm}
              label={defaultConfirmText}
              className="buttonContainer"
            />
            <SeityButton
              onClick={onNotNow}
              label={defaultDeclineText}
              className="buttonContainer"
              type="outline"
            />
          </div>
        </div>
      </Modal>
    );
  };

export default CoreValuesCompleteDetailsModal;
