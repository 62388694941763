import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../app/rootReducer';
import { MyWholeHealthOutcomeSubcategory } from '../../../api/getMyWholeHealth/types';

export const selectWholeHealthState = (state: RootState) => state.wholeHealth;

export const selectSubcategoryById = (outcomeSubcategoryID: number) =>
  createSelector(
    [selectWholeHealthState],
    (wholeHealth) => {
      return wholeHealth.myWholeHealth?.myWholeHealthOutcomeSubcategories.find(
        (subcategory: MyWholeHealthOutcomeSubcategory) => subcategory.outcomeSubcategoryID === outcomeSubcategoryID
      ) || null;
    }
  );

export const selectAllCategories = createSelector(
  [selectWholeHealthState],
  (wholeHealth) => wholeHealth.myWholeHealth?.myWholeHealthOutcomeCategories || []
);
