import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ServiceResponseSurveyDetail, SurveyDetailResponse } from "../../../api/getSurveyDetail/types";
import { AppThunk } from "../../../app/store";
import { setRedirectToLogin } from "../../auth/authSlice";
import { SeityAuthenticationError } from "../../../api/authTypes";
import { getSurveyDetail } from "../../../api/getSurveyDetail/getSurveyDetailApi";
import { confirmCoreValues } from "../../../api/confirmCoreValues/confirmCoreValuesApi";
import { practiceSelectionUpdate } from "../../../api/practiceSelectionUpdate/practiceSelectionUpdateApi";
import { getRefreshedToken } from "../../auth/UseSessionStorageToken";

interface SurveyDetailState {
  error: string | null;
  isLoading: boolean;
  surveyDetail: SurveyDetailResponse | null;
}

const initialState: SurveyDetailState = {
  error: null,
  isLoading: false,
  surveyDetail: null,
};

const surveyDetailSlice = createSlice({
  name: 'survey_detail',
  initialState,
  reducers: {
    fetchSurveyDetailSuccess(state, action: PayloadAction<SurveyDetailResponse>) {
      state.surveyDetail = action.payload;
      state.isLoading = false;
    },
    setApiError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    clearApiError(state) {
      state.error = null;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    updateAnswerLevel(state, action: PayloadAction<{ selectionID: number; answerLevel: number }>) {
      const { selectionID, answerLevel } = action.payload;
      const question = state.surveyDetail?.surveyQuestions.find(
        (q) => q.selectionID === selectionID
      );
      if (question) {
        question.answerLevel = answerLevel;
      }
    },
    updateCoreValuesStatus(state) {
      if (state.surveyDetail) {
        state.surveyDetail.coreValuesStatusID = 1;
      }
    },
    clearSurveyDetailsState(state) {
      // Reset the state to initial values
      state.error = initialState.error;
      state.isLoading = initialState.isLoading;
      state.surveyDetail = initialState.surveyDetail;
    }
  },
});

export const {
  fetchSurveyDetailSuccess,
  setApiError,
  clearApiError,
  setIsLoading,
  updateAnswerLevel,
  updateCoreValuesStatus,
  clearSurveyDetailsState
} = surveyDetailSlice.actions;

export default surveyDetailSlice.reducer;

export const fetchSurveyDetail = (outcomeSubcategoryID: number): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    dispatch(clearApiError());

    const { token } = await getRefreshedToken();
    if (token === null) {
      dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
      throw new SeityAuthenticationError();
    }

    const response: ServiceResponseSurveyDetail = await getSurveyDetail(token, outcomeSubcategoryID);

    if (response.success) {
      dispatch(fetchSurveyDetailSuccess(response.data));
    } else {
      dispatch(setApiError(response.userMessage || 'Failed to fetch survey detail data'));
    }
  } catch (err: any) {
    dispatch(setApiError(err.toString()));
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const confirmCoreValue = (extendedDefer: boolean, callback?: () => void): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    dispatch(clearApiError());

    const { token } = await getRefreshedToken();
    if (token === null) {
      dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
      throw new SeityAuthenticationError();
    }

    const response = await confirmCoreValues(token, extendedDefer);

    if (response.success) {
      dispatch(updateCoreValuesStatus());
      if (callback) callback();
    } else {
      dispatch(setApiError(response.userMessage || 'Failed to confirm core value'));
    }
  } catch (err: any) {
    dispatch(setApiError(err.toString()));
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const saveAnswerLevel = (selectionID: number, answerLevel: number): AppThunk => async (dispatch) => {
  try {
    const { token } = await getRefreshedToken();
    if (token === null) {
      dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
      throw new SeityAuthenticationError();
    }

    const response = await practiceSelectionUpdate(token, selectionID, answerLevel);

    if (!response.success) {
      dispatch(updateAnswerLevel({ selectionID, answerLevel: 0 }));
      dispatch(setApiError(response.userMessage || 'Failed to save answer level'));
    }
  } catch (err: any) {
    dispatch(updateAnswerLevel({ selectionID, answerLevel: 0 }));
    dispatch(setApiError(err.toString()));
  }
};
