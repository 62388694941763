import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CoreValueRecord, ServiceResponseCoreValuesDetail } from "../api/getCoreValuesDetail01/types";
import { AppThunk } from "../app/store";
import { setRedirectToLogin } from "../features/auth/authSlice";
import { SeityAuthenticationError } from "../api/authTypes";
import { getCoreValuesDetail01 } from "../api/getCoreValuesDetail01/getCoreValuesDetail01Api";
import {getRefreshedToken} from "../features/auth/UseSessionStorageToken";

export interface CoreValuesDetailState {
  error: string | null;
  isLoading: boolean;
  coreValueRecords: CoreValueRecord[] | null;
  totalCoreValues: number;
}

const initialState: CoreValuesDetailState = {
  error: null,
  isLoading: false,
  coreValueRecords: null,
  totalCoreValues: 0,
};

const coreValuesDetailSlice = createSlice({
  name: 'CORE_VALUES_DETAIL',
  initialState,
  reducers: {
    fetchCoreValuesDetailSuccess(state, action: PayloadAction<CoreValueRecord[]>) {
      state.coreValueRecords = action.payload;
      state.totalCoreValues = action.payload.length;
      state.isLoading = false;
    },
    setApiError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    clearApiError(state) {
      state.error = null;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
  },
});

export const {
  fetchCoreValuesDetailSuccess,
  setApiError,
  clearApiError,
  setIsLoading,
} = coreValuesDetailSlice.actions;

export default coreValuesDetailSlice.reducer;

export const fetchCoreValuesDetail = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    dispatch(clearApiError());

    const { token } = await getRefreshedToken();
    if (token === null) {
      dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
      throw new SeityAuthenticationError();
    }

    const response: ServiceResponseCoreValuesDetail = await getCoreValuesDetail01(token);

    if (response.success) {
      dispatch(fetchCoreValuesDetailSuccess(response.data.coreValueRecords));
    } else {
      dispatch(setApiError(response.userMessage || 'Failed to fetch core values details'));
    }
  } catch (err: any) {
    dispatch(setApiError(err.toString()));
  } finally {
    dispatch(setIsLoading(false));
  }
};
