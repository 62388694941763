/* eslint-disable jsx-a11y/alt-text */
import React, {useState, useEffect, FunctionComponent, ReactElement} from "react";
import {MessageType} from "../../../api/account/types/messageCenterListTypes";
import {SeityTodoItem} from "./SeityTodoItem";
import closeButton from "../../../assets/icons/closeButton.svg";
import {SeityTodoProps} from "./types";
import "./styles.scss";

const resolveTodoItemConfig = {
  [MessageType.AssessmentFull]: {
    color: "#fff",
    background: "#D87373",
    img: require("../../../assets/icons/take-assesment.svg").default,
    navigationPath: () => "/assessment/intro"
  },
  [MessageType.DailyCheckIn]: {
    color: "#fff",
    background: "#507F81",
    img: require("../../../assets/icons/smile-face.svg").default,
    navigationPath: () => "/checkin/wellbeingCheckin"
  },
  [MessageType.Challenge]: {
    color: "#000",
    background: "#FCD98B",
    img: require("../../../assets/icons/challenge.svg").default,
    navigationPath: () => "/challenge/acceptance"
  },
  [MessageType.Survey]: {
    color: "#000",
    background: "#BBDADB",
    img: require("../../../assets/icons/survey.svg").default,
    navigationPath: (messageKeyID: number) => `/survey/${messageKeyID}`
  },
  [MessageType.AccountMaintenance]: {
    color: "#000",
    background: "#7CC6AC",
    img: require("../../../assets/icons/intentions.svg").default,
    navigationPath: () => "/profile"
  },
  [MessageType.DailyIntention]: {
    color: "#000",
    background: "#7CC6AC",
    img: require("../../../assets/icons/intentions.svg").default,
    navigationPath: () => "/intentions"
  },
  [MessageType.SelectCoreValues]: {
    color: "#fff",
    background: "#D87373",
    img: require("../../../assets/icons/take-assesment.svg").default,
    navigationPath: () => "/assessment/intro"
  },
  [MessageType.CoreValueAssessment]: {
    color: "#fff",
    background: "#D87373",
    img: require("../../../assets/icons/corevaluemessagecenter01.png").default,
    navigationPath: () => "/assessment/corevalues"
  },
  [MessageType.WholeHealthAssessment]: {
    color: "#fff",
    background: "#D87373",
    img: require("../../../assets/icons/wholehealthmessagecenter01.png").default,
    navigationPath: () => "/mywholeHealth"
  }
};

export const SeityTodo: FunctionComponent<SeityTodoProps> =
  ({
     content,
     isVisible,
     onToggleItem,
     onTouchClose
   }): ReactElement => {
    const [load, setLoad] = useState(false);
    const [showClassName, setShowClassName] = useState("animation-slideUp hide-todo");

    useEffect(() => {
      if (!load) {
        setLoad(true);
        return;
      }
      if (isVisible) {
        setShowClassName("animation-slideDown show-todo");
      } else {
        setShowClassName("animation-slideUp");
        setTimeout(() => {
          setShowClassName("hide-todo");
        }, 700);
      }
    }, [isVisible]);

    return (
      <div className={`todo-container ${showClassName}`}>
        <div
          className="seity-todo-mask"
          onClick={() => {
            onTouchClose();
          }}
        />
        <div className="seity-todo">
          <div className="seity-todo__content">
            {content &&
              content.map((item, index) => (
                <SeityTodoItem
                  key={`${item.messageKeyID} + ${index}`}
                  keyStr={`${item.messageKeyID} + ${index}`}
                  item={item}
                  configuration={resolveTodoItemConfig[item.messageTypeID]}
                  onClose={onTouchClose}
                />
              ))}
          </div>
          <a
            href=""
            className="mt-3 mb-2"
            onClick={(e) => {
              e.preventDefault();
              onTouchClose();
            }}
          >
            <img src={closeButton}/>
          </a>
        </div>
      </div>
    );
  };
