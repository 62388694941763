import React from 'react';
import './styles.scss';

interface ProgressBarProps {
  currentStep: number;
  totalSteps?: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ currentStep, totalSteps = 4 }) => {
  const steps = Array.from({ length: totalSteps }, (_, index) => index + 1);

  return (
    <div className="progressbar-container">
      {steps.map((step) => (
        <div
          key={step}
          className="progressbar-step"
          style={{
            backgroundColor: step <= currentStep ? '#2F696C' : '#DCD8D6',
          }}
        />
      ))}
    </div>
  );
};

export default ProgressBar;
