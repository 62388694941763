import { BASE_URL } from "../../apiConstants";
import axios from "axios";
import { SeityResponse } from "../../baseTypes";
import { CoreValueSelectionUpdate } from "./types";

export async function coreValueSelectionUpdateRequest(
  token: string,
  coreValueSelectionUpdate: CoreValueSelectionUpdate
): Promise<SeityResponse> {
  const url = BASE_URL + "/WholeHealth/CoreValueSelectionUpdate";
  try {
    const coreValueCategorySelectionUpdateResponse = await axios.post<SeityResponse>(url, coreValueSelectionUpdate, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return coreValueCategorySelectionUpdateResponse.data;
  } catch (err) {
    throw err;
  }
}