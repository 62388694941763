import React, { useContext } from 'react';
import { RadioButtonContext } from '../SeityRadioButtonGroup/SeityRadioButtonGroup';
import './styles.scss';

export interface SeityRadioButtonItemProps {
  value: string;
  label: string;
  disabled?: boolean;
  onPress?: () => void;
  testID?: string;
}

const SeityRadioButtonItem: React.FC<SeityRadioButtonItemProps> = ({
                                                                     value,
                                                                     label,
                                                                     disabled,
                                                                     onPress,
                                                                     testID,
                                                                   }) => {
  const context = useContext(RadioButtonContext);

  const checked = context?.value === value;

  const handleChange = () => {
    if (!disabled) {
      if (onPress) {
        onPress();
      }
      context?.onValueChange(value);
    }
  };

  return (
    <label className="radioButtonItem" data-testid={testID}>
      <input
        type="radio"
        name={context?.name}
        value={value}
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
      />
      <span className="radioLabel">{label}</span>
    </label>
  );
};

export default SeityRadioButtonItem;
