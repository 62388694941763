import { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import strings from "../../../_core/strings/strings";

import "./styles.scss";

import CommitSuccessModal from "../CommitSuccessModal";
import { Intention } from "../../../api/dailyIntention/intentionTypes";
import { getColorData, getDragonImg } from "../ChooseIntention/helpers";
import SeityIdkModal from "../../../_core/components/SeityIdkModal";
import SeityCVSelection, { CoreValueType } from "../../../_core/components/SeityCVSelection";
import { RootState } from "../../../app/rootReducer";
import { getSortedFourCoreValuesRequest } from "../../coreValues/coreValuesSlice";
import SeityButton from "../../../_core/components/SeityButton";
import { getCongratulationsRequest, postIntentionRequest } from "../intentionsSlice";
import SeityLoader from "../../../_core/components/SeityLoader";
import { convertCorevals } from "./helpers";
import { SeityBackButton } from "../../../_core/components/SeityBackButton";
import { SeityCloseButton } from "../../../_core/components/SeityCloseButton";
import RequireSeityImage from "../../../_core/utils/graphicUtils/localGraphic";
import { PlotlineInjectionPointID } from "../../../api/plotlineTypes";
import { isPlotlineInjectionCompleted } from "../../plotline/helpers";

type LocationStateProps = {
  intention: Intention;
};

const IntentionSelected: FunctionComponent = (): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<LocationStateProps>();
  const { intention } = location?.state;

  // this is to prevent refreshed state when the browser is reloaded
  if (!intention) {
    history.goBack();
  }

  const { sortedFourCoreValues } = useSelector((state: RootState) => {
    return state.fourCoreValues;
  });
  const { isCVOnly } = useSelector((state: RootState) => {
    return state.userAppSettings.userAppSettings;
  });
  const { plotlineInjections, firstIncompletePLInjectionId } = useSelector((state: RootState) => {
    return state.plotline;
  });

  const [isLoading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showIdkModal, setShowIdkModal] = useState(false);
  const [coreVals, setCoreVals] = useState<Array<CoreValueType>>([]);

  useEffect(() => {
    dispatch(getSortedFourCoreValuesRequest());
    dispatch(getCongratulationsRequest(1));
  }, []);

  useEffect(() => {
    if (sortedFourCoreValues.length === 4) {
      const vals = sortedFourCoreValues.map((c) => {
        return {
          id: c.coreValueID,
          name: c.coreValue,
          selected: false
        };
      });
      setCoreVals(vals);
    }
  }, [sortedFourCoreValues]);

  const colorData = getColorData(intention.outcomeCategoryID);
  return intention ? (
    <div className="intention-selected mb-fs-layout mt-xxl">
      <div className="mobile-header w-100">
        <SeityBackButton wrapperClassName="header-back-button" />
        <SeityCloseButton />
      </div>
      <div className="intention-selected__card mb-xxl" style={{ backgroundColor: colorData.bg, borderColor: colorData.border }}>
        <div className="listitem-top mb-md">
          <img
            className="mr-lg"
            src={intention.outcomeCategoryImage}
            alt="category img"
            width={32}
            height={32}
          />
          <span style={{ color: colorData.text }}>{intention.outcomeCategory}</span>
          <img
            className="check-icon"
            style={{ backgroundColor: colorData.border }}
            src={require("../../../assets/graphics/intentions/check.png").default}
            alt="check icon"
          />
        </div>
        <div className="listitem-middle flex-col flex-center w-100 p-sm">
          <img className="background-img" src={intention.imageFileName + "h.png"} alt="background img" />
          <div className="listitem-overlay flex-col-center w-100 h-100 flex">
            <p>
              {strings.todayIWill}
              <br />
              <br />
              {intention.text}
            </p>
          </div>
        </div>
        <div className={`listitem-bottom flex-ai-c mt-md ${isCVOnly ? "flex-center" : ""}`}>
          <img
            className="mr-md"
            src={intention.outcomeSubcategoryImage}
            alt="subcategory img"
            width={32}
            height={32}
          />
          <span className={`${!isCVOnly ? "mr-auto" : ""}`} style={{ color: colorData.text }}>
            {intention.outcomeSubcategory}
          </span>
          {!isCVOnly && intention.dragonType && intention.dragonType.length > 0 && (
            <img src={getDragonImg(intention.dragonType)} alt="dragon" width={32} height={32} />
          )}
        </div>
      </div>
      <p>{strings.selectWhichCV}</p>
      <SeityCVSelection
        coreVals={coreVals}
        isReadOnly={false}
        size={48}
        onChange={(vals) => {
          setCoreVals(vals);
        }}
      />
      <button
        className="intention-ancho btn-idk"
        onClick={() => {
          setShowIdkModal(true);
        }}
      >
        {strings.idk}
      </button>
      <SeityButton
        className="intention-button mt-xxl"
        label={strings.commit}
        onClick={() => {
          const cv = convertCorevals(coreVals);
          setLoading(true);
          const param = {
            dailyIntentionID: intention.dailyIntentionID,
            ...cv
          };
          dispatch(
            postIntentionRequest(param, () => {
              setLoading(false);
              setShowSuccess(true);
              // check if plotline 4 is completed
              // if (!isPlotlineInjectionCompleted(plotlineInjections, PlotlineInjectionPointID.OnDailyIntention)) {
              //   history.push("/plotline/" + PlotlineInjectionPointID.OnDailyIntention);
              // } else {
              //   setShowSuccess(true);
              // }
            })
          );
        }}
      />
      {showSuccess && (
        <CommitSuccessModal
          isOpen={showSuccess}
          onClose={() => {
            setShowSuccess(false);
            history.push("/");
          }}
        />
      )}
      {isLoading && <SeityLoader />}
      {showIdkModal && (
        <SeityIdkModal
          isOpen={showIdkModal}
          onClose={() => {
            setShowIdkModal(false);
          }}
        />
      )}
    </div>
  ) : (
    <></>
  );
};

export default IntentionSelected;
