export class FourCoreValuesSortOrderUpdate {
  coreValueID01: number;
  coreValueID02: number;
  coreValueID03: number;
  coreValueID04: number;
  questionKey: string;

  constructor(coreValueID01: number, coreValueID02: number, coreValueID03: number, coreValueID04: number,
    questionKey: string
  ) {
    this.coreValueID01 = coreValueID01;
    this.coreValueID02 = coreValueID02;
    this.coreValueID03 = coreValueID03;
    this.coreValueID04 = coreValueID04;
    this.questionKey = questionKey;
  }
}