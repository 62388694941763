import React from 'react';
import SeityRadioButtonGroup from '../SeityRadioButtonGroup/SeityRadioButtonGroup';
import SeityRadioButtonItem from '../SeityRadioButtonItem/SeityRadioButtonItem';

export interface AnswerGroupProps {
  onValueChange: (value: string) => void;
  radioValue: string;
  items: { label: string; value: string }[];
  name: string;
}

const AnswerGroup: React.FC<AnswerGroupProps> = ({ onValueChange, radioValue, items, name }) => {
  return (
    <SeityRadioButtonGroup onValueChange={onValueChange} value={radioValue} name={name}>
      {items.map((item) => (
        <SeityRadioButtonItem
          label={item.label}
          key={item.value}
          value={item.value}
        />
      ))}
    </SeityRadioButtonGroup>
  );
};

export default AnswerGroup;
