import React from 'react';
import './styles.scss';

interface ThermometerProps {
  score: number;
}

const Thermometer: React.FC<ThermometerProps> = ({ score }) => {
  const sanitizedScore = Math.max(0, Math.min(score, 100));
  const thermometerHeight = 150;
  const circlePosition = ((100 - sanitizedScore) / 100) * thermometerHeight;

  let circleBackgroundColor = 'transparent';
  if (sanitizedScore >= 69) {
    circleBackgroundColor = '#71AE3C';
  } else if (sanitizedScore >= 49) {
    circleBackgroundColor = '#D9AE2E';
  } else {
    circleBackgroundColor = '#D92E2E';
  }

  return (
    <div className="thermometerContainer">
      <p className="topLabel">100</p>

      <div className="thermometerWrapper">
        <div className="thermometer">
          <div className="thermometerTop" />
          <div className="thermometerMiddle" />
          <div className="thermometerBottom" />
        </div>

        <div
          className="circle"
          style={{ top: `${circlePosition}px`, backgroundColor: circleBackgroundColor }}
        >
          <p className="circleText">{sanitizedScore}</p>
        </div>
      </div>

      <p className="bottomLabel">0</p>
    </div>
  );
};

export default Thermometer;
