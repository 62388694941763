import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import './styles.scss';

interface AccordionItemProps {
  title: string;
  children: React.ReactNode;
  subText?: string | null;
  onToggle?: (isExpanded: boolean) => void;
}

const AccordionItem: React.FC<AccordionItemProps> = ({ title, children, subText, onToggle }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded((prev) => {
      const newState = !prev;
      if (onToggle) onToggle(newState);
      return newState;
    });
  };

  return (
    <div className="accordion-container">
      <button onClick={toggleExpand} className="accordion-header">
        <div className="title-section">
          <p className="header-text">{title}</p>
        </div>

        {subText && (
          <div className="subtext-section">
            <p className="subtext">{subText}</p>
          </div>
        )}

        <div className={`icon-section ${isExpanded ? 'icon-expanded' : ''}`}>
          <FontAwesomeIcon icon={faChevronRight} className="icon" />
        </div>
      </button>

      {isExpanded && <div className="accordion-body">{children}</div>}
    </div>
  );
};

export default AccordionItem;
