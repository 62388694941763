import { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SeityPrimaryCancelButtons from "../../../../_core/components/SeityNavigationButtons";
import SeityCVImage from "../../../../_core/components/SeityCVImage";
import SeityLoader from "../../../../_core/components/SeityLoader";

import { RootState } from "../../../../app/rootReducer";
import { postCoreValueMarkComplete, sendFourCoreValuesRequest } from "../../coreValuesSlice";

import { YourFourCoreValuesProps } from "./types";
import strings from "../../../../_core/strings/strings";

import "./styles.scss";

export const YourFourCoreValues: FunctionComponent<YourFourCoreValuesProps> = (props) => {
  const dispatch = useDispatch();
  const [selectedValues, setSelectedValues] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const { fourCoreValues, coreValueGenerics, error: coreValueCategoryError } = useSelector((state: RootState) => {
    return state.assessment.coreValues;
  });

  const handleSelect = (coreValueID: number) => {
    if (selectedValues.includes(coreValueID)) {
      setSelectedValues(
        selectedValues.filter((id) => {
          return id !== coreValueID;
        })
      );
    } else {
      setSelectedValues([...selectedValues, coreValueID]);
    }
  };

  useEffect(() => {
    const fetchCoreValues = async () => {
      await dispatch(sendFourCoreValuesRequest());
      setIsLoading(false);
    };

    fetchCoreValues();
  }, [dispatch]);

  return (
    <div className="four-core-values-wrapper">
      <h1>{strings.hereAreYourCoreValues}</h1>
      {isLoading ? (
        <SeityLoader/>
      ) : (
        <div className="four-core-values-container">
          {fourCoreValues.length === 4 &&
            fourCoreValues.map((coreValue, index) => {
              return selectedValues.includes(coreValue.coreValueID) ? (
                <div
                  key={index}
                  className="four-core-values-container-value four-core-value-selected"
                  onClick={() => {
                    return handleSelect(coreValue.coreValueID);
                  }}
                >
                  <p className="four-core-value-title-selected-header">{coreValue.coreValue}</p>
                  <p className="four-core-value-title-selected">{coreValue.coreValueText}</p>
                </div>
              ) : (
                <div
                  key={index}
                  className="four-core-values-container-value"
                  onClick={() => {
                    return handleSelect(coreValue.coreValueID);
                  }}
                >
                  <SeityCVImage classname="four-core-value-img" cvId={coreValue.coreValueID} size={96} />
                  <p className="four-core-value-title">{coreValue.coreValue}</p>
                </div>
              );
            })}
        </div>
      )}
      <div className="four-core-value-bottom">
        <div className="is-accurate">
          <p>{strings.isAccurate}</p>
        </div>
        <SeityPrimaryCancelButtons
          primaryTitle={props.isCVOnly ? strings.yesThatsMe : strings.yesItsAccurate}
          primaryAction={() => {
            return props.onNextClicked(props.stepId);
          }}
          primaryClassName="is-accurate-primary-button"
          cancelTitle={strings.noStartOver}
          cancelAction={() => {;
            return props.onBackClicked(props.stepId);
          }}
          cancelClassName="is-accurate-cancel-button"
          primaryDisabled={false}
          errorMessage={coreValueCategoryError}
          showCancel
          isVertical
          cancelButtonType="discard"
        />
      </div>
    </div>
  );
};