import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/rootReducer';
import { AppDispatch } from '../../../../app/store';
import { fetchWhatAreDragons } from '../../slices/whatAreDragonsSlice';
import './styles.scss';
import DragonItem from "../DragonItem/DragonItem";

interface DragonInfoModalProps {
  isVisible: boolean;
  onClose: () => void;
}

const DragonInfoModal: React.FC<DragonInfoModalProps> = ({ isVisible, onClose }) => {
  const dispatch: AppDispatch = useDispatch();

  const whatAreDragons = useSelector((state: RootState) => state.whatAreDragons.whatAreDragons);
  const isLoading = useSelector((state: RootState) => state.whatAreDragons.isLoading);
  const error = useSelector((state: RootState) => state.whatAreDragons.error);

  useEffect(() => {
    Modal.setAppElement('#root'); // Ensure you set this for accessibility
  }, []);

  useEffect(() => {
    if (isVisible) {
      dispatch(fetchWhatAreDragons());
    }
  }, [isVisible, dispatch]);

  if (!isVisible) {
    return null;
  }

  if (isLoading) {
    return (
      <Modal
        isOpen={isVisible}
        onRequestClose={onClose}
        className="dragon-info-modal"
        overlayClassName="dragon-info-modal-overlay"
      >
        <div className="loadingContainer">Loading...</div>
      </Modal>
    );
  }

  if (error) {
    return (
      <Modal
        isOpen={isVisible}
        onRequestClose={onClose}
        className="dragon-info-modal"
        overlayClassName="dragon-info-modal-overlay"
      >
        <button className="closeButton" onClick={onClose}>×</button>
        <div className="errorText">{error}</div>
      </Modal>
    );
  }

  if (!whatAreDragons) {
    return (
      <Modal
        isOpen={isVisible}
        onRequestClose={onClose}
        className="dragon-info-modal"
        overlayClassName="dragon-info-modal-overlay"
      >
        <button className="closeButton" onClick={onClose}>×</button>
        <div className="errorText">No data available.</div>
      </Modal>
    );
  }

  const { dragonInfo } = whatAreDragons;
  const { header, text, lmDragonInfos } = dragonInfo;

  return (
    <Modal
      isOpen={isVisible}
      onRequestClose={onClose}
      className="dragon-info-modal"
      overlayClassName="dragon-info-modal-overlay"
    >
      <button className="closeButton" onClick={onClose}>×</button>
      <div className="modalScrollContent">
        <h2 className="dragonInfoHeader">{header}</h2>
        <p className="dragonInfoText">{text?.replace(/\\n/g, '\n')}</p>

        <div className="dragonItemContainer">
          {lmDragonInfos.map((dragon) => (
            <DragonItem key={dragon.dragonID} dragon={dragon} />
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default DragonInfoModal;
