import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import './styles.scss';
import { RootState } from '../../../../app/rootReducer';
import { GRAPHICS_URL } from '../../../../api/apiConstants';
import SeityButton from '../../../../_core/components/SeityButton';
import { subcategoryImages } from '../../assets/subcategoryImages';

interface OutcomeAssessmentIntroModalProps {
  isVisible: boolean;
  onClose: () => void;
  onNext: () => void;
}

const OutcomeAssessmentIntroModal: React.FC<OutcomeAssessmentIntroModalProps> = ({
                                                                                   isVisible,
                                                                                   onClose,
                                                                                   onNext,
                                                                                 }) => {
  const surveyIntro = useSelector((state: RootState) => state.surveyOutcomeDetail.surveyDetail?.surveyIntro);
  const categoryHeading = useSelector((state: RootState) => state.surveyOutcomeDetail.surveyDetail?.categoryHeading);
  const outcomeCategoryID = useSelector((state: RootState) => state.surveyOutcomeDetail.currentOutcomeCategoryID);

  const [useFallbackImage, setUseFallbackImage] = useState(false);
  const [useFallbackLockImage, setUseFallbackLockImage] = useState(false);
  const [headerImageLoaded, setHeaderImageLoaded] = useState(false);

  if (!isVisible || !surveyIntro) {
    return null;
  }

  const fallbackImage = outcomeCategoryID
    ? (subcategoryImages[outcomeCategoryID] || subcategoryImages[1])
    : subcategoryImages[1];

  const fallbackLockImage = require('../../assets/lockIcon.png');

  const headerImageSource = useFallbackImage
    ? fallbackImage
    : GRAPHICS_URL + surveyIntro.sectionImage01;

  const lockImageSource = useFallbackLockImage
    ? fallbackLockImage
    : GRAPHICS_URL + surveyIntro.lockImage;

  return (
    <div className="outcome-assessment-intro-container">
      <div className="intro-content-container">
        <div className="intro-heading-container">
          {categoryHeading && <p className="intro-category-heading">{categoryHeading}</p>}
          <h2 className="intro-section-name">{surveyIntro.sectionName}</h2>
        </div>

        {/* Header Image Placeholder */}
        {!headerImageLoaded && (
          <div className="intro-header-image-placeholder" />
        )}

        <img
          className={`intro-header-image ${!headerImageLoaded ? 'intro-image-invisible' : ''}`}
          src={headerImageSource}
          alt="Section"
          onLoad={() => setHeaderImageLoaded(true)}
          onError={() => setUseFallbackImage(true)}
        />

        <div className="intro-text-container">
          <h3 className="intro-section-heading">{surveyIntro.sectionHeading}</h3>
          <p className="intro-section-text">{surveyIntro.sectionText}</p>
        </div>

        <img
          className="intro-lock-image"
          src={lockImageSource}
          alt="Lock"
          onError={() => setUseFallbackLockImage(true)}
        />

        <p className="intro-disclaimer-text">{surveyIntro.disclaimer}</p>
      </div>

      <div className="intro-footer-container">
        <SeityButton
          onClick={onNext}
          label={surveyIntro.startButtonText || 'Next'}
          type="next"
        />
      </div>
    </div>
  );
};

export default OutcomeAssessmentIntroModal;
