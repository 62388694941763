import { BASE_URL } from "../../apiConstants";
import axios from "axios";
import { FourCoreValuesResponse } from "../types";

export async function getFourCoreValues(token: string): Promise<FourCoreValuesResponse> {
  const url = BASE_URL + "/Account/FourCoreValues";
  try {
    const coreValueCateogryResponse = await axios.get<FourCoreValuesResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return coreValueCateogryResponse.data;
  } catch (err) {
    throw err;
  }
}