export const subcategoryImages: { [key: number]: any } = {
  1: require('./subcategory_1_banner.png'),
  2: require('./subcategory_2_banner.png'),
  3: require('./subcategory_3_banner.png'),
  4: require('./subcategory_4_banner.png'),
  5: require('./subcategory_5_banner.png'),
  6: require('./subcategory_6_banner.png'),
  7: require('./subcategory_7_banner.png'),
  8: require('./subcategory_8_banner.png'),
  9: require('./subcategory_9_banner.png'),
  10: require('./subcategory_10_banner.png'),
  11: require('./subcategory_11_banner.png'),
  12: require('./subcategory_12_banner.png'),
  13: require('./subcategory_13_banner.png'),
  14: require('./subcategory_14_banner.png'),
  15: require('./subcategory_15_banner.png'),
  16: require('./subcategory_16_banner.png'),
  17: require('./subcategory_17_banner.png'),
  18: require('./subcategory_18_banner.png'),
};
