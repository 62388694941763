import axios from 'axios';
import { ServiceResponseOutcomeSelectionUpdate, OutcomeSelectionUpdateRequest } from './types';
import { BASE_URL } from "../apiConstants";

export async function outcomeSelectionUpdate(
  token: string,
  outcomeSelectionID: number,
  answerLevel: number
): Promise<ServiceResponseOutcomeSelectionUpdate> {
  const url = `${BASE_URL}/WholeHealth/OutcomeSelectionUpdate`;

  const requestBody: OutcomeSelectionUpdateRequest = {
    outcomeSelectionID,
    answerLevel,
  };

  try {
    const response = await axios.post<ServiceResponseOutcomeSelectionUpdate>(
      url,
      requestBody,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (err: any) {
    if (err.response?.data?.userMessage) {
      err.message = err.response.data.userMessage;
    }
    throw err;
  }
}
