import React, { useRef } from 'react';
import AccordionItem from '../AccordionItem/AccordionItem';
import './styles.scss';

interface AccordionDataItem {
  id: string;
  title: string;
  subText?: string | null;
  content: React.ReactNode;
}

interface AccordionListProps {
  scrollViewRef: React.RefObject<HTMLDivElement>;
  data: AccordionDataItem[];
  error?: string | null;
}

const AccordionList: React.FC<AccordionListProps> = ({ scrollViewRef, data, error }) => {
  const accordionRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const handleAccordionToggle = (key: string, isExpanded: boolean) => {
    if (isExpanded && accordionRefs.current[key] && scrollViewRef.current) {
      const element = accordionRefs.current[key];
      const scrollView = scrollViewRef.current;

      if (element && scrollView) {
        const offsetTop = element.offsetTop;
        scrollView.scrollTo({ top: offsetTop, behavior: 'smooth' });
      }
    }
  };

  return (
    <>
      {error && <p className="error-text">{error}</p>}

      {data.map((item) => (
        <div
          key={item.id}
          ref={(ref) => {
            accordionRefs.current[item.id] = ref;
          }}
        >
          <AccordionItem
            title={item.title}
            subText={item.subText}
            onToggle={(isExpanded) => handleAccordionToggle(item.id, isExpanded)}
          >
            {item.content}
          </AccordionItem>
        </div>
      ))}
    </>
  );
};

export default AccordionList;
