import React from 'react';
import Modal from 'react-modal';
import './styles.scss';
import {useSelector} from 'react-redux';
import {RootState} from '../../../../app/rootReducer';
import SeityButton from '../../../../_core/components/SeityButton';

interface UnlockYourWholeHealthPageModalProps {
  visible: boolean;
  onClose: () => void;
  onComplete: () => void;
}

const UnlockYourWholeHealthPageModal: React.FC<UnlockYourWholeHealthPageModalProps> =
  ({
     visible,
     onClose,
     onComplete
   }) => {
    const myWholeHealth = useSelector((state: RootState) => state.wholeHealth.myWholeHealth);
    const header = myWholeHealth?.unlockWholeHealth?.header || 'Unlock your Whole Health page';
    const text01 = myWholeHealth?.unlockWholeHealth?.text01 ||
      'You can’t access your Whole Health page until you have discovered your Core Values.';
    const text02 = myWholeHealth?.unlockWholeHealth?.text02 || 'Would you like to start now?';
    const buttonText01 = myWholeHealth?.unlockWholeHealth?.buttonText01 || 'Yes';
    const buttonText02 = myWholeHealth?.unlockWholeHealth?.buttonText02 || 'Not now';

    return (
      <Modal
        isOpen={visible}
        onRequestClose={onClose}
        className="unlock-wholehealth-modal"
        overlayClassName="unlock-wholehealth-modal-overlay"
      >
        <button className="closeButton" onClick={onClose}>×</button>
        <div className="unlock-wholehealth-content">
        <h2 className="title">{header}</h2>
          <div className="subTitleWrapper">
            <p className="subTitle">{text01}</p>
            <p className="subTitle">{text02}</p>
          </div>

          <div className="buttonWrapper">
            <SeityButton
              onClick={onComplete}
              label={buttonText01}
              className="buttonContainer"
            />
            <SeityButton
              onClick={onClose}
              label={buttonText02}
              className="buttonContainer"
              type="outline"
            />
          </div>
        </div>
      </Modal>
    );
  };

export default UnlockYourWholeHealthPageModal;
