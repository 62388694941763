import { BASE_URL } from "../../apiConstants";
import axios from "axios";
import { SeityResponse } from "../../baseTypes";
import { MarkCoreValuesCompleteRequest } from "./types";

export async function coreValueMarkComplete(token: string, request: MarkCoreValuesCompleteRequest): Promise<SeityResponse> {
  const url = BASE_URL + "/WholeHealth/CoreValueMarkComplete";
  try {
    const response = await axios.post<SeityResponse>(url, request, {
      headers: {
        Authorization: "Bearer " + token
      }
    });
    return response.data;
  } catch (err) {
    throw err;
  }
}
