import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from "../../../app/store";

export interface AssessmentHeaderState {
  heading: string | null;
  subheading: string | null;
  currentStep: number;
  totalSteps: number;
}

const initialState: AssessmentHeaderState = {
  heading: null,
  subheading: null,
  currentStep: 1,
  totalSteps: 4,
};

const assessmentHeaderSlice = createSlice({
  name: 'navigationHeader',
  initialState,
  reducers: {
    setTitle(state, action: PayloadAction<string | null>) {
      state.heading = action.payload;
    },
    setSubtitle(state, action: PayloadAction<string | null>) {
      state.subheading = action.payload;
    },
    setCurrentStep(state, action: PayloadAction<number>) {
      state.currentStep = action.payload;
    },
    setTotalSteps(state, action: PayloadAction<number>) {
      state.totalSteps = action.payload;
    },
    clearHeader(state) {
      state.heading = null;
      state.subheading = null;
      state.currentStep = 1;
      state.totalSteps = 4;
    },
  },
});

export const {
  setTitle,
  setSubtitle,
  setCurrentStep,
  setTotalSteps,
  clearHeader,
} = assessmentHeaderSlice.actions;

export default assessmentHeaderSlice.reducer;

export const updateNavigationTitleAndSubtitle = (title: string | null, subtitle: string | null): AppThunk => (dispatch) => {
  dispatch(setTitle(title));
  dispatch(setSubtitle(subtitle));
};

export const updateCurrentStep = (currentStep: number): AppThunk => (dispatch) => {
  dispatch(setCurrentStep(currentStep));
};

export const updateTotalSteps = (totalSteps: number): AppThunk => (dispatch) => {
  dispatch(setTotalSteps(totalSteps));
};
