import { FunctionComponent, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useParams } from "react-router-dom";

import { SeityBackButton } from "../../../_core/components/SeityBackButton";
import SeityDragonList from "../../../_core/components/SeityDragonList";

import { RootState } from "../../../app/rootReducer";
import RequireSeityImage from "../../../_core/utils/graphicUtils/localGraphic";
import { getPracticeScoresListRequest } from "../practicesSlice";

import { PracticeScoreSubCategory } from "../../../api/account/types/practiceScoresTypes";
import { DragonType, DragonListParamType, DragonListProps } from "./types";
import strings from "../../../_core/strings/strings";

import "./styles.scss";

/**
 * @deprecated
 * @constructor
 */
export const DragonList: FunctionComponent<DragonListProps> = () => {
  const { shouldRedirectToLogin } = useSelector((state: RootState) => {
    return state.auth;
  });
  const history = useHistory();

  const params = useParams<DragonListParamType>();
  const dispatch = useDispatch();
  const { dragonType } = params;

  const { awakeDragons, ragingDragons, tameDragons } = useSelector((state: RootState) => {
    return state.practiceScores;
  });
  const onToggleDragonItem = (dragonData: PracticeScoreSubCategory, dragonSelected: string) => {
    history.push(`/dragonList/${dragonSelected}/${dragonData.subcategoryID}/${dragonData.categoryID}`);
  };

  useEffect(() => {
    if (!awakeDragons?.length) {
      dispatch(getPracticeScoresListRequest());
      window.scrollTo(0, 0);
    }
  }, []);

  const {
    subCategoryData,
    noDragonsStr,
    dragonSelected,
    dragonDescription,
    dragonSubDescription,
    currentDragon,
    dragonLogo,
    dragonText,
    backgroundColor
  } = useMemo(() => {
    switch (dragonType) {
      case DragonType.RangingDragons:
        return {
          currentDragon: ragingDragons[0],
          dragonLogo: "dragonraging01",
          dragonText: strings.ragingDragon,
          subCategoryData: ragingDragons,
          noDragonsStr: strings.dragonRagingEmpty,
          dragonSelected: DragonType.RangingDragons,
          backgroundColor: "#7c8b89",
          dragonDescription: strings.dragonRagingDesc,
          dragonSubDescription: strings.dragonRagingSubDesc
        };
      case DragonType.AwakeDragons:
        return {
          currentDragon: awakeDragons[0],
          dragonLogo: "dragonawake01",
          dragonText: strings.awakeDragon,
          subCategoryData: awakeDragons,
          noDragonsStr: strings.dragonAwakeEmpty,
          dragonSelected: DragonType.AwakeDragons,
          backgroundColor: "#5a6b6a",
          dragonDescription: strings.dragonAwakeDesc,
          dragonSubDescription: strings.dragonAwakeSubDesc
        };

      case DragonType.TameDragons:
        return {
          currentDragon: tameDragons[0],
          dragonLogo: "dragontame01",
          dragonText: strings.tameDragon,
          subCategoryData: tameDragons,
          noDragonsStr: strings.dragonTameEmpty,
          dragonSelected: DragonType.TameDragons,
          backgroundColor: "#424c4a",
          dragonDescription: strings.dragonTameDesc,
          dragonSubDescription: strings.dragonTameSubDesc
        };

      default:
        return {
          currentDragon: awakeDragons[0],
          dragonLogo: "dragonawake01",
          dragonText: strings.awakeDragon,
          subCategoryData: [],
          noDragonsStr: "",
          dragonSelected: "",
          backgroundColor: "",
          dragonDescription: "",
          dragonSubDescription: ""
        };
    }
  }, [awakeDragons, dragonType, ragingDragons, tameDragons]);

  if (shouldRedirectToLogin) {
    return <Redirect to={{ pathname: "/login", state: { from: "/" } }} />;
  }

  return (
    <div className="dragon-list-main-container">
      <SeityBackButton wrapperClassName="dragon-list-main-back-button" />
      <h1>{dragonText}s</h1>
      <img className="dragon-list-logoImage" src={RequireSeityImage(dragonLogo).default} alt={dragonLogo} />
      <div className="dragon-list-background-wrapper">
        <div className="dragon-list-descripntion">
          <h3>{dragonDescription}</h3>
          <h3>{dragonSubDescription}</h3>
        </div>
        <div style={{ color: "rgb(255, 248, 235)", backgroundColor }} className="dragon-list-foreground-wrapper">
          <SeityDragonList
            onToggleDragonItem={(data) => {
              return onToggleDragonItem(data, dragonSelected);
            }}
            subCategoryData={subCategoryData}
            emptyDataTitle={noDragonsStr}
            dragonTitle={dragonText}
          />
        </div>
      </div>
    </div>
  );
};
