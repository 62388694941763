import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../app/rootReducer';
import { SurveyPracticeQuestion } from '../../../api/getSurveyDetail/types';

export const selectSurveyOutcomeDetailState = (state: RootState) => state.surveyOutcomeDetail;

export const selectAnsweredQuestionCountForCurrentCategory = createSelector(
  [selectSurveyOutcomeDetailState],
  (surveyOutcomeDetailState) => {
    const { surveyDetail, currentOutcomeCategoryID } = surveyOutcomeDetailState;

    const questions = surveyDetail?.surveyQuestions.filter(
      (q) => q.outcomeCategoryID === currentOutcomeCategoryID
    ) || [];

    const answeredCount = questions.filter((question: SurveyPracticeQuestion) => question.answerLevel > 0).length;
    const totalCount = questions.length;

    return { answeredCount, totalCount };
  }
);
