import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
  useRef,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AppDispatch } from '../../../../app/store';
import { RootState } from '../../../../app/rootReducer';
import {
  clearWholeHealthState,
  fetchMyWholeHealth,
} from '../../slices/wholeHealthSlice';
import MySurveysTab from "../MySurveysTab/MySurveysTab";
import { GRAPHICS_URL } from '../../../../api/apiConstants';
import './styles.scss';
import MyResultsTab from "../MyResultsTab/MyResultsTab";
import {fetchCoreValuesDetail} from "../../../../slices/coreValuesDetailSlice";
import TextWithNewlines from "../TextWithNewlines/TextWithNewlines";
import SurveyInfoModal from "../SurveyInfoModal/SurveyInfoModal";

const MyWholeHealth: FunctionComponent = (): ReactElement => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();

  const scrollViewRef = useRef<HTMLDivElement>(null);

  const isLoading = useSelector((state: RootState) => state.wholeHealth.isLoading);
  const { myWholeHealth, subcategorySvgData } = useSelector(
    (state: RootState) => state.wholeHealth
  );

  const [activeTab, setActiveTab] = useState<'surveys' | 'results'>('surveys');
  const [isSurveyInfoModalVisible, setSurveyInfoModalVisible] = useState(false);
  const [isUnlockModalVisible, setUnlockModalVisible] = useState(false);

  const [bannerImageSource, setBannerImageSource] = useState<string>(
    require('../../assets/myWholeHealthBanner.png').default
  );

  useEffect(() => {
    setActiveTab('surveys');
    dispatch(fetchMyWholeHealth());
    dispatch(fetchCoreValuesDetail());

    return () => {
      setUnlockModalVisible(false);
      dispatch(clearWholeHealthState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (
      myWholeHealth &&
      myWholeHealth.coreValuesStatusID === 2 &&
      myWholeHealth.coreValuesEnabled
    ) {
      setUnlockModalVisible(true);
    }
  }, [myWholeHealth]);

  useEffect(() => {
    if (myWholeHealth && myWholeHealth.headingImage01x3) {
      const remoteImageUrl = GRAPHICS_URL + myWholeHealth.headingImage01x3;
      const img = new Image();
      img.src = remoteImageUrl;
      img.onload = () => {
        setBannerImageSource(remoteImageUrl);
      };
      img.onerror = () => {
        // Handle error if needed
      };
    }
  }, [myWholeHealth]);

  const renderContent = () => {
    if (activeTab === 'surveys') {
      return (
        <MySurveysTab
          subcategorySvgData={subcategorySvgData}
          scrollViewRef={scrollViewRef}
        />
      );
    } else if (activeTab === 'results') {
      return <MyResultsTab scrollViewRef={scrollViewRef} />;
    }
    return null;
  };

  const handleInfoPress = () => {
    setSurveyInfoModalVisible(true);
  };

  const handleUnlockModalClose = () => {
    setUnlockModalVisible(false);
    history.goBack();
  };

  if (isLoading) {
    return (
      <div className="loadingContainer">
        Loading...
      </div>
    );
  }

  return (
    <div className="mainContainer">
      {/* Render only the modal when it is visible */}
      {isSurveyInfoModalVisible ? (
        <SurveyInfoModal
          isVisible={true}
          onClose={() => setSurveyInfoModalVisible(false)}
        />
      ) : (
        <>
          <div className="bannerContainer">
            <div
              className="bannerImage"
              style={{ backgroundImage: `url(${bannerImageSource})` }}
            >
              <button className="infoIconContainer" onClick={handleInfoPress}>
                <img
                  src={
                    require('../../../../assets/graphics/info-icon-white.png').default
                  }
                  className="infoIcon"
                  alt="Info"
                />
              </button>
              <div className="bannerContent">
                <p className="bannerText">
                  {myWholeHealth?.headingIndividualLines && (
                    <TextWithNewlines text={myWholeHealth.headingIndividualLines}/>
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className="tabsContainer">
            <button
              className={`tab ${
                activeTab === 'surveys' ? 'activeTab' : 'inactiveTabLeft'
              }`}
              onClick={() => setActiveTab('surveys')}
            >
              <div className="tabContent">
                <div className="tabTextContainer">
                  <p
                    className={`tabText ${
                      activeTab === 'surveys' ? 'activeTabText' : 'inactiveTabText'
                    }`}
                  >
                    {myWholeHealth?.surveysTab || 'My Surveys'}
                  </p>
                  {myWholeHealth && myWholeHealth?.totalSurveysAvailable > 0 && (
                    <div className="bubble">
                      <p className="bubbleText">
                        {myWholeHealth.totalSurveysAvailable}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </button>

            <button
              className={`tab ${
                activeTab === 'results' ? 'activeTab' : 'inactiveTabRight'
              }`}
              onClick={() => setActiveTab('results')}
            >
              <div className="tabContent">
                <p
                  className={`tabText ${
                    activeTab === 'results' ? 'activeTabText' : 'inactiveTabText'
                  }`}
                >
                  {myWholeHealth?.resultsTab || 'My Results'}
                </p>
              </div>
            </button>
          </div>

          <div ref={scrollViewRef} className="scrollViewContent">
            {renderContent()}
          </div>
        </>
      )}
    </div>
  );
};

export default MyWholeHealth;
