import { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { getAppContentByType } from "../../../../api/content/getAppContentByTypeId/getAppContentByTypeIdApi";
import SeityLoader from "../../../../_core/components/SeityLoader";

import SeityModal from "../../../../_core/components/SeityModal";
import { getRefreshedToken } from "../../../auth/UseSessionStorageToken";
import { AppContentType } from "../../../../api/content/types";
import { useHistory } from "react-router-dom";

export type CoreValuesOutroModalProps = {};

export const CoreValuesOutroModal: FunctionComponent<CoreValuesOutroModalProps> = (): ReactElement => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [appContentDictionary, setAppContentDictionary] = useState({});
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = (await getRefreshedToken()).token ?? '';
        getAppContentByType(token, AppContentType.CoreValueCongratulationsModal)
        .then((response) => {
           const dictionary = response.data.appContentLines.reduce((acc, item) => {
            acc[item.appContentTextKey] = item.contentText;
            return acc;
        }, {});
        console.log(dictionary)
        setAppContentDictionary(dictionary);
        })
        .finally(() => {
          setIsLoading(false);
        })
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, []);

  if (!isLoading) {
    return (
      <SeityModal actionButtons={[
        { type: "submit", label: "Yes" },
        { type: "cancel", label: "Not now" }, 
      ]} 
      isOpen={isModalOpen} 
      showCloseBtn={true}
      onClickActions={(idx) => {
        if (idx === 0) {
          history.push({
            pathname: "/assessment/wholeHealth"
          });
        } else if (idx === 1) {
          history.push({
            pathname: "/assessment/coreValuesOutro",
          });
        }
      }}
      onClose={() => history.push({
        pathname: "/assessment/coreValuesOutro",
      })}
      style={{maxWidth: '500px', textAlign: 'center'}}
      title={appContentDictionary["CoreValuesCompleteModalHeader1"]}
      title2={appContentDictionary["CoreValuesCompleteModalHeader2"]}
      text={appContentDictionary["CoreValuesCompleteModalBody1"]}
      text2={appContentDictionary["CoreValuesCompleteModalBody2"]}
      wrapperClassName="completed-cv-modal"
      />
    );
  }

  return <SeityLoader showBackgroundMask />;
};

export default CoreValuesOutroModal;
