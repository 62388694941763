import React, {useState, useRef, useEffect} from 'react';
import './styles.scss';
import {useHistory} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import Thermometer from "../Thermometer/Thermometer";

interface OutcomeResultCardProps {
  svgXml?: string;
  lifeOutcome: MyWholeHealthLifeOutcome;
  historyTitle: string;
  historyText: string;
  retakeSurveyText: string;
}

interface OutcomeScoreHistory {
  score: number;
  scoreDate: string;
}

export interface MyWholeHealthLifeOutcome {
  categoryName: string;
  categoryImage: string;
  surveysAvailable: number;
  surveysAvailableDescription: string;
  resultsAvailable: number;
  resultsAvailableDescription: string;
  timeEstimateDescription: string;
  lifeOutcomeName: string;
  lifeOutcomeImage: string;
  outcomeStatusID: number;
  statusDescription: string;
  statusImage: string;
  lifeOutcomeScore: number;
  lifeOutcomeValidScore: boolean;
  lifeOutcomeText: string;
  outcomeScoreHistories: OutcomeScoreHistory[];
}

const OutcomeResultCard: React.FC<OutcomeResultCardProps> =
  ({
     svgXml,
     lifeOutcome,
     historyTitle,
     historyText,
     retakeSurveyText

   }) => {
    const history = useHistory();
    const [imageError, setImageError] = useState(false);
    const scrollViewRef = useRef<HTMLDivElement>(null);
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);

    const [dragonSvgContent, setDragonSvgContent] = useState<string | null>(null);

    const GRAPHICS_URL = process.env.REACT_APP_GRAPHICS_URL || '';
    const dragonImageUrl = lifeOutcome.lifeOutcomeImage
      ? `${GRAPHICS_URL}${lifeOutcome.lifeOutcomeImage}`
      : null;

    useEffect(() => {
      let isMounted = true;

      const fetchSvg = async () => {
        try {
          if (dragonImageUrl) {
            const response = await fetch(dragonImageUrl);
            const svgText = await response.text();
            if (isMounted) {
              setDragonSvgContent(svgText);
            }
          }
        } catch (error) {
          console.error('Error fetching SVG:', error);
          setImageError(true);
        }
      };

      fetchSvg();

      return () => {
        isMounted = false;
      };
    }, [dragonImageUrl]);

    useEffect(() => {
      if (scrollViewRef.current) {
        scrollViewRef.current.scrollLeft =
          scrollViewRef.current.scrollWidth - scrollViewRef.current.clientWidth;
        handleScroll();
      }
    }, [lifeOutcome.outcomeScoreHistories]);

    const handleRetakeSurvey = () => {
      history.push('/myWholeHealth/OutcomeAssessment');
    };

    const scrollLeft = () => {
      if (scrollViewRef.current) {
        scrollViewRef.current.scrollBy({
          left: -200,
          behavior: 'smooth',
        });
      }
    };

    const scrollRight = () => {
      if (scrollViewRef.current) {
        scrollViewRef.current.scrollBy({
          left: 200,
          behavior: 'smooth',
        });
      }
    };

    const handleScroll = () => {
      if (scrollViewRef.current) {
        const {
          scrollLeft,
          scrollWidth,
          clientWidth
        } = scrollViewRef.current;
        setCanScrollLeft(scrollLeft > 0);
        setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
      }
    };

    return (
      <div className="cardContainer">
        <div className="subcategoryCard">
          {svgXml ? (
            <div
              className="subcategoryImage"
              dangerouslySetInnerHTML={{__html: svgXml}}
            />
          ) : (
            <div className="svgPlaceholder">
              <p className="svgPlaceholderText">SVG</p>
            </div>
          )}

          <div className="subcategoryTextContainer">
            <p className="subcategoryText">{lifeOutcome.categoryName}</p>
          </div>

          {dragonSvgContent && !imageError && (
            <div
              className="dragonImage"
              dangerouslySetInnerHTML={{__html: dragonSvgContent}}
              aria-label={`Dragon image for ${lifeOutcome.categoryName}`}
            />
          )}
        </div>

        <div className="expandedContent">
          <p className="dragonScoreText">{lifeOutcome.lifeOutcomeScore}</p>

          {lifeOutcome.lifeOutcomeName && (
            <p className="dragonTitleText">{lifeOutcome.lifeOutcomeName}</p>
          )}

          <p className="dragonText">{lifeOutcome.lifeOutcomeText}</p>

          <button className="retakeSurveyButton" onClick={handleRetakeSurvey}>{retakeSurveyText}</button>

          <p className="historyTitle">{historyTitle}</p>

          {lifeOutcome.outcomeScoreHistories.length > 0 && (
            <>
              {lifeOutcome.outcomeScoreHistories.length <= 3 ? (
                <div className="historyContentContainer">
                  <div className="scoreHistoriesContainer">
                    {[...lifeOutcome.outcomeScoreHistories]
                      .sort(
                        (a, b) =>
                          new Date(a.scoreDate).getTime() -
                          new Date(b.scoreDate).getTime()
                      )
                      .map((history, index) => (
                        <div key={index} className="scoreHistoryItem">
                          <Thermometer score={history.score}/>
                          <p className="scoreHistoryDate">{history.scoreDate}</p>
                        </div>
                      ))}
                  </div>
                  <p className="historyText">{historyText}</p>
                </div>
              ) : (
                <div className="scrollContainer">
                  {canScrollLeft && (
                    <button className="chevronLeft" onClick={scrollLeft}>
                      <FontAwesomeIcon icon={faChevronLeft} size="2x"/>
                    </button>
                  )}
                  <div
                    className="scoreHistoriesScroll"
                    ref={scrollViewRef}
                    onScroll={handleScroll}
                  >
                    <div className="scoreHistoriesContainer">
                      {[...lifeOutcome.outcomeScoreHistories]
                        .sort(
                          (a, b) =>
                            new Date(a.scoreDate).getTime() -
                            new Date(b.scoreDate).getTime()
                        )
                        .map((history, index) => (
                          <div key={index} className="scoreHistoryItem">
                            <Thermometer score={history.score}/>
                            <p className="scoreHistoryDate">{history.scoreDate}</p>
                          </div>
                        ))}
                    </div>
                  </div>
                  {canScrollRight && (
                    <button className="chevronRight" onClick={scrollRight}>
                      <FontAwesomeIcon icon={faChevronRight} size="2x"/>
                    </button>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

export default OutcomeResultCard;
