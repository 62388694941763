import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './styles.scss';
import { RootState } from '../../../../app/rootReducer';
import { GRAPHICS_URL } from '../../../../api/apiConstants';
import { subcategoryImages } from '../../assets/subcategoryImages';
import SeityButton from '../../../../_core/components/SeityButton';
import { fetchSurveyOutro } from '../../slices/surveyOutroSlice';
import {fetchSurveyDetail} from "../../slices/surveyDetailSlice";
import DragonInfoModal from '../DragonInfoModal/DragonInfoModal'; // Import the new modal

interface PracticeAssessmentOutroRouteParams {
  subcategoryID: string;
}

const PracticeAssessmentOutroPage: React.FC = () => {
  const { subcategoryID } = useParams<PracticeAssessmentOutroRouteParams>();
  const dispatch = useDispatch();
  const history = useHistory();

  const surveyOutro = useSelector((state: RootState) => state.surveyOutro.surveyOutro);
  const categoryHeading = useSelector((state: RootState) => state.surveyDetail.surveyDetail?.categoryHeading);
  const isLoading = useSelector((state: RootState) => state.surveyOutro.isLoading);
  const error = useSelector((state: RootState) => state.surveyOutro.error);

  const [useFallbackHeaderImage, setUseFallbackHeaderImage] = useState(false);
  const [useFallbackDragonImage, setUseFallbackDragonImage] = useState(false);
  const [headerImageLoaded, setHeaderImageLoaded] = useState(false);

  const [isDragonModalVisible, setDragonModalVisible] = useState(false);

  useEffect(() => {
    dispatch(fetchSurveyDetail(Number(subcategoryID)));
    dispatch(fetchSurveyOutro(Number(subcategoryID)));
  }, [dispatch, subcategoryID]);

  const handleContinue = () => {
    history.push('/MyWholeHealth');
  };

  if (isLoading) {
    return <div className="loadingContainer">Loading...</div>;
  }

  if (error) {
    return <div className="errorText">{error}</div>;
  }

  if (!surveyOutro) {
    return <div className="errorText">No outro data available.</div>;
  }

  const {
    sectionName,
    heading,
    sectionImage01,
    dragonImage,
    dragonText,
    resultsText,
    dragonsInfoButtonText,
    continueButtonText
  } = surveyOutro;

  const fallbackImage = subcategoryImages[Number(subcategoryID) ?? 1] || subcategoryImages[1];
  const fallbackDragonImage = require('../../assets/lockIcon.png');

  const headerImageSource = useFallbackHeaderImage
    ? fallbackImage
    : GRAPHICS_URL + sectionImage01;

  const dragonImageSource = dragonImage
    ? (useFallbackDragonImage ? fallbackDragonImage : GRAPHICS_URL + dragonImage)
    : null;

  return (
    <div className="practice-assessment-outro-container">
      {/* Dragon Info Modal */}
      <DragonInfoModal
        isVisible={isDragonModalVisible}
        onClose={() => setDragonModalVisible(false)}
      />

      <div className="outro-content-container">
        <div className="outro-heading-container">
          {categoryHeading && <p className="outro-category-heading">{categoryHeading}</p>}
          <h2 className="outro-section-name">{sectionName}</h2>
        </div>

        {!headerImageLoaded && (
          <div className="outro-header-image-placeholder"/>
        )}
        <img
          className={`outro-header-image ${!headerImageLoaded ? 'outro-image-invisible' : ''}`}
          src={headerImageSource}
          alt="Section"
          onLoad={() => setHeaderImageLoaded(true)}
          onError={() => setUseFallbackHeaderImage(true)}
        />

        {dragonImageSource && (
          <div className="outro-dragon-container">
            <img
              className="outro-dragon-image"
              src={dragonImageSource}
              alt="Dragon"
              onError={() => setUseFallbackDragonImage(true)}
            />
            <div className="outro-dragon-text-container">
              {heading && <h3 className="outro-dragon-heading">{heading}</h3>}
              {dragonText && <p className="outro-section-text">{dragonText}</p>}
            </div>
          </div>
        )}

        {resultsText && <p className="outro-results-text">{resultsText}</p>}
      </div>

      <div className="outro-footer-container">
        {dragonsInfoButtonText && (
          <p
            className="outro-dragon-info-text"
            onClick={() => setDragonModalVisible(true)}
          >
            {dragonsInfoButtonText}
          </p>
        )}
        <SeityButton
          onClick={handleContinue}
          label={continueButtonText || 'Continue'}
          type="next"
        />
      </div>
    </div>
  );
};

export default PracticeAssessmentOutroPage;
