import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../../app/store';
import { setRedirectToLogin } from '../../auth/authSlice';
import { SeityAuthenticationError } from '../../../api/authTypes';
import { getRefreshedToken } from '../../auth/UseSessionStorageToken';
import {CoreValuesCompleteData} from "../../../api/coreValues/getCoreValuesCompleteDetails/types";
import {getCoreValuesCompleteDetails} from "../../../api/coreValues/getCoreValuesCompleteDetails/getCoreValuesCompleteDetailsApi";

interface CoreValuesCompleteDetailsState {
  error: string | null;
  isLoading: boolean;
  coreValuesCompleteDetails: CoreValuesCompleteData | null;
}

const initialState: CoreValuesCompleteDetailsState = {
  error: null,
  isLoading: false,
  coreValuesCompleteDetails: null,
};

const coreValuesCompleteDetailsSlice = createSlice({
  name: 'core_values_complete_details',
  initialState,
  reducers: {
    fetchCoreValuesCompleteDetailsSuccess(state, action: PayloadAction<CoreValuesCompleteData>) {
      state.coreValuesCompleteDetails = action.payload;
      state.isLoading = false;
    },
    setApiError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    clearApiError(state) {
      state.error = null;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
  },
});

export const {
  fetchCoreValuesCompleteDetailsSuccess,
  setApiError,
  clearApiError,
  setIsLoading,
} = coreValuesCompleteDetailsSlice.actions;

export default coreValuesCompleteDetailsSlice.reducer;

export const fetchCoreValuesCompleteDetails = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    dispatch(clearApiError());

    const { token } = await getRefreshedToken();
    if (token === null) {
      dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
      throw new SeityAuthenticationError();
    }

    const response = await getCoreValuesCompleteDetails(token);

    if (response.success) {
      dispatch(fetchCoreValuesCompleteDetailsSuccess(response.data));
    } else {
      dispatch(setApiError(response.userMessage || 'Failed to fetch Core Values Complete Details'));
    }
  } catch (err: any) {
    dispatch(setApiError(err.toString()));
  } finally {
    dispatch(setIsLoading(false));
  }
};
