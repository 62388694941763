import React from 'react';
import { useSelector } from 'react-redux';
import './styles.scss';
import PracticeQuestion from '../PracticeQuestion/PracticeQuestion';
import PracticeAssessmentNavigation from '../PracticeAssessmentNavigation/PracticeAssessmentNavigation';
import useOutcomeAssessment from '../../hooks/useOutcomeAssessment';
import { selectAnsweredQuestionCountForCurrentCategory } from '../../selectors/surveyOutcomeDetailSelectors';
import OutcomeAssessmentIntroModal from '../OutcomeAssessmentIntroModal/OutcomeAssessmentIntroModal';
import CoreValuesConfirmationModal from '../CoreValuesConfirmationModal/CoreValuesConfirmationModal';
import ProgressBar from '../ProgressBar/ProgressBar';
import OutcomeAssessmentIncentive from '../OutcomeAssessmentIncentive/OutcomeAssessmentIncentive';

const OutcomeAssessment: React.FC = () => {
  const {
    surveyDetail,
    isLoading,
    error,
    currentQuestions,
    handleNext,
    handleBack,
    onQuestionChange,
    questionRefs,
    scrollContainerRef,
    isIntroModalVisible,
    handleIntroModalClose,
    handleIntroNext,
    isOutroModalVisible,
    handleOutroModalClose,
    handleOutroContinue,
    currentIncentive,
    isIncentiveModalVisible,
    handleIncentiveModalClose,
    currentSectionHeading,
    continueButtonText,
    backButtonText,
    isCoreValuesModalVisible,
    handleCoreValuesConfirm,
    handleCoreValuesRetake,
    currentStep,
    totalSteps,
  } = useOutcomeAssessment();

  const { answeredCount, totalCount } = useSelector(selectAnsweredQuestionCountForCurrentCategory);

  // If any modal is visible, we don’t show the main content behind it
  const shouldShowMainContent =
    !isLoading &&
    !isIntroModalVisible &&
    !isCoreValuesModalVisible &&
    !isIncentiveModalVisible &&
    !isOutroModalVisible;

  const allQuestionsAnswered = currentQuestions?.every((q) => q.answerLevel > 0);

  if (isIncentiveModalVisible && currentIncentive) {
    return (
      <OutcomeAssessmentIncentive
        incentive={currentIncentive}
        onClose={handleIncentiveModalClose} // This triggers goToNextCategory
      />
    )
  }

  return (
    <div className="outcome-assessment-mainContainer">
      {isLoading ? (
        <div className="loadingContainer">Loading...</div>
      ) : (
        <>
          {/* Core Values Modal */}
          <CoreValuesConfirmationModal
            isVisible={isCoreValuesModalVisible}
            onClose={handleBack}
            onConfirm={handleCoreValuesConfirm}
            onRetake={handleCoreValuesRetake}
            surveyCoreValuePage={surveyDetail?.surveyCoreValuePage}
          />

          {/* Intro Modal */}
          <OutcomeAssessmentIntroModal
            isVisible={isIntroModalVisible}
            onClose={handleIntroModalClose}
            onNext={handleIntroNext}
          />

          {shouldShowMainContent && surveyDetail && (
            <>
              {/* Progress Bar */}
              <ProgressBar currentStep={currentStep} totalSteps={totalSteps} />

              {/* Header area */}
              <div className="headingContainer">
                <p className="categoryHeading">{surveyDetail.categoryHeading}</p>
              </div>

              <div className="subcategoryHeader">
                <p className="subcategoryText">{currentSectionHeading}</p>
                <p className="questionCountText">
                  {answeredCount}/{totalCount}
                </p>
              </div>

              <div ref={scrollContainerRef}>
                {error && <p className="errorText">{error}</p>}
                {!error && currentQuestions && (
                  <div>
                    {currentQuestions.map((question, index) => (
                      <PracticeQuestion
                        key={question.selectionID}
                        id={question.selectionID}
                        questionTypeId={question.questionTypeID}
                        questionText={question.questionText}
                        answerLevel={question.answerLevel}
                        answers={question.surveyAnswers}
                        onQuestionChange={(id, answerLevel) =>
                          onQuestionChange(id, answerLevel, index)
                        }
                        index={index}
                        needsToBeAnswered={question.answerLevel === 0}
                        ref={(el) => (questionRefs.current[index] = el)}
                      />
                    ))}
                  </div>
                )}

                <PracticeAssessmentNavigation
                  continueButtonText={continueButtonText}
                  primaryAction={handleNext}
                  cancelAction={handleBack}
                  backButtonText={backButtonText}
                  errorMessage={error}
                  isPrimaryDisabled={!allQuestionsAnswered}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default OutcomeAssessment;
