import React from 'react';

interface TextWithNewlinesProps {
  text: string;
}

const TextWithNewlines: React.FC<TextWithNewlinesProps> = ({ text }) => {
  if (!text) return null;

  return (
    <>
      {text
        .replace(/\\n/g, '\n')
        .split('\n')
        .map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
    </>
  );
};

export default TextWithNewlines;
