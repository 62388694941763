import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router-dom";

import { RootState } from "../../../../../app/rootReducer";
import {
  clearAccountError,
  sendSmsValidateVerificationCodeRequest,
  sendSmsVerificationCodeRequest,
  sendValidateVerificationCodeRequest,
  sendVerificationCodeRequest
} from "../../../../account/accountSlice";
import { setEmailVerificationNeeded, setSmsVerificationNeeded } from "../../../../../slices/userAppSettingsSlice";
import formatPhoneNumber from "../../../../../_core/utils/countryCode/formatPhoneNumber";
import strings from "../../../../../_core/strings/strings";
import { ContentProps, ContentType, LocationStateProps } from "../../types";
import "./styles.scss";
import {
  sendGetPhoneCountryCodes,
  setEmailValidationRequired,
  setSmsValidationRequired
} from "../../../../register/registerSlice";
import { CredentialsType } from "../../../../account/maintenance/ModifyAuthentication";

import { SeityTextInput } from "../../../../../_core/components/SeityTextInput/SeityTextInput";
import SeityButton from "../../../../../_core/components/SeityButton";

const lockIcon = require("../../../../../assets/icons/lockIcon.svg").default;
const lockIconError = require("../../../../../assets/icons/lockIconError.svg").default;

type VerificationStateProps = LocationStateProps & {
  isPhoneVerification: boolean;
};

export const EmailVerificationContent = ({ setContentType }: ContentProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<VerificationStateProps>();
  const { pathname } = location;

  const { shouldRedirectToLockedAccount } = useSelector((state: RootState) => state.auth);
  const { emailValidationRequired, smsValidationRequired, current, phoneCountryCodes } = useSelector(
    (state: RootState) => state.register
  );
  const { codeVerifyError, isPasswordValidated, accountInfo } = useSelector((state: RootState) => state.account);

  const { eMailAddress, cellPhone, phoneCountryCodeID } = accountInfo;

  const [code, setCode] = useState("");
  const [isPhoneVerification, setIsPhoneVerification] = useState(pathname === "/phoneVerification");

  // Determine where the user came from (e.g., registration flow vs. settings).
  const isFromRegistration =
    location.state && "isFromRegistration" in location.state ? location.state.isFromRegistration : false;
  const isFromSettings =
    location.state && "isFromSettings" in location.state ? location.state.isFromSettings : false;

  // Decide which email/phone to verify (either from 'current' if registration, or from existing accountInfo).
  const emailToVerify = isFromRegistration ? current.emailAddress : eMailAddress;
  const phoneToVerify = isFromRegistration ? current.cellPhone : cellPhone;
  const phoneCountryCodeIDToVerify = isFromRegistration ? current.phoneCountryCodeID : phoneCountryCodeID;

  /**
   * Dispatches the correct code-sending action (SMS or Email).
   */
  const sendVerificationCode = () => {
    if (isPhoneVerification) {
      dispatch(sendSmsVerificationCodeRequest());
    } else {
      dispatch(sendVerificationCodeRequest());
    }
  };

  /**
   * Called when user presses "Resend Code."
   */
  const onResendCodeHandler = () => {
    sendVerificationCode();
    dispatch(clearAccountError());
    setCode("");
  };

  /**
   * Validates the code typed by user (SMS or Email).
   */
  const sendValidateCode = () => {
    if (isPhoneVerification) {
      dispatch(sendSmsValidateVerificationCodeRequest(code, submitResponseHandler));
    } else {
      dispatch(sendValidateVerificationCodeRequest(code, submitResponseHandler));
    }
  };

  /**
   * useEffect to:
   * 1. Clear any existing errors.
   * 2. Fetch phone country codes if not loaded.
   * 3. Send verification code once on the first load if not sent recently (localStorage check).
   */
  useEffect(() => {
    dispatch(clearAccountError());

    if (!phoneCountryCodes) {
      dispatch(sendGetPhoneCountryCodes());
    }

    const lastSent = localStorage.getItem("lastVerificationSent");
    const now = Date.now();
    const THRESHOLD_MS = 2 * 60 * 1000; // 2 minutes to prevent spamming on refresh

    if (!lastSent || now - parseInt(lastSent, 10) > THRESHOLD_MS) {
      sendVerificationCode();
      localStorage.setItem("lastVerificationSent", now.toString());
    }

    // If you need dynamic detection:
    // if (location.pathname === "/phoneVerification") setIsPhoneVerification(true);

    /**
     * NOTE: If you prefer an in-memory approach only (no localStorage),
     * you'll need a state variable (didSendCodeOnMount) instead.
     * But that won't survive a full page refresh.
     */
  }, [dispatch, phoneCountryCodes]);

  /**
   * Decide which lock icon to display (error or normal).
   */
  const textBoxIcon = () => {
    if (codeVerifyError !== null) {
      return lockIconError;
    }
    return lockIcon;
  };

  /**
   * Handler for "Not the correct email/phone?" -> Possibly route user to "Change Credentials" flow.
   */
  const onUpdateEmailLinkHandler = () => {
    if (isFromRegistration) {
      history.push("register/registerFour");
      return;
    }
    if (isFromSettings || (!isFromRegistration && !isFromSettings)) {
      if (pathname.includes("phoneVerification")) {
        history.push({
          pathname: "/authentication/changeCredential",
          state: { credentialsType: CredentialsType.PhoneNumber, checkPassword: false, updateCreds: true }
        });
      } else if (pathname.includes("emailVerification")) {
        history.push({
          pathname: "/authentication/changeCredential",
          state: { credentialsType: CredentialsType.Email, checkPassword: false, updateCreds: true }
        });
      } else {
        history.push("/authentication");
      }
    }
    if (isPasswordValidated) {
      setContentType(ContentType.UpdateEmail);
    } else {
      setContentType(ContentType.PwdValidate);
    }
  };

  /**
   * Runs after a successful code validation. Decides next steps based on registration vs. settings vs. normal.
   */
  const submitResponseHandler = () => {
    if (isFromRegistration) {
      if (isPhoneVerification) {
        dispatch(setSmsValidationRequired({ smsValidationRequired: false }));

        if (emailValidationRequired) {
          history.push({
            pathname: "/emailVerification",
            state: { isFromRegistration: true }
          });
        } else {
          history.push("register/registerSix");
        }
      } else {
        dispatch(setEmailValidationRequired({ emailValidationRequired: false }));
        if (smsValidationRequired) {
          history.push({
            pathname: "/phoneVerification",
            state: { isFromRegistration: true }
          });
        } else {
          history.push("register/registerSix");
        }
      }
    } else if (isFromSettings) {
      if (isPhoneVerification) {
        dispatch(setSmsValidationRequired({ smsValidationRequired: false }));
        dispatch(setSmsVerificationNeeded());
      } else {
        dispatch(setEmailValidationRequired({ emailValidationRequired: false }));
        dispatch(setEmailVerificationNeeded());
      }
      history.push("/authentication");
    } else {
      // Startup only check email validation required
      dispatch(setEmailValidationRequired({ emailValidationRequired: false }));
      dispatch(setEmailVerificationNeeded());
      history.push({ pathname: "/" });
    }
  };

  // If user should be locked out, redirect
  if (shouldRedirectToLockedAccount) {
    return <Redirect to="/lockedAccount" />;
  }

  // Show either phone or email address for user reference
  const verificationItem = isPhoneVerification
    ? formatPhoneNumber(phoneToVerify, phoneCountryCodeIDToVerify, phoneCountryCodes)
    : emailToVerify;

  return (
    <div className="ev-wrapper">
      <div className="ev-title">{isPhoneVerification ? strings.pvTitle : strings.evTitle}</div>
      <div className="ev-subtitle">{isPhoneVerification ? strings.pvSubtitle : strings.evSubtitle}</div>

      <div className="ev-codeSent">{strings.evCodeSent}</div>
      <div className="ev-email">
        <span>{verificationItem}</span>
      </div>
      <div className="ev-codeExpires">{strings.evCodeExpires}</div>

      <SeityTextInput
        value={code}
        onChange={(e) => setCode(e.target.value)}
        className="ev-codeInput"
        containerClassname="ev-codeInputContainer"
        leftIconClassname="ev-codeInputIcon"
        type="number"
        leftIcon={textBoxIcon()}
        isInvalid={codeVerifyError !== null}
      />

      <div className="ev-recivedCode">{strings.evReceivedCode}</div>
      <SeityButton onClick={onResendCodeHandler} type="link" label={strings.evResendCode} />

      <div className="ev-codeError">{codeVerifyError}</div>

      <SeityButton
        className="ev-submit"
        type="submit"
        label={strings.submit}
        onClick={sendValidateCode}
      />

      <div className="ev-notCorrectAddress">
        <span>{isPhoneVerification ? strings.wrongNumber : strings.wrongEmail}</span>
        <span className="ml-2" onClick={onUpdateEmailLinkHandler}>
          <u>{isPhoneVerification ? strings.pvUpdateNumber : strings.evUpdateEmailAddress}</u>
        </span>
      </div>
    </div>
  );
};

export default EmailVerificationContent;
