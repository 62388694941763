export class CoreValueCategorySelection {
  coreValueCategoryID: number;
  coreValueCategorySelectionID: number;
  questionKey: string;
  heading: string;
  description: string;
  selected: boolean;
  topRated: boolean;
  sortOrder: string;
  image: string;

  constructor(
    coreValueCategoryID: number,
    coreValueCategorySelectionID: number,
    questionKey: string,
    heading: string,
    description: string,
    selected: boolean,
    topRated: boolean,
    sortOrder: string,
    image: string
  ) {
    this.coreValueCategoryID = coreValueCategoryID;
    this.coreValueCategorySelectionID = coreValueCategorySelectionID;
    this.questionKey = questionKey;
    this.heading = heading;
    this.description = description;
    this.selected = selected;
    this.topRated = topRated;
    this.sortOrder = sortOrder;
    this.image = image;
  }
}

export class CoreValueSelection {
  coreValueSelectionID: number;
  coreValueID: number;
  coreValue: string;
  coreValueText: string;
  selected: boolean;
  topRated: boolean;
  image: string;

  constructor(coreValueSelectionID: number, coreValueID: number,
     heading: string, description: string, 
     selected: boolean, topRated: boolean, image: string) {
    this.coreValueSelectionID = coreValueSelectionID;
    this.coreValueID = coreValueID;
    this.coreValue = heading;
    this.coreValueText = description;
    this.selected = selected;
    this.topRated = topRated;
    this.image = image;
  }
}

export class FourCoreValuesResponse {
  success: boolean;
  message: string | null;
  data: FourCoreValuesResponseData;

  constructor(success: boolean, message: string | null, data: FourCoreValuesResponseData) {
    this.success = success;
    this.message = message;
    this.data = data;
  }
}

export class FourCoreValuesResponseData {
  lmAccountCoreValues: CoreValue[];
  constructor(lmAccountCoreValues: CoreValue[]) {
    this.lmAccountCoreValues = lmAccountCoreValues;
  }
}

export class CoreValue {
  coreValueID: number;
  coreValue: string;
  coreValueText: string;
  description: string;
  descriptionText: string;
  quote: string;
  coreValueImage: string;
  coreValueCategoryID: number;
  coreValueCategory: string;
  coreValueCategoryText: string;
  coreValueCategoryImage: string;
  userSortOrder: number;
  videoFileName: string;

  constructor(
    coreValueID: number,
    coreValue: string,
    coreValueText: string,
    description: string,
    descriptionText: string,
    quote: string,
    coreValueImage: string,
    coreValueCategoryID: number,
    coreValueCategory: string,
    coreValueCategoryText: string,
    coreValueCategoryImage: string,
    userSortOrder: number,
    videoFileName: string
  ) {
    this.coreValueID = coreValueID;
    this.coreValue = coreValue;
    this.coreValueText = coreValueText;
    this.description = description;
    this.descriptionText = descriptionText;
    this.quote = quote;
    this.coreValueImage = coreValueImage;
    this.coreValueCategoryID = coreValueCategoryID;
    this.coreValueCategory = coreValueCategory;
    this.coreValueCategoryText = coreValueCategoryText;
    this.coreValueCategoryImage = coreValueCategoryImage;
    this.userSortOrder = userSortOrder;
    this.videoFileName = videoFileName;
  }
}

export class CoreValueGeneric {
  id: number;
  cvCateId: number;
  questionKey: string;
  cvId: number;
  heading: string;
  description: string;
  selected: boolean;
  topRated: boolean;
  sortOrder: string;
  image: string;

  constructor(
    id: number,
    cvCateId: number,
    questionKey: string,
    cvId: number,
    heading: string,
    description: string,
    selected: boolean,
    topRated: boolean,
    sortOrder: string,
    image: string
  ) {
    this.id = id;
    this.cvCateId = cvCateId;
    this.questionKey = questionKey;
    this.cvId = cvId;
    this.heading = heading;
    this.description = description;
    this.selected = selected;
    this.topRated = topRated;
    this.sortOrder = sortOrder;
    this.image = image;
  }
}

export class CoreValueGenericUpdate {
  id: number;
  selected: boolean;
  topRated: boolean;

  constructor(id: number, selected: boolean, topRated: boolean) {
    this.id = id;
    this.selected = selected;
    this.topRated = topRated;
  }
}

export class CoreValuesIncentiveResponse {
  success: boolean;
  message: string | null;
  data: CoreValuesIncentive;

  constructor(success: boolean, message: string | null, data: CoreValuesIncentive) {
    this.success = success;
    this.message = message;
    this.data = data;
  }
}

export class CoreValuesIncentive {
  incentiveScheduleID: number;
  isActive: boolean;
  header: string;
  body: string;
  footer: string;

  constructor(incentiveScheduleID: number, isActive: boolean, header: string, body: string, footer: string) {
    this.incentiveScheduleID = incentiveScheduleID;
    this.isActive = isActive;
    this.header = header;
    this.body = body;
    this.footer = footer;
  }
}
