import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ServiceResponseSurveyOutro, SurveyOutroResponse } from "../../../api/getSurveyOutro/types";
import { AppThunk } from "../../../app/store";
import { setRedirectToLogin } from "../../auth/authSlice";
import { SeityAuthenticationError } from "../../../api/authTypes";
import { getSurveyOutro } from "../../../api/getSurveyOutro/getSurveyOutroApi";
import {getRefreshedToken} from "../../auth/UseSessionStorageToken";

interface SurveyOutroState {
  error: string | null;
  isLoading: boolean;
  surveyOutro: SurveyOutroResponse | null;
}

const initialState: SurveyOutroState = {
  error: null,
  isLoading: false,
  surveyOutro: null,
};

const surveyOutroSlice = createSlice({
  name: 'survey_outro',
  initialState,
  reducers: {
    fetchSurveyOutroSuccess(state, action: PayloadAction<SurveyOutroResponse>) {
      state.surveyOutro = action.payload;
      state.isLoading = false;
    },
    setApiError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    clearApiError(state) {
      state.error = null;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
  },
});

export const {
  fetchSurveyOutroSuccess,
  setApiError,
  clearApiError,
  setIsLoading,
} = surveyOutroSlice.actions;

export default surveyOutroSlice.reducer;

export const fetchSurveyOutro = (outcomeSubcategoryID: number): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    dispatch(clearApiError());

    const { token } = await getRefreshedToken();
    if (token === null) {
      dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
      throw new SeityAuthenticationError();
    }

    const response: ServiceResponseSurveyOutro = await getSurveyOutro(token, outcomeSubcategoryID);

    console.log("survey outro", response.data);

    if (response.success) {
      dispatch(fetchSurveyOutroSuccess(response.data));
    } else {
      dispatch(setApiError(response.userMessage || 'Failed to fetch Survey Outro data'));
    }
  } catch (err: any) {
    dispatch(setApiError(err.toString()));
  } finally {
    dispatch(setIsLoading(false));
  }
};
