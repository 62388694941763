import { FunctionComponent } from "react";
import { PostItemProps } from "./types";

import "./styles.scss";

export const PostItem: FunctionComponent<PostItemProps> = ({ title, className, subtitle, imageUrl, onClick, previewImageName}) => {
  return (
    <div className={`post-list-item ${className ?? ""}`} key={`${title}-${subtitle}`} onClick={onClick}>
      {imageUrl ? (
        <div
          className="post-list-item-image"
          style={{
            backgroundImage: `url(${imageUrl})`
          }}
        />
      ) : (
        <img className="post-list-item-image" src={require(`../../../../../assets/graphics/guides/${previewImageName}.png`).default} />
      )}
      <div className="post-list-item-bottom">
        <h2>{title}</h2>
        {subtitle && <h3>{subtitle}</h3>}
      </div>
    </div>
  );
};
