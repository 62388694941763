import { BASE_URL } from "../../apiConstants";
import { SeityResponse } from "../../baseTypes";
import axios from "axios";
import { FourCoreValuesSortOrderUpdate } from "./types";

export async function fourCoreValuesSortOrderUpdateRequest(
  token: string,
  fourCoreValuesSortOrderUpdate: FourCoreValuesSortOrderUpdate
): Promise<SeityResponse> {
  const url = BASE_URL + "/WholeHealth/CoreValueUserSortOrderUpdate";
  try {
    const fourCoreValuesSortOrderUpdateResponse = await axios.post<SeityResponse>(url, fourCoreValuesSortOrderUpdate, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return fourCoreValuesSortOrderUpdateResponse.data;
  } catch (err) {
    throw err;
  }
}