import {useState, useContext, useEffect, FunctionComponent} from "react";
import {useLocation as useRouterLocation, useHistory, Link} from "react-router-dom";

import {useDispatch, useSelector} from "react-redux";
import {MessageType, SeityAccountMessageCenter} from "../../../api/account/types/messageCenterListTypes";
import {SeityAppContext} from "../../../app/SeityAppContext";
import {RootState} from "../../../app/rootReducer";
import {HIDE_HAMBURGER} from "../../../consts";

import {SeityTodo} from "../SeityTodo";
import HeaderMenu from "./HeaderMenu";
import {SeityHeaderProps} from "./types";

import Burger from "../../../assets/web-icons/burger.svg";
import BurgerClose from "../../../assets/web-icons/close.svg";
import SmallSeityLettersCream from "../../../assets/web-icons/header_logo.svg";
import SmallSeityLetters from "../../../assets/web-icons/seityletterssmallwhite.svg";
import VertLine from "../../../assets/web-icons/vertline.svg";
import notiImg from "../../../assets/graphics/notifications.png";

import "./styles.scss";
import SeityAvatar from "../SeityAvatar";
import SeityButton from "../SeityButton";
import strings from "../../strings/strings";
import useHeaderMenuData from "./useHeaderMenuData";
import UnlockYourWholeHealthPageModal
  from "../../../features/wholeHealth/components/UnlockYourWholeHealthPageModal/UnlockYourWholeHealthPageModal";

export const SeityHeader: FunctionComponent<SeityHeaderProps> = () => {
  const appContext = useContext(SeityAppContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const {shouldRedirectToLogin} = useSelector((state: RootState) => {
    return state.auth;
  });
  const {userAppSettings} = useSelector((state: RootState) => {
    return state.userAppSettings;
  });

  if (shouldRedirectToLogin) {
    history.push("/login");
  }

  const routerLocation = useRouterLocation();
  const [showTodo, setShowTodo] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [activeMenuIdx, setActiveMenuIdx] = useState<number | null>(null);
  const [submenuData, setSubmenuData] = useState<Array<any>>([]);
  const [showUnlockModal, setShowUnlockModal] = useState(false);
  const headerMenuData = useHeaderMenuData();

  useEffect(() => {
    if (activeMenuIdx) {
      setSubmenuData(headerMenuData[activeMenuIdx].submenu);
    }
  }, [activeMenuIdx]);

  const isBurgerMenuVisible = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isVisible ? setVisible(isVisible) : setVisible(!isVisible);
  };

  const {
    messageCenterList,
    isCheckInRedirected
  } = useSelector((state: RootState) => {
    return state.account;
  });

  const navMenuButton = () => {
    // Display either Back Caret button(Desktop) or Burger button(mobile)
    return (
      <>
        {HIDE_HAMBURGER.includes(routerLocation.pathname) ? null : (
          <img src={Burger} alt="Burger" className="seity-burger" onClick={isBurgerMenuVisible}/>
        )}
      </>
    );
  };

  const handleUnlockModalClose = () => {
    setShowUnlockModal(false);
  };

  const handleUnlockModalComplete = () => {
    setShowUnlockModal(false);
    history.push("/assessment/corevalues");
  };

  const handleWholeHealthClick = () => {
    setActiveMenuIdx(null);
    setShowTodo(false);
    setVisible(false);

    if (userAppSettings.coreValueStatusID === 2 && userAppSettings.coreValuesEnabled) {
      setShowUnlockModal(true);
    } else {
      history.push("/myWholeHealth");
    }
  };

  const todoView = (messageCenterList: Array<SeityAccountMessageCenter> | null) => {
    const listCount = messageCenterList?.filter((m) => m.messageTypeID !== MessageType.UpToDate).length ?? 0;

    return appContext.showTodo ? (
      <div className="seity-header__main__right">
        {userAppSettings.getHelpEnabled && (
          <SeityButton
            type="cancel"
            label={strings.getHelp}
            image={require("../../../assets/icons/parachute.svg").default}
            className="get-help-button"
            onClick={() => history.push("/checkIn/resources")}
          />
        )}
        <a
          href="_blank"
          onClick={(e) => {
            e.preventDefault();
            setShowTodo(!showTodo);
          }}
        >
          <img src={notiImg} alt="Notification"/>
          {listCount > 0 && <div className="flex-center">{listCount}</div>}
        </a>
        <SeityAvatar
          size={48}
          onClick={() => {
            history.push("/settings");
          }}
        />
      </div>
    ) : null;
  };

  const menuItems = () => {
    return appContext.showNavMenuItems ? (
      <div className="center-menu-items">
        <div>
          <img
            src={SmallSeityLettersCream}
            className="center-menu-items__img-logo"
            alt="SmallSeityLettersCream"
            onClick={() => {
              return history.push("/");
            }}
          />
        </div>
        <img className="vert-line" src={VertLine} alt="Vert Line"/>
        <HeaderMenu
          showBurger={false}
          setVisible={setVisible}
          setShowTodo={setShowTodo}
          activeMenuIdx={activeMenuIdx}
          setActiveMenuIdx={setActiveMenuIdx}
          submenuData={submenuData}
        />
      </div>
    ) : null;
  };

  const burgerMenu = () => {
    return appContext.showNavMenuItems ? (
      <div className={isVisible ? "visible-burger" : "invisible-burger"}>
        <div className="burger-header">
          <img
            onClick={() => {
              return setVisible(false);
            }}
            className="burger-close"
            src={BurgerClose}
            alt="Burger Close"
          />

          <img className="small-seity-letters" src={SmallSeityLetters} alt="Small Seity Letters"/>
        </div>
        <div className="burger-menu-item-container">
          <HeaderMenu
            showBurger={true}
            setVisible={setVisible}
            setShowTodo={setShowTodo}
            activeMenuIdx={activeMenuIdx}
            setActiveMenuIdx={setActiveMenuIdx}
            submenuData={submenuData}
          />
        </div>
      </div>
    ) : null;
  };

  return (
    <header className="seity-header" aria-label="header-container">
      <UnlockYourWholeHealthPageModal
        visible={showUnlockModal}
        onClose={handleUnlockModalClose}
        onComplete={handleUnlockModalComplete}
      />
      <div className="seity-header__main">
        <div className="seity-header__main__left">{navMenuButton()}</div>
        <div id="nav" className={isVisible ? "visible-burger" : "invisible-burger"}>
          {burgerMenu()}
        </div>
        <div className="seity-header__main__center d-flex flex-column justify-content-center">{menuItems()}</div>
        {todoView(messageCenterList)}
      </div>
      <SeityTodo
        isVisible={showTodo}
        content={messageCenterList}
        onToggleItem={() => {
        }}
        onTouchClose={() => {
          setShowTodo(false);
        }}
      />
      {activeMenuIdx && (
        <div className="grow-submenu" style={{left: document.getElementById(headerMenuData[activeMenuIdx].title)?.offsetLeft}}>
          {submenuData.map((item, i) => {
            return (
              !item?.hidden && (
                <div
                  key={i}
                  className={"submenu-item"}
                  onClick={() => {
                    if (item.linkUrl === "/myWholeHealth") {
                      handleWholeHealthClick();
                    } else {
                      history.push(item.linkUrl);
                      setActiveMenuIdx(null);
                    }
                  }}
                >
                  <div>
                    <img src={require(`../../../assets/graphics/submenu/${item.icon}.svg`).default}/>
                    <p>{item.title}</p>
                  </div>
                </div>
              )
            );
          })}
        </div>
      )}
    </header>
  );
};
