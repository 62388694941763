import React from 'react';
import {useHistory} from 'react-router-dom';
import './styles.scss';

interface SubcategoryCardProps {
  svgXml?: string;
  subcategory: string;
  subcategoryId: number;
  timeEstimateDescription: string;
  statusId: number;
  statusDescription: string;
  isLifeOutcome?: boolean;
}

const wholeHealthSurveyStartedIcon = require('../../assets/whole-health-survey-started-icon.png').default;
const wholeHealthSurveyNotStartedIcon = require('../../assets/whole-health-survey-not-started-icon.png').default;

const SubcategoryCard: React.FC<SubcategoryCardProps> =
  ({
     svgXml,
     subcategory,
     subcategoryId,
     timeEstimateDescription,
     statusId,
     statusDescription,
     isLifeOutcome = false,
   }) => {
    const history = useHistory();

    const handleClick = () => {
      if (isLifeOutcome) {
        history.push('/mywholeHealth/outcomeAssessment');
      } else {
        history.push(`/mywholeHealth/practiceAssessment/${subcategoryId}`);
      }
    };

    const isStarted = statusId === 3 || statusId === 4;
    const iconSource = isStarted ? wholeHealthSurveyStartedIcon : wholeHealthSurveyNotStartedIcon;

    let tagClass = 'start-now-tag';
    let tagTextClass = 'start-now-text';

    if (statusId === 3) {
      tagClass = 'incomplete-tag';
      tagTextClass = 'incomplete-text';
    } else if (statusId === 4) {
      tagClass = 'retake-tag';
      tagTextClass = 'retake-text';
    }

    return (
      <button
        className="touchable-card"
        onClick={handleClick}
        aria-label={`Start survey for ${subcategory}`}
      >
        <div className="subcategory-card">
          {svgXml ? (
            <div
              className="subcategory-image"
              dangerouslySetInnerHTML={{__html: svgXml}}
            />
          ) : (
            <div className="svg-placeholder">
              <p className="svg-placeholder-text">SVG</p>
            </div>
          )}

          <div className="subcategory-text-container">
            <p className="subcategory-text">{subcategory}</p>
            <p className="time-estimate-text">{timeEstimateDescription}</p>
          </div>

          <div className="status-icon-container">
            <img src={iconSource} alt="Status" className="status-icon"/>
          </div>

          <div className={tagClass}>
            <p className={tagTextClass}>{statusDescription}</p>
          </div>
        </div>
      </button>
    );
  };

export default SubcategoryCard;
