import React from 'react';
import './styles.scss';
import SeityButton from "../../../../_core/components/SeityButton";
import {SurveyIncentive} from "../../../../api/getSurveyDetail/types";

interface OutcomeAssessmentIncentiveProps {
  incentive: SurveyIncentive;
  onClose: () => void;
}

const OutcomeAssessmentIncentive: React.FC<OutcomeAssessmentIncentiveProps> = ({
                                                                                 incentive,
                                                                                 onClose,
                                                                               }) => {
  if (!incentive) return null;

  return (
    <div className="outcome-assessment-incentive-container">
      <div className="background-image" />
      <div className="main-container">
        <div className="content-container">
          <h1 className="text01">{incentive.text01?.replace(/\\n/g, '\n')}</h1>
        </div>
        <div className="footer-container">
          <p className="text02">{incentive.text02}</p>
          <div className="button-wrapper">
            <SeityButton
              onClick={onClose}
              label={incentive.buttonText || 'Continue'}
              type="next"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutcomeAssessmentIncentive;
