import React, { FunctionComponent, ReactElement, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './styles.scss';
import { AppDispatch } from '../../../../app/store';
import { RootState } from '../../../../app/rootReducer';
import { fetchSurveyInformation } from '../../slices/surveyInformationSlice';
import SeityButton from '../../../../_core/components/SeityButton';

type SurveyInfoModalProps = {
  isVisible: boolean;
  onClose: () => void;
};

const SurveyInfoModal: FunctionComponent<SurveyInfoModalProps> = ({
                                                                    isVisible,
                                                                    onClose
                                                                  }): ReactElement | null => {
  const dispatch: AppDispatch = useDispatch();
  const surveyInformation = useSelector((state: RootState) => state.surveyInformation.surveyInformation);

  useEffect(() => {
    if (isVisible) {
      dispatch(fetchSurveyInformation());
    }
  }, [isVisible, dispatch]);

  if (!isVisible || !surveyInformation) {
    return null;
  }

  const { lifeHeader, lifeText, resultsHeader, resultsText, doneButtonText } = surveyInformation;

  return (
      <div className="survey-info-modal-container">

        <div className="survey-info-modal-content">
          {lifeHeader && <h2 className="survey-info-modal-header">{lifeHeader}</h2>}
          {lifeText && <p className="survey-info-modal-text">{lifeText}</p>}

          {resultsHeader && <h2 className="survey-info-modal-header-2">{resultsHeader}</h2>}
          {resultsText && <p className="survey-info-modal-text">{resultsText}</p>}
        </div>

        <div className="survey-info-modal-footer">
          <SeityButton
            onClick={onClose}
            label={doneButtonText || 'Done'}
          />
        </div>
      </div>
  );
};

export default SurveyInfoModal;
