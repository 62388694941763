import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ServiceResponseSurveyDetail,
  SurveyDetailResponse, SurveyPracticeQuestion,
} from '../../../api/getSurveyDetail/types';
import { AppThunk } from '../../../app/store';
import { setRedirectToLogin } from '../../auth/authSlice';
import { SeityAuthenticationError } from '../../../api/authTypes';
import { getSurveyDetail } from '../../../api/getSurveyDetail/getSurveyDetailApi';
import { outcomeSelectionUpdate } from '../../../api/outcomeSelectionUpdate/outcomeSelectionUpdateApi';
import { RootState } from '../../../app/rootReducer';
import {getRefreshedToken} from "../../auth/UseSessionStorageToken";

interface SurveyDetailState {
  error: string | null;
  isLoading: boolean;
  surveyDetail: SurveyDetailResponse | null;
  currentOutcomeCategoryID: number | null;
  isLastPage: boolean;
  currentStep: number;
  totalSteps: number;
  uniqueCategories: number[];
}

const initialState: SurveyDetailState = {
  error: null,
  isLoading: false,
  surveyDetail: null,
  currentOutcomeCategoryID: null,
  isLastPage: false,
  currentStep: 1,
  totalSteps: 1,
  uniqueCategories: [],
};

const surveyDetailSlice = createSlice({
  name: 'surveyOutcomeDetail',
  initialState,
  reducers: {
    fetchSurveyDetailSuccess(state, action: PayloadAction<SurveyDetailResponse>) {
      state.surveyDetail = action.payload;
      state.isLoading = false;

      // Handle nextSelectionID being 0
      let nextQuestion: SurveyPracticeQuestion | undefined;
      if (action.payload.nextSelectionID === 0) {
        // Use the very first question from the list
        nextQuestion = action.payload.surveyQuestions[0];
      } else {
        nextQuestion = action.payload.surveyQuestions.find(
          (q) => q.selectionID === action.payload.nextSelectionID
        );
      }

      if (nextQuestion) {
        state.currentOutcomeCategoryID = nextQuestion.outcomeCategoryID;
        state.isLastPage = false;
      } else {
        state.currentOutcomeCategoryID = null;
        state.isLastPage = true;
      }

      state.uniqueCategories = Array.from(
        new Set(action.payload.surveyQuestions.map((q) => q.outcomeCategoryID))
      );
      state.totalSteps = state.uniqueCategories.length;

      const currentCategoryIndex = state.uniqueCategories.indexOf(state.currentOutcomeCategoryID || 0);
      state.currentStep = currentCategoryIndex + 1;
    },
    setApiError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    clearApiError(state) {
      state.error = null;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    updateAnswerLevel(state, action: PayloadAction<{ selectionID: number; answerLevel: number }>) {
      const { selectionID, answerLevel } = action.payload;
      const question = state.surveyDetail?.surveyQuestions.find((q) => q.selectionID === selectionID);
      if (question) {
        question.answerLevel = answerLevel;
      }
    },
    setCurrentOutcomeCategoryID(state, action: PayloadAction<number | null>) {
      state.currentOutcomeCategoryID = action.payload;
    },
    setIsLastPage(state, action: PayloadAction<boolean>) {
      state.isLastPage = action.payload;
    },
    updateCurrentStep(state, action: PayloadAction<number>) {
      state.currentStep = action.payload;
    },
  },
});

export const {
  fetchSurveyDetailSuccess,
  setApiError,
  clearApiError,
  setIsLoading,
  updateAnswerLevel,
  setIsLastPage,
  setCurrentOutcomeCategoryID,
  updateCurrentStep,
} = surveyDetailSlice.actions;

export default surveyDetailSlice.reducer;

export const fetchSurveyDetail = (outcomeSubcategoryID: number): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    dispatch(clearApiError());

    const { token } = await getRefreshedToken();
    if (token === null) {
      dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
      throw new SeityAuthenticationError();
    }

    const response: ServiceResponseSurveyDetail = await getSurveyDetail(token, outcomeSubcategoryID);

    //response.data.nextSelectionID = 21119;

    console.log("response55", response);

    if (response.success) {
      dispatch(fetchSurveyDetailSuccess(response.data));
    } else {
      dispatch(setApiError(response.userMessage || 'Failed to fetch survey detail data'));
    }
  } catch (err: any) {
    dispatch(setApiError(err.toString()));
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const saveOutcomeAnswerLevel = (
  selectionID: number,
  answerLevel: number
): AppThunk => async (dispatch) => {
  try {
    const { token } = await getRefreshedToken();
    if (token === null) {
      dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
      throw new SeityAuthenticationError();
    }

    const response = await outcomeSelectionUpdate(token, selectionID, answerLevel);

    if (!response.success) {
      dispatch(updateAnswerLevel({ selectionID, answerLevel: 0 }));
      dispatch(setApiError(response.userMessage || 'Failed to save answer level'));
    }
    // Note: We do not update nextSelectionID here since the API doesn't return it
  } catch (err: any) {
    dispatch(updateAnswerLevel({ selectionID, answerLevel: 0 }));
    dispatch(setApiError(err.toString()));
  }
};

export const goToNextCategory = (): AppThunk => (dispatch, getState) => {
  const state: RootState = getState();
  const { currentOutcomeCategoryID, uniqueCategories } = state.surveyOutcomeDetail;

  const currentIndex = uniqueCategories.indexOf(currentOutcomeCategoryID || 0);
  const nextCategoryID = uniqueCategories[currentIndex + 1] || null;

  if (nextCategoryID !== null) {
    dispatch(setCurrentOutcomeCategoryID(nextCategoryID));
    dispatch(setIsLastPage(currentIndex + 2 === uniqueCategories.length));
    dispatch(updateCurrentStep(currentIndex + 2));
  } else {
    // If there is no next category, set isLastPage to true
    dispatch(setIsLastPage(true));
  }
};

export const goToPreviousCategory = (): AppThunk => (dispatch, getState) => {
  const state: RootState = getState();
  const { currentOutcomeCategoryID, uniqueCategories } = state.surveyOutcomeDetail;

  const currentIndex = uniqueCategories.indexOf(currentOutcomeCategoryID || 0);
  const previousCategoryID = uniqueCategories[currentIndex - 1] || null;

  if (previousCategoryID !== null) {
    dispatch(setCurrentOutcomeCategoryID(previousCategoryID));
    dispatch(setIsLastPage(false));
    dispatch(updateCurrentStep(currentIndex));
  }
};
