import React, { useImperativeHandle, forwardRef, useRef } from 'react';
import './styles.scss';
import { SurveyPracticeAnswer } from '../../../../api/getSurveyDetail/types';
import AnswerGroup from '../AnswerGroup/AnswerGroup';

export interface PracticeQuestionProps {
  id: number;
  questionTypeId: number;
  questionText: string;
  answerLevel: number;
  answers: SurveyPracticeAnswer[];
  onQuestionChange: (id: number, answerLevel: number, index: number) => void;
  index: number;
  needsToBeAnswered: boolean;
}

const PracticeQuestion = forwardRef<HTMLDivElement, PracticeQuestionProps>(
  (
    {
      id,
      questionTypeId,
      questionText,
      answerLevel,
      answers,
      onQuestionChange,
      index,
      needsToBeAnswered,
    },
    ref
  ) => {
    const innerRef = useRef<HTMLDivElement>(null);

    // Expose the innerRef via forwardRef, asserting it's non-null
    useImperativeHandle(ref, () => {
      if (innerRef.current === null) {
        throw new Error('Ref is null');
      }
      return innerRef.current;
    });

    const onValueChange = (value: string) => {
      const newAnswerLevel = Number(value);
      onQuestionChange(id, newAnswerLevel, index);
    };

    const answerItems = answers.map((answer) => ({
      label: answer.answerText,
      value: answer.answerLevel.toString(),
    }));

    return (
      <div
        className="questionContainer"
        ref={innerRef}
      >
        <p className="questionText">{`${index + 1}. ${questionText}`}</p>
        <AnswerGroup
          onValueChange={onValueChange}
          radioValue={answerLevel.toString()}
          items={answerItems}
          name={`question-${id}`}
        />
      </div>
    );
  }
);

export default PracticeQuestion;
